import { Card, CardContent } from "@mui/material"
import { ReactNode, useMemo } from "react"
import { ErrorComponent } from "../../errorComponent/ErrorComponent"
import HorizontalStackedBarChart from "../../genericBarChart/HorizontalStackedBarChart"
import GenericTable from "../../genericTable/GenericTable"
import { Loading } from "../../loading/Loading"
import style from "./EmissionsPerFacility.module.css"
import { IFacilityEmissionsV2 } from "../../../models/CompanyEmissionsV2"
import { KpiSection } from "../../../models/Kpi"
import { toFixed } from "../../../utils/Utils"

interface IProps {
    minHeight?: number;
    facilityEmissions: IFacilityEmissionsV2[] | undefined;
}

export interface IFacilityDataForTable {
    facilityName: string,
    location: string,
    numberOfEmployees: number,
    horizontalChart: ReactNode
    totalEmissions: number,
}

type IEmissionsBreakdown = {
    [K in KpiSection | "key"]: number | string;
}

type SectionsWithTitleAndColor = {
    section: KpiSection;
    title: string;
    color: string;
}

function getTableData(facilityEmissions: IFacilityEmissionsV2[] | undefined, colors: string[]) {
    const headers = ["Facility", "Location", "Number of employees", "Emissions Breakdown", "Total emissions tCO2 e"];
    const rows: IFacilityDataForTable[] = [];
    const applicationSectionsWithTitleAndColor: SectionsWithTitleAndColor[] = [];

    if (facilityEmissions === undefined) {
        return { headers, rows, applicationSectionsWithTitleAndColor };
    }

    facilityEmissions.sort((a, b) => {
        return a.facility.name.localeCompare(b.facility.name, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    });

    const sectionToColor = Object.values(KpiSection).map((section, index) => {
        return { section, color: colors[index] };
    });

    facilityEmissions.forEach((facilityEmission) => {
        const emissionDataKeys: string[] = [];
        let emissionBreakdown = { "key": facilityEmission.facility.name } as IEmissionsBreakdown;
        const colorsForBars: string[] = [];
        facilityEmission.sectionEmissions.forEach((sectionEmission) => {
            const color = sectionToColor.find((value) => value.section === sectionEmission.section)?.color ?? "";
            colorsForBars.push(color);
            emissionDataKeys.push(sectionEmission.section);
            emissionBreakdown[sectionEmission.section] = sectionEmission.totalEmissions;

            if (applicationSectionsWithTitleAndColor.findIndex((value) => value.section === sectionEmission.section) === -1) {
                applicationSectionsWithTitleAndColor.push({ section: sectionEmission.section, title: sectionEmission.sectionTitle, color });
            }
        });

        const totalEmissions = facilityEmission.totalEmissions;
        const facilityDataForTable: IFacilityDataForTable = {
            facilityName: facilityEmission.facility.name,
            location: facilityEmission.facility.address.city + ", " + facilityEmission.facility.address.country,
            numberOfEmployees: facilityEmission.facility.numberOfEmployees,
            horizontalChart:
                <HorizontalStackedBarChart
                    data={[emissionBreakdown]}
                    dataKeys={emissionDataKeys}
                    yAxisDataKey="key"
                    colors={colorsForBars}
                    labelFormatter={(data, key) => {
                        if (totalEmissions === 0) {
                            return `${data[key]} tCO2 (0%)`;
                        }
                        return `${data[key]} tCO2 (${toFixed(((data[key] * 100) / totalEmissions), 2)}%)`;
                    }}
                />,
            totalEmissions: facilityEmission.totalEmissions
        };
        rows.push(facilityDataForTable)
    });

    return { headers, rows, applicationSectionsWithTitleAndColor };
}

const colors = ['#32E7A4', '#64B5FC', '#155EA2', '#0069E8', '#5B3A96', '#CE1D26', '#FFA600', '#7FC91B', '#04B1B4', '#FCEA64', '#CECECE', '#8D6BEB', '#878787'];

export const EmissionsPerFacilityV2 = (props: IProps) => {

    const { minHeight = 300, facilityEmissions } = props;

    const tableData = useMemo(() => getTableData(facilityEmissions, colors), [facilityEmissions]);

    return (
        <>
            <Card>
                <div className={style.header}>
                    <h3 className="title">{"Emissions per facility"}</h3>
                </div>
                <CardContent className={style.cardContent} sx={{ minHeight: minHeight }}>
                    {facilityEmissions === undefined && (
                        <div className={style.loadingContainer} style={{ height: minHeight }}>
                            <Loading isLoading />
                        </div>
                    )}
                    {facilityEmissions && (
                        <>
                            {facilityEmissions.length === 0 && (
                                <ErrorComponent />
                            )}

                            {facilityEmissions.length > 0 && (
                                <>
                                    <div className={style.colorLabels}>
                                        {tableData.applicationSectionsWithTitleAndColor.map((value) => {
                                            return (
                                                <div key={`${value.section}`} style={{ display: "flex", alignItems: "center" }}>
                                                    <div id="color1" className={style.colorSample} style={{ backgroundColor: value.color }} />
                                                    <label htmlFor="color1">{value.title}</label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={style.table}>
                                        <GenericTable
                                            showHeadingRow={true}
                                            pagination={true}
                                            headers={tableData.headers.map((str) => {
                                                return { header: str };
                                            })}
                                            data={tableData.rows}
                                            getWidth={(column) => {
                                                switch (column) {
                                                    case 'Emissions Breakdown':
                                                        return 500;
                                                    default:
                                                        return 100;
                                                }
                                            }}
                                            dataRenderer={function (data: IFacilityDataForTable, column: string): ReactNode {
                                                switch (column) {
                                                    case 'Facility':
                                                        return data.facilityName;
                                                    case 'Location':
                                                        return data.location;
                                                    case 'Number of employees':
                                                        return data.numberOfEmployees;
                                                    case 'Emissions Breakdown':
                                                        return data.horizontalChart;
                                                    case 'Total emissions tCO2 e':
                                                        return data.totalEmissions;
                                                }
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    )
}
