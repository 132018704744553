import { Card, CardContent } from '@mui/material';
import { useContext } from 'react';
import { FoundationThemeContext } from '../../contexts/FoundationThemeContext';
import GenericTable, { IGenericTableProps } from './GenericTable';
import style from "./GenericTable.module.css"

interface IProps {
    title: string;
    maxHeight?: string;
}

function GenericTableWithTitle<T>({ title, maxHeight, ...rest }: IProps & IGenericTableProps<T>) {

    const { primaryTextColor } = useContext(FoundationThemeContext);

    return (
        <Card className={style.genericTable_tableCard}>
            <div className={style.genericTable_textHeadingConatiner}>
                <div className={style.genericTable_tableTitle} style={{ color: primaryTextColor }}>{title}</div>
            </div>
            <CardContent className={style.genericTable_cardContent}>
                <GenericTable maxHeight={maxHeight} {...rest} />
            </CardContent>
        </Card>
    )
}

export default GenericTableWithTitle
