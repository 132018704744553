import { FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput, Select, SelectChangeEvent, styled } from "@mui/material";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Loading } from "../loading/Loading";
import styles from "./Filters.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyDataContextV2 } from "../../contexts/CompanyDataContextV2";
import { getLastNCalendarYears, getLastNFinancialYears, getRolling12Month } from "../../utils/Utils";

interface IProps {
    showDates?: boolean;
    showFacilityFilter?: boolean;
    showESGFilters?: "E" | "S" | "G";
    children: ReactNode;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    }
};

export const Filters = (props: IProps) => {
    const { showDates, showFacilityFilter, showESGFilters } = props;

    const {
        handleDateRangeChange,
        isLoadingCompanyEmissions,
        facilities,
        selectedFacility,
        selectFacility,
    } = useContext(CompanyDataContextV2);


    const ranges = useMemo(() => {
        const durations: { start: Date, end: Date; label: string }[] = [];
        durations.push(getRolling12Month());
        getLastNFinancialYears().forEach((financialYear) => durations.push(financialYear));
        getLastNCalendarYears().forEach((calendarYear) => durations.push(calendarYear));
        return durations;
    }, []);
    const { selectedBorderColor } = useContext(FoundationThemeContext);
    const [selectedRange, setSelectedRange] = useState<string>(ranges.at(0)!.label);

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedRange(event.target.value as string);
    };

    const StyledSelect = styled(Select)(() => ({
        [`&.MuiOutlinedInput-root`]: {
            backgroundColor: "#fff",
            height: "50px",
            "& fieldset": {
                borderColor: selectedBorderColor
            },
            "&:hover fieldset": {
                borderColor: selectedBorderColor
            },
            "&.Mui-focused fieldset": {
                borderColor: selectedBorderColor
            }
        },
    }))

    const { primaryTextColor, primaryColor } = useContext(FoundationThemeContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const queryStr = useLocation().search;

    const esgEButtonDropdownMenu = [
        {
            title: "Company",
            onClick: () => {
                navigate({
                    pathname: "/E/company-emissions",
                    search: queryStr,
                });
                handleClose();
            }
        },
        {
            title: "Facility",
            onClick: () => {
                navigate({
                    pathname: "/E/facility-emissions",
                    search: queryStr,
                });
                handleClose();
            }
        },
    ];

    const esgButtonSelectedStyle = {
        color: "#FFF",
        backgroundColor: primaryTextColor,
        width: "80px",
        borderRadius: "8px",
        height: "40px !important",
        ":hover": {
            color: "#FFF",
            backgroundColor: primaryTextColor,
        }
    };

    const esgButtonStyle = {
        color: primaryTextColor,
        backgroundColor: primaryColor,
        width: "80px",
        borderRadius: "8px",
        height: "40px !important",
        ":hover": esgButtonSelectedStyle
    };

    useEffect(() => {
        const range = ranges.find((range) => range.label === selectedRange);
        if (range) {
            handleDateRangeChange(range.start, range.end);
        }
    }, [handleDateRangeChange, selectedRange, ranges]);


    return (
        <>
            <div className={styles.headerContainer}>
                <div className={styles.path}>

                </div>
                <div className={styles.filters}>
                    {showESGFilters && (
                        <div className={styles.esgFilters} style={{ borderBottom: "1px solid #CECECE" }}>
                            <div className={styles.esgButtonDiv} style={{ borderBottom: (showESGFilters === "E") ? `3px solid ${selectedBorderColor}` : "none" }}>
                                <IconButton
                                    aria-label="fingerprint" color="success"
                                    onClick={(e) => {
                                        handleClick(e);
                                    }}
                                    sx={(showESGFilters === "E") ? esgButtonSelectedStyle : esgButtonStyle}
                                >
                                    {"E"}
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <Menu
                                    id="e-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {esgEButtonDropdownMenu.map(item => (
                                        <MenuItem onClick={item.onClick} key={item.title} value={item.title}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            <div className={styles.esgButtonDiv} style={{
                                marginLeft: "20px",
                                borderBottom: (showESGFilters === "S") ? `3px solid ${selectedBorderColor}` : "none"
                            }}>
                                <IconButton
                                    aria-label="fingerprint" color="success"
                                    onClick={() => {
                                        navigate({
                                            pathname: "/S",
                                            search: queryStr,
                                        });
                                    }}
                                    sx={showESGFilters === "S" ? esgButtonSelectedStyle : esgButtonStyle}
                                >
                                    {"S"}
                                </IconButton>
                            </div>
                            <div className={styles.esgButtonDiv} style={{
                                marginLeft: "20px",
                                borderBottom: (showESGFilters === "G") ? `3px solid ${selectedBorderColor}` : "none"
                            }}>
                                <IconButton
                                    aria-label="fingerprint" color="success"
                                    onClick={() => {
                                        navigate({
                                            pathname: "/G",
                                            search: queryStr,
                                        });
                                    }}
                                    sx={showESGFilters === "G" ? esgButtonSelectedStyle : esgButtonStyle}
                                >
                                    {"G"}
                                </IconButton>
                            </div>
                        </div >
                    )}
                    {isLoadingCompanyEmissions && (
                        <div className={styles.facilityFilters}>
                            <Loading text="" isLoading={isLoadingCompanyEmissions} />
                        </div>
                    )}
                    {showDates && (
                        <div className={styles.dateRange}>
                            <LocalizationProvider dateAdapter={DateFnsUtils} >
                                <FormControl fullWidth>
                                    <InputLabel id="duration-label">Duration</InputLabel>
                                    <StyledSelect
                                        labelId="duration-label"
                                        id="duration-select"
                                        value={selectedRange}
                                        label="Duration"
                                        onChange={(event) => {
                                            handleChange(event as SelectChangeEvent);
                                        }}
                                    >
                                        {ranges.map((range) => <MenuItem key={range.label} value={range.label}>{range.label}</MenuItem>)}
                                    </StyledSelect>
                                </FormControl>
                            </LocalizationProvider>
                        </div>
                    )}
                    {(showFacilityFilter && !isLoadingCompanyEmissions && facilities) && (
                        <div className={styles.facilityFilters}>
                            <FormControl sx={{ width: "200px" }}>
                                <InputLabel id="facility-label">Facility</InputLabel>
                                <StyledSelect
                                    labelId="facility-label"
                                    id="facility"
                                    value={selectedFacility?.id}
                                    onChange={(event) => {
                                        const facilityId = event.target.value as string;
                                        selectFacility(facilityId);
                                    }}
                                    input={<OutlinedInput label="facility" />}
                                    MenuProps={MenuProps}
                                >
                                    {facilities.length === 0 && (
                                        <MenuItem>
                                            {"No facilities present"}
                                        </MenuItem>
                                    )}
                                    {facilities.map((facilityDetail) => (
                                        <MenuItem
                                            key={facilityDetail.name}
                                            value={facilityDetail.id}
                                        >
                                            {facilityDetail.name}
                                        </MenuItem>
                                    ))}
                                </StyledSelect>
                            </FormControl>
                        </div>
                    )}
                </div>
            </div>
            {props.children}
        </>
    );

}
