import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Radio, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useCallback, useContext, useEffect, useState } from "react";
import { createActionPlan, getActionItems, updateActionPlan } from "../../api/ActionPlan";
import { FacilitiesDataContext } from "../../contexts/FacilityDataContext";
import { ActionPlanItem, IActionPlanItemView, IActionPlanView } from "../../models/ActionPlan";
import { IFacility } from "../../models/Facility";
import { FoundationError } from "../../models/FoundationError";
import GenericDialog from "../genericDialog/GenericDialog";
import GenericTable from "../genericTable/GenericTable";
import styles from "./CreateActionPlanDialog.module.css";
import DateFnsUtils from '@date-io/date-fns';
import { useForm } from "react-hook-form";
import { ToastContext } from "../../contexts/ToastContext";

interface ICreateActionPlanDialog {
    actionPlan: IActionPlanView | undefined;
    isOpenModal: boolean;
    onClose: (success: boolean) => void;
}

interface ICreateActionPlanRequest {
    facilityId: string;
    actionPlanItem: ActionPlanItem;
    startDate: string;
    endDate: string;
}

export const CreateOrUpdateActionPlanDialog: React.FC<ICreateActionPlanDialog> = (props) => {
    const {
        isOpenModal,
        onClose,
        actionPlan
    } = props;
    const { facilities } = useContext(FacilitiesDataContext);
    const [actionPlanItems, setActionPlanItems] = useState<IActionPlanItemView[]>([]);

    const defaultSelectedFacility = actionPlan ? facilities.find((facility) => facility.id === actionPlan.facilityView.id) : undefined

    const [selectedFacility, setSelectedFacility] = useState<IFacility | undefined>(defaultSelectedFacility);
    const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlanItem | undefined>(actionPlan?.actionPlanItem);
    const [loadingActionPlanItems, setLoadingActionPlanItems] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(actionPlan ? new Date(actionPlan.startDate) : null);
    const [endDate, setEndDate] = useState<Date | null>(actionPlan ? new Date(actionPlan.endDate) : null);
    const [creatingActionPlan, setCreatingActionPlan] = useState(false);
    const { showToast } = useContext(ToastContext);

    const {
        register,
        handleSubmit,
        clearErrors,
        formState: {
            errors
        }
    } = useForm<ICreateActionPlanRequest>();

    const fetchActionItemsForFacility = useCallback(async (facility: IFacility) => {
        setLoadingActionPlanItems(true);
        const response = await getActionItems(facility);
        setLoadingActionPlanItems(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setActionPlanItems(response);
    }, [showToast]);

    useEffect(() => {
        if (actionPlan) {
            const actionPlanItem: IActionPlanItemView = {
                item: actionPlan.actionPlanItem,
                category: actionPlan.actionPlanCategory,
                description: actionPlan.description,
                reductionPotential: actionPlan.getEmissionReduction
            }
            setActionPlanItems([actionPlanItem]);
            return;
        }
        if (selectedFacility) {
            fetchActionItemsForFacility(selectedFacility);
        }
    }, [selectedFacility, fetchActionItemsForFacility, actionPlan]);

    const onSubmit = async (createActionItemRequest: ICreateActionPlanRequest) => {
        const shouldUpdate = actionPlan !== undefined;
        setCreatingActionPlan(true);
        const { facilityId, actionPlanItem, startDate, endDate } = createActionItemRequest;
        const response = await (shouldUpdate ? updateActionPlan(actionPlan, startDate, endDate) : createActionPlan(facilityId, actionPlanItem, startDate, endDate));
        setCreatingActionPlan(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        onClose(true);
    }

    return (
        <>
            <GenericDialog
                isOpenModal={isOpenModal}
                onClose={() => { onClose(false) }}
                title={actionPlan !== undefined ? "Update Action Plan" : "Create Action Plan"}
                btnText="Submit"
                maxWidth="lg"
                btnAction={handleSubmit(onSubmit)}
                btnLoading={creatingActionPlan}
            >
                <>
                    <FormControl sx={{ width: 1 / 2 }}>
                        <InputLabel id="select-facility-label">{actionPlan !== undefined ? "Facility" : "Select Facility"}</InputLabel>
                        <Select
                            labelId="select-facility-label"
                            value={selectedFacility?.id ?? ""}
                            label={actionPlan !== undefined ? "Facility" : "Select Facility"}
                            {...register("facilityId")}
                            disabled={actionPlan !== undefined}
                            onChange={(e) => {
                                const facilityId = e.target.value;
                                if (facilityId.length > 0) {
                                    const facility = facilities.filter((facility) => facility.id === facilityId)[0];
                                    setSelectedFacility(facility);
                                }
                            }}
                        >
                            {facilities.map((facility) =>
                                <MenuItem value={facility.id} key={facility.id}>
                                    {facility.name}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    {loadingActionPlanItems && actionPlanItems.length === 0 && (
                        <div className={styles.createActionPlanDialog_loadingDiv}>
                            <CircularProgress
                                size={30}
                                color={"inherit"}
                            />
                        </div>
                    )}

                    {!loadingActionPlanItems && actionPlanItems.length > 0 && (
                        <div className={styles.createActionPlanDialog_actionPlanItems}>
                            <div className={styles.createActionPlanDialog_dateRange}>
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <div className={styles.createActionPlanDialog_to}>
                                        Date Range:
                                    </div>
                                    <MobileDatePicker
                                        views={['year', 'month']}
                                        inputFormat="MMM-yyyy"
                                        value={startDate}
                                        label="Start"
                                        openTo="year"
                                        toolbarTitle="Start Date"
                                        closeOnSelect
                                        maxDate={endDate}
                                        onChange={(newDate) => {
                                            clearErrors("startDate");
                                            setStartDate(newDate);
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    error={errors.startDate !== undefined}
                                                    helperText={errors.startDate?.message ?? ""}
                                                    style={{ width: "150px" }}
                                                    {...register("startDate", { required: "Start date required" })}
                                                />
                                            );
                                        }}
                                    />

                                    <div className={styles.createActionPlanDialog_to}>
                                        {"to"}
                                    </div>

                                    <MobileDatePicker
                                        views={['year', 'month']}
                                        inputFormat="MMM-yyyy"
                                        value={endDate}
                                        label="End"
                                        toolbarTitle="End Date"
                                        openTo="year"
                                        closeOnSelect
                                        minDate={startDate}
                                        onChange={(newDate) => {
                                            clearErrors("endDate");
                                            setEndDate(newDate);
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                size="small"
                                                error={errors.endDate !== undefined}
                                                helperText={errors.endDate?.message ?? ""}
                                                style={{ width: "150px" }}
                                                {...register("endDate", { required: "End date required" })}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className={styles.createActionPlanDialog_actionItemsList}>
                                <GenericTable
                                    headers={["", "Category", "Description", "Reduction Potential in tCO2e (last 12 months)"]
                                        .map((str) => {
                                            return { header: str };
                                        })}
                                    data={actionPlanItems}
                                    showHeadingRow
                                    cursor="pointer"
                                    dataRenderer={(actionPlanData, column) => {
                                        switch (column) {
                                            case "":
                                                return (
                                                    <Radio
                                                        checked={(selectedActionPlan === actionPlanData.item)}
                                                        value={actionPlanData.item}
                                                        disabled={actionPlan !== undefined}
                                                        {...register("actionPlanItem", actionPlan === undefined ? { required: "Select at least one action plan" } : undefined)}
                                                    />
                                                );
                                            case "Description":
                                                return actionPlanData.description;
                                            case "Category":
                                                return actionPlanData.category;
                                            case "Reduction Potential in tCO2e (last 12 months)":
                                                return `${actionPlanData.reductionPotential}`;
                                        }
                                    }}
                                    onRowClick={(actionPlan) => {
                                        setSelectedActionPlan(actionPlan.item);
                                        clearErrors("actionPlanItem");
                                    }}
                                />
                                {errors.actionPlanItem !== undefined && (
                                    <div className={styles.createActionPlanDialog_actionItemError}>
                                        {errors.actionPlanItem.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            </GenericDialog>
        </>
    );
}
