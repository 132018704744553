import { IMonthlyEmissionAnalyticsData } from "../models/ActionPlan";
import { ICompanyEmissionsV2 } from "../models/CompanyEmissionsV2";
import { FoundationError } from "../models/FoundationError";
import { IGetGovernanceDashboardReportsResponse } from "../models/Governance";
import { ICreateReportRequest, IReportView, ISectionIdToQuestionIdToAnswer, ReportType } from "../models/Report";
import { IGetSocialDashboardReportsResponse } from "../models/Social";
import { BACKEND_URL_V1, BACKEND_URL_V2, deletee, get, post } from "../utils/Api";
import { formatStartEndDatesToMonthYear } from "../utils/DateUtils";
import { downloadFile } from "../utils/FileUtils";

export const getProcessedCompanyDataV2 = async (
    companyId: string,
    startDate: Date,
    endDate: Date
): Promise<ICompanyEmissionsV2 | FoundationError> => {
    try {
        const [start, end] = formatStartEndDatesToMonthYear(startDate, endDate, false);
        const response = await get<ICompanyEmissionsV2>(`${BACKEND_URL_V2}/companies/${companyId}/processedData?startDate=${start}&endDate=${end}`);

        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch company data";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const getReports = async (companyId: string): Promise<IReportView[] | FoundationError> => {
    try {
        const response = await get<{ reports: IReportView[] }>(`${BACKEND_URL_V1}/companies/${companyId}/reports`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.reports;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch reports";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const getEmissionsReport = async (companyId: string, year: number): Promise<null | FoundationError> => {
    try {
        const response = await get(`${BACKEND_URL_V2}/companies/${companyId}/reports/emission?year=${year}`, false);

        if (Math.floor(response.status / 100) === 2) {
            downloadFile(
                response.headers,
                await response.blob(),
                `Annual_Emissions_report_${year}.pdf`
            );
            return null;
        }

        const errorMessage =
            response.serverError?.error.message ??
            "Emissions report could not be downloaded";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};


export const getReport = async (companyId: string, reportId: string): Promise<IReportView | FoundationError> => {
    try {
        const response = await get<{ report: IReportView }>(`${BACKEND_URL_V1}/companies/${companyId}/reports/${reportId}`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.report;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const deleteReport = async (companyId: string, reportId: string): Promise<null | FoundationError> => {
    try {
        const response = await deletee(`${BACKEND_URL_V1}/companies/${companyId}/reports/${reportId}`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to delete report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const updateReport = async (
    companyId: string,
    reportId: string,
    sectionIdToQuestionIdToAnswerMap: ISectionIdToQuestionIdToAnswer
): Promise<IReportView | FoundationError> => {
    try {
        const response = await post<{ report: IReportView }>(`${BACKEND_URL_V1}/companies/${companyId}/reports/${reportId}`, { sectionIdToQuestionIdToAnswerMap });
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.report;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to update report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const createReport = async (companyId: string, type: ReportType, request: ICreateReportRequest): Promise<IReportView | FoundationError> => {
    try {
        const response = await post<{ reportView: IReportView }>(`${BACKEND_URL_V1}/companies/${companyId}/reports/new/types/${type}`, request);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.reportView;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch reports";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const completeReport = async (
    companyId: string,
    reportId: string,
): Promise<null | FoundationError> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/companies/${companyId}/reports/${reportId}/complete`);

        if (Math.floor(response.status / 100) === 2) {
            return null;
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to submit report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const downloadReport = async (companyId: string, reportId: string): Promise<null | FoundationError> => {
    try {
        const response = await get(`${BACKEND_URL_V1}/companies/${companyId}/reports/${reportId}/pdf`, false);

        if (Math.floor(response.status / 100) === 2) {
            downloadFile(
                response.headers,
                await response.blob(),
                `REPORT.pdf`
            );
            return null;
        }

        const errorMessage =
            response.serverError?.error.message ??
            "Emissions report could not be downloaded";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const getGovernanceData = async (companyId: string): Promise<IGetGovernanceDashboardReportsResponse | FoundationError> => {
    try {
        const response = await get<IGetGovernanceDashboardReportsResponse>(`${BACKEND_URL_V1}/companies/${companyId}/reports/governance`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const getSocialData = async (companyId: string): Promise<IGetSocialDashboardReportsResponse | FoundationError> => {
    try {
        const response = await get<IGetSocialDashboardReportsResponse>(`${BACKEND_URL_V1}/companies/${companyId}/reports/social`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const getEmissionsAnalytics = async (companyId: string): Promise<IMonthlyEmissionAnalyticsData[] | FoundationError> => {
    try {
        const response = await get<{ monthlyEmissionAnalyticsData: IMonthlyEmissionAnalyticsData[] }>(`${BACKEND_URL_V2}/companies/${companyId}/emissionsAnalytics`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.monthlyEmissionAnalyticsData;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch report";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const uploadCompanyLogo = async (companyId: string, file: File): Promise<null | FoundationError> => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await post(`${BACKEND_URL_V2}/companies/${companyId}/logo`, formData, { method: "post", body: formData });
        if (response.status === 200) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Logo could not be uploaded";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}
