import { FoundationError } from "../models/FoundationError";
import { ICustomReportTemplate, ICustomReportTemplateView, ICustomReportTemplateViewWithData } from "../models/Report";
import { BACKEND_URL_V1, get, post } from "../utils/Api";

export const getCustomReportTemplateDetails = async (): Promise<ICustomReportTemplateView[] | FoundationError> => {
    try {
        const response = await get<{ customReportTemplateViews: ICustomReportTemplateView[] }>(`${BACKEND_URL_V1}/customReportTemplates`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.customReportTemplateViews;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch reports";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const getCustomReportTemplateData = async (customReportId: string): Promise<ICustomReportTemplateViewWithData | FoundationError> => {
    try {
        const response = await get<{ customReportTemplateViewWithData: ICustomReportTemplateViewWithData }>(`${BACKEND_URL_V1}/customReportTemplates/${customReportId}`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.customReportTemplateViewWithData;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to fetch reports";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const saveCustomReportTemplate = async (reportTemplate: ICustomReportTemplate): Promise<ICustomReportTemplateView | FoundationError> => {
    try {
        const response = await post<{ customReportTemplateView: ICustomReportTemplateView }>(`${BACKEND_URL_V1}/customReportTemplates`, reportTemplate);

        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.customReportTemplateView;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to create report template";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const updateCustomReportTemplate = async (customReportId: string, reportTemplate: ICustomReportTemplate): Promise<ICustomReportTemplateView | FoundationError> => {
    try {
        const response = await post<{ customReportTemplateView: ICustomReportTemplateView }>(`${BACKEND_URL_V1}/customReportTemplates/${customReportId}`, reportTemplate);

        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody) {
                return response.parsedBody.customReportTemplateView;
            }
        }
        const errorMessage = response.serverError?.error.message ?? "Failed to update report template";
        return new FoundationError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};
