import { ICompany } from "./Company";
import { IFacility } from "./Facility";
import { Role } from "./Role";

export interface IUserProfile {
  id: string;
  name: string;
  email: string;
  role: Role;
  phoneNumber?: string;
  parentCompanyId: string;
  designationInCompany: string;
  facilityViews: IFacility[];
  companyView: ICompany;
}

export const isRoleAdmin = (role: Role) =>
  role === Role.ADMIN || role === Role.SUPER_ADMIN;
