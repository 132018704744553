import { themeConfig } from "../configs/ConfigProvider";
import React, { createContext } from "react";

export const FoundationThemeContext = createContext<ReturnType<typeof themeConfig>>(
  undefined!
);

export const FoundationThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const config = themeConfig();
  return (
    <FoundationThemeContext.Provider value={config}>
      {children}
    </FoundationThemeContext.Provider>
  );
};
