import { Button, Card, CardContent, TextField } from "@mui/material"
import style from "./GenericSummaryComponent.module.css"
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useContext } from "react";
import { FoundationThemeContext } from "../../../contexts/FoundationThemeContext";
import { Loading } from "../../loading/Loading";
import { ErrorComponent } from "../../errorComponent/ErrorComponent";
import { toFixed } from "../../../utils/Utils";

interface ISummaryProps {
    data: IGenericSummaryComponentItemData[][];
    unit?: string;
    showDates?: boolean;
    minHeight?: number;
    selectedDateRange?: {
        start: Date | null;
        end: Date | null;
    };
    dateOnChange?: (start: Date | null, end: Date | null) => void;
    firstButtonText?: string;
    firstButtonActionOnClick?: () => void;
    secondButtonText?: string;
    secondButtonActionOnClick?: () => void;
    isLoading?: boolean;
}

export interface IGenericSummaryComponentItemData {
    key?: string,
    value?: number,
    unit?: string;
    progress?: number;
};

export const GenericSummaryComponent = (props: ISummaryProps) => {

    const {
        firstButtonText,
        secondButtonText,
        firstButtonActionOnClick = () => { },
        secondButtonActionOnClick = () => { },
        isLoading
    } = props;

    const { unit, showDates = false, data, selectedDateRange, dateOnChange } = props;
    const { minHeight = 300 } = props;

    const { primaryColor, selectedBorderColor, primaryColorLight } = useContext(
        FoundationThemeContext
    );

    const buttonStyle = {
        backgroundColor: primaryColorLight,
        color: "black",
        border: `1px solid ${selectedBorderColor}`,
    };


    const isEmpty = data.length === 0 ||
        data.map(e => {
            return e.map(obj => {
                return (obj.value !== undefined && obj.value === 0) || (obj.progress !== undefined && obj.progress === 0);
            })
                .filter(isZero => isZero === true).length === e.length;
        })
            .filter(isZero => isZero === true).length === data.length;

    return (
        <>
            <Card className={style.card}>
                <div className={style.summaryHeader}>
                    <h3 className="title">{"Summary"}</h3>
                    {!isLoading && !isEmpty && unit && (<p>{unit}</p>)}
                </div>
                <CardContent className={style.cardContent} style={{ minHeight: `${minHeight}px` }}>
                    {isLoading && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: minHeight }}>
                            <Loading isLoading />
                        </div>
                    )}
                    {!isLoading && (
                        <>
                            {isEmpty ?
                                (<ErrorComponent />) :
                                (
                                    <>
                                        {showDates && (
                                            <div className={style.dateRange}>
                                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                                    <MobileDatePicker
                                                        views={['year', 'month']}
                                                        inputFormat="MMM yyyy"
                                                        disableFuture={true}
                                                        maxDate={new Date()}
                                                        value={selectedDateRange?.start}
                                                        onChange={(date: Date | null) => {
                                                            if (dateOnChange && date) {
                                                                dateOnChange(date, null)
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField size="small" style={{ width: "100px" }} {...params} />}
                                                    />
                                                    {"to"}
                                                    <MobileDatePicker
                                                        views={['year', 'month']}
                                                        inputFormat="MMM yyyy"
                                                        disableFuture={true}
                                                        maxDate={new Date()}
                                                        value={selectedDateRange?.end}
                                                        onChange={(date: Date | null) => {
                                                            if (dateOnChange && date) {
                                                                dateOnChange(null, date)
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField size="small" style={{ width: "100px" }} {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        )}
                                        <div className={style.summary}>
                                            <>
                                                {data?.map((sections, index) =>
                                                    <div className={style.section} key={index}>
                                                        {sections.map((section, sectionIndex) => (
                                                            <div key={section.key + "" + sectionIndex}>
                                                                {(section.progress !== undefined) ?
                                                                    (
                                                                        <div className={style.row}>
                                                                            <div className={style.progressBarContainer} style={{ backgroundColor: primaryColor }}>
                                                                                <div className={style.progressBar} style={{ backgroundColor: selectedBorderColor, width: `${section.progress ?? ""}%` }}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) :
                                                                    (
                                                                        <div className={style.row}>
                                                                            <p style={{
                                                                                wordWrap: "break-word",
                                                                                textAlign: "left"
                                                                            }}>{section.key}</p>
                                                                            <p style={{ fontWeight: index === 0 && sectionIndex === 0 ? "bold" : "" }}>{`${toFixed(section.value)}${section.unit ?? ""}`}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                        <div className={style.actionsButtons} style={{ justifyContent: firstButtonText && secondButtonText ? "space-between" : "right" }}>
                                            {firstButtonText && (
                                                <Button
                                                    style={{ ...buttonStyle, textTransform: "none", fontFamily: "Poppins" }}
                                                    variant="outlined"
                                                    onClick={firstButtonActionOnClick}
                                                >
                                                    {firstButtonText}
                                                </Button>
                                            )}
                                            {secondButtonText && (
                                                <Button
                                                    style={{ ...buttonStyle, textTransform: "none", fontFamily: "Poppins" }}
                                                    variant="outlined"
                                                    onClick={secondButtonActionOnClick}
                                                >
                                                    {secondButtonText}
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    )
}
