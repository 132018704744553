import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "../../utils/Auth";

const Logout: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    Auth.getInstance().logout();
    setLoggedIn(false);
  }, []);
  return isLoggedIn ? <div></div> : <Navigate to="/login" />;
};

export default Logout;
