import { InputAdornment, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import GenericDialog from "../genericDialog/GenericDialog";
import styles from "./CreateTargetDialog.module.css";
import DateFnsUtils from '@date-io/date-fns';
import { FoundationError } from "../../models/FoundationError";
import { ITargetView } from "../../models/Target";
import { createTarget, ICreateTargetRequest, updateTarget } from "../../api/Targets";
import { ToastContext } from "../../contexts/ToastContext";

interface IProps {
    target: ITargetView | undefined;
    open: boolean;
    onClose: () => void;
    onNewTargetCreated: (report: ITargetView) => void;
    onTargetUpdated: () => void;
}

const CreateOrEditTargetDialog: React.FC<IProps> = (props) => {
    const {
        target,
        open,
        onClose,
        onNewTargetCreated,
        onTargetUpdated
    } = props;

    const { showToast } = useContext(ToastContext);

    const {
        register,
        handleSubmit,
        clearErrors,
        formState: {
            errors
        }
    } = useForm<ICreateTargetRequest>({
        defaultValues: target ? {
            targetEmission: target.targetEmission
        } : undefined
    });

    const [isSubmitting, setSubmitting] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(target ? new Date(target.startDate) : null);
    const [endDate, setEndDate] = useState<Date | null>(target ? new Date(target.endDate) : null);

    const onSubmit = async (request: ICreateTargetRequest) => {

        const shouldUpdate = target !== undefined;

        setSubmitting(true);
        const response = await (shouldUpdate ? updateTarget(target, request) : createTarget(request));
        setSubmitting(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        if (shouldUpdate) {
            onTargetUpdated();
        } else if (response) {
            onNewTargetCreated(response);
        }
    }

    return (
        <>
            <GenericDialog
                isOpenModal={open}
                title={`${target ? "Update" : "Create"} Target`}
                btnText={"Submit"}
                btnAction={handleSubmit(onSubmit)}
                maxWidth={"sm"}
                btnLoading={isSubmitting}
                onClose={onClose}
            >
                <div className={styles.createTargetDialog_modalBody}>
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <MobileDatePicker
                            views={['year', 'month']}
                            inputFormat="MMM-yyyy"
                            value={startDate}
                            label={"Start Date*"}
                            toolbarPlaceholder={"Start Date"}
                            toolbarTitle={"Select"}
                            closeOnSelect={true}
                            onChange={(date) => {
                                clearErrors("startDate");
                                setStartDate(date)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.startDate !== undefined}
                                    helperText={errors.startDate ? "Start Date required" : ""}
                                    {...params}
                                    {...register("startDate", { required: true })}
                                />
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <MobileDatePicker
                            views={['year', 'month']}
                            inputFormat="MMM-yyyy"
                            value={endDate}
                            label={"End Date*"}
                            toolbarPlaceholder={"End Date"}
                            toolbarTitle={"Select"}
                            closeOnSelect={true}
                            onChange={(date: Date | null) => {
                                clearErrors("endDate");
                                setEndDate(date)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.endDate !== undefined}
                                    helperText={errors.endDate ? "End date required" : ""}
                                    {...params}
                                    {...register("endDate", { required: true })}
                                />
                            }
                        />
                        <TextField
                            type={"number"}
                            label="Target Emissions"
                            error={errors.targetEmission !== undefined}
                            helperText={errors.targetEmission ? "Name required" : ""}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            defaultValue={target?.targetEmission}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">tCO2e</InputAdornment>
                            }}
                            {...register("targetEmission", { required: true })}
                        />
                    </LocalizationProvider>
                </div>
            </GenericDialog>
        </>
    );
};

export default CreateOrEditTargetDialog;
