import React from "react";
import { Card, FormControl, InputLabel, MenuItem, OutlinedInput, Select, styled, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getFacilityDataCollectionQuestions as getFacilityDataCollectionQuestionsV2, postDataCollectionAnswerV2 } from "../../api/Facility";
import { FacilitiesDataContext } from "../../contexts/FacilityDataContext";
import { IDataCollectionQuestionWithAnswerV2, IDataCollectionSectionV2 } from "../../models/DataCollection";
import { IFacility } from "../../models/Facility";
import { FoundationError } from "../../models/FoundationError";
import styles from "./DataEntry.module.css"
import DateFnsUtils from '@date-io/date-fns';
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { ConfirmationDialog } from "../../components/confirmationDialog/ConfirmationDialog";
import { Loading } from "../../components/loading/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataCollectionSidebarV2 } from "../../components/dataCollectionSidebar/DataCollectionSidebarV2";
import { DataCollectionInputV2 } from "../../components/dataCollectionInput/DataCollectionInputV2";
import { ToastContext } from "../../contexts/ToastContext";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    }
};

export const DataEntryV2: React.FC = React.memo(() => {

    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { facilities } = useContext(FacilitiesDataContext);

    const [previousSelectedFacility, setPreviousSelectedFacility] = useState<IFacility>();
    const [previousSelectedDate, setPreviousSelectedDate] = useState<Date>();
    const [facility, setFacility] = useState<IFacility>();

    const [searchParams, setSearchParameters] = useSearchParams();
    const defaultFacilityId = searchParams.get("facility") as string;

    const [date, setDate] = useState<Date>(new Date());
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const [sections, setSections] = useState<IDataCollectionSectionV2[]>();
    const [selectedQuestion, setSelectedQuestion] = useState<IDataCollectionQuestionWithAnswerV2>();

    const { setValue, getValues, formState: { dirtyFields } } = useForm<IDataCollectionSectionV2[]>();

    const navigate = useNavigate();

    const { selectedBorderColor } = useContext(FoundationThemeContext);

    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        if (defaultFacilityId && defaultFacilityId !== "") {
            setFacility(facilities.find((facility) => facility.id === defaultFacilityId));
        } else if (facilities.length > 0) {
            setFacility(facilities[0]);
        }
    }, [defaultFacilityId, facilities])

    const StyledSelect = styled(Select)(() => ({
        [`&.MuiOutlinedInput-root`]: {
            backgroundColor: "#fff",
            height: "50px",
            "& fieldset": {
                borderColor: selectedBorderColor
            },
            "&:hover fieldset": {
                borderColor: selectedBorderColor
            },
            "&.Mui-focused fieldset": {
                borderColor: selectedBorderColor
            }
        },
    }))

    const StyledMobileDatePicker = styled(MobileDatePicker)(() => ({
        [`& .MuiInputBase-root`]: {
            backgroundColor: "#fff",
            width: "100px",
            "& fieldset": {
                borderColor: selectedBorderColor
            },
            "&:hover fieldset": {
                borderColor: selectedBorderColor
            },
            "&.Mui-focused fieldset": {
                borderColor: selectedBorderColor
            }
        }
    }));

    const fetchDataCollectionQuestions = useCallback(async (facilityId: string, date: Date) => {
        if (facilityId === "" || !facilityId || !date) {
            return;
        }
        setIsLoading(true);
        const sections = await getFacilityDataCollectionQuestionsV2(facilityId, date);
        setIsLoading(false);

        if (sections instanceof FoundationError) {
            const message = sections?.message ?? "Something went wrong!";
            showToast(message, "error");
            return;
        }

        setSelectedQuestion(sections[0].questionWithAnswers[0])
        setSections(sections);
    }, [showToast]);

    useEffect(() => {
        // first load
        if (!previousSelectedFacility) {
            if (facility) {
                fetchDataCollectionQuestions(facility.id, date);
            }
        }
    }, [facility, previousSelectedFacility, fetchDataCollectionQuestions, date])

    useEffect(() => {
        if (sections) {
            const input = getValues(`${selectedSectionIndex}.questionWithAnswers.${selectedQuestionIndex}.input`) as number | undefined;
            const question = sections[selectedSectionIndex].questionWithAnswers[selectedQuestionIndex];
            if (input !== undefined) {
                question.input = input;
            }

            setSelectedQuestion(question);
        }
    }, [selectedSectionIndex, selectedQuestionIndex, sections, getValues, selectedQuestion?.input])

    const onSelect = (sectionIndex: number, questionNumber: number) => {
        setSelectedSectionIndex(sectionIndex);
        setSelectedQuestionIndex(questionNumber);
    }

    const showNextQuestion = () => {
        if (sections) {
            if (selectedQuestionIndex < sections[selectedSectionIndex].questionWithAnswers.length - 1) {
                setSelectedQuestionIndex(selectedQuestionIndex + 1);
            } else if (selectedSectionIndex < sections.length - 1) {
                setSelectedSectionIndex(selectedSectionIndex + 1);
                setSelectedQuestionIndex(0);
            }
        }
    }

    const onSave = async (value: number | undefined, isLastQuestion: boolean) => {
        setValue(`${selectedSectionIndex}.questionWithAnswers.${selectedQuestionIndex}.input`, value, { shouldDirty: true });

        if (facility && selectedQuestion) {
            const response = await postDataCollectionAnswerV2(facility.id, date, selectedQuestion.kpiType, value);
            if (response instanceof FoundationError) {
                const errorMessage = response.message;
                showToast(errorMessage, "error");
            } else {
                if (!searchParams.has("refresh")) {
                    searchParams.set("refresh", "1");
                    setSearchParameters(searchParams);
                }
                showToast("Saved!", "success");
                if (isLastQuestion) {
                    setTimeout(() => {
                        navigate("/data-collection");
                    }, 2000);
                } else {
                    showNextQuestion();
                }
            }
        }
    }

    const displayConfirmationDialogIfRequired = (newFacilityId: string, newDate: Date) => {
        if (!dirtyFields) {
            fetchDataCollectionQuestions(newFacilityId, newDate);
        } else {
            setShowConfirmationDialog(true);
        }
    }

    const confirmFilterChange = () => {
        if (facility) {
            fetchDataCollectionQuestions(facility.id, date);
        }
        hideConfirmationDialog(false);
    }

    const hideConfirmationDialog = (shouldUndoFilterSelections: boolean) => {
        if (shouldUndoFilterSelections) {
            setFacility(previousSelectedFacility)
            setDate(previousSelectedDate ?? date)
        }
        setShowConfirmationDialog(false);
    };

    const isLastQuestion = sections
        && selectedSectionIndex === sections.length - 1
        && selectedQuestionIndex === sections[selectedSectionIndex].questionWithAnswers.length - 1;

    return (
        <>
            <DataCollectionSidebarV2
                data={sections ?? []}
                onSelect={onSelect}
                selectedSectionIndex={selectedSectionIndex}
                selectedQuestionIndex={selectedQuestionIndex}
                isLoading={isLoading}
                getValues={getValues}
            />

            <div className={styles.filters}>
                <FormControl sx={{ width: "200px" }}>
                    <InputLabel id="facility-label">Facility</InputLabel>
                    <StyledSelect
                        labelId="facility-label"
                        id="facility"
                        value={facility?.id ?? ""}
                        onChange={(event) => {
                            const facilityIdString = event.target.value as string;
                            setPreviousSelectedFacility(facility);
                            setFacility(facilities.find((facility) => facility.id === facilityIdString));
                            displayConfirmationDialogIfRequired(facilityIdString, date);
                        }}
                        input={<OutlinedInput label="facility" />}
                        MenuProps={MenuProps}
                    >
                        {facilities.length === 0 && (
                            <MenuItem>
                                {"No facilities present"}
                            </MenuItem>
                        )}
                        {facilities.length !== 0 && facilities.map((facilityDetail) => (
                            <MenuItem
                                key={facilityDetail.name}
                                value={facilityDetail.id}
                            >
                                {facilityDetail.name}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <StyledMobileDatePicker
                        views={['year', 'month']}
                        inputFormat="MMM yyyy"
                        disableFuture={true}
                        maxDate={new Date()}
                        value={date}
                        onChange={(newDate: any) => {
                            setPreviousSelectedDate(date);
                            setDate(newDate);
                            displayConfirmationDialogIfRequired(facility?.id ?? "", newDate);
                        }}
                        renderInput={(params) => <TextField size="small" style={{ width: "100px" }} {...params} />}
                    />
                </LocalizationProvider>

            </div>

            <ConfirmationDialog
                buttonText={"Confirm"}
                bodyText={"All unsaved data for the current facility will be lost, do you want to continue?"}
                open={showConfirmationDialog}
                onClose={() => {
                    hideConfirmationDialog(true);
                }}
                onApprove={() => confirmFilterChange()}
                isApproving={false}
            />

            {isLoading && (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "300px",
                }}>
                    <Loading text="" isLoading={true} />
                </div>
            )}

            {sections && sections.length > 0 && (
                <Card variant="outlined" className={styles.dataCollectionContainer}>
                    <div className={styles.dataPoint}>
                        <h3>{sections[selectedSectionIndex].title}</h3>
                    </div>
                    {selectedQuestion && (
                        <DataCollectionInputV2
                            selectedQuestion={selectedQuestion}
                            onSave={onSave}
                            isLastQuestion={isLastQuestion ?? false}
                        />
                    )}
                </Card>
            )}

        </>
    );
});
