import React from 'react';
import { formatTimestampToMonthYear } from '../../utils/DateUtils';
import GenericChartsWithTitle from '../genericChartsWithTitle/GenericChartsWithTitle';
import { IFacilityEmissionsV2 } from '../../models/CompanyEmissionsV2';
import { KpiSection } from '../../models/Kpi';
import { toFixed } from '../../utils/Utils';

interface IProps {
    isLoadingCompanyEmissions: boolean;
    selectedFacilityEmissions: IFacilityEmissionsV2 | undefined;
    section?: KpiSection;
}

type SectionToEmissionWithYearMonth = {
    [key: string]: number;
} & { yearMonth: string }

function getFacilityEmissionsOverTime(facilityEmissions: IFacilityEmissionsV2 | undefined, section: KpiSection | undefined) {
    const chartData: SectionToEmissionWithYearMonth[] = [];
    const chartKeys: string[] = [];
    let total = 0;
    if (facilityEmissions === undefined) {
        return { chartData, chartKeys, total };
    }

    facilityEmissions.monthlySectionWiseEmissions.forEach((sectionEmissionsForMonth) => {
        const data = {
            yearMonth: formatTimestampToMonthYear(sectionEmissionsForMonth.yearMonth)
        } as SectionToEmissionWithYearMonth;

        let applicableSections = sectionEmissionsForMonth.sectionEmissions;

        if (section !== undefined) {
            applicableSections = sectionEmissionsForMonth.sectionEmissions.filter((sectionEmission) => sectionEmission.section === section)
        }

        applicableSections.forEach((sectionEmission) => {
            const key = sectionEmission.sectionTitle;
            data[key] = sectionEmission.totalEmissions;
            total = total + sectionEmission.totalEmissions;
            if (!chartKeys.includes(key)) {
                chartKeys.push(key);
            }
        });

        chartData.push(data);
    });

    return { chartData, chartKeys, total };
}

const areaChartColors = [
    '#32E7A4',
    '#64B5FC',
    '#155EA2',
    '#0069E8',
    '#5B3A96',
    '#CE1D26',
    '#FFA600',
    '#7FC91B',
    '#04B1B4',
    '#FCEA64',
    '#CECECE',
    '#8D6BEB',
    '#878787'
];

const FacilityEmissionsOverTime: React.FC<IProps> = React.memo((props) => {
    const {
        isLoadingCompanyEmissions,
        selectedFacilityEmissions,
        section
    } = props;

    const facilityEmissionsOverTime = getFacilityEmissionsOverTime(selectedFacilityEmissions, section);

    return (
        <GenericChartsWithTitle
            title={"Facility Emissions Over Time"}
            isLoading={isLoadingCompanyEmissions}
            showActionButton
            showInfoIcon
            colors={areaChartColors}
            minHeight={110}
            areaChartProps={{
                data: facilityEmissionsOverTime.chartData,
                yAxisDataKeys: facilityEmissionsOverTime.chartKeys,
                xAxisDataKey: "yearMonth",
                labelFormatter: (data, key) => {
                    if (facilityEmissionsOverTime.total === 0) {
                        return `${data[key]} tCO2 (0%)`;
                    }
                    return `${data[key]} tCO2 (${toFixed(((data[key] * 100) / facilityEmissionsOverTime.total), 2)}%)`;
                }
            }}
            chartType={'area'}
        />
    );
});

export default FacilityEmissionsOverTime;
