import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import style from "./GenericBarChart.module.css";

export interface IGenericStackedBarChartProps {
    data: any[];
    yAxisDataKey: string;
    dataKeys: string[];
    colors?: string[];
    labelFormatter?: (data: any, key: string) => string;
}

function HorizontalStackedBarChart(props: IGenericStackedBarChartProps) {
    const {
        data,
        dataKeys,
        yAxisDataKey,
        colors,
        labelFormatter = (data, key) => data[key],
    } = props;

    const barColors = colors ?? [
        '#64B5FC',
        '#5B3A96',
        '#CE1D26',
        '#FFA600',
        '#7FC91B',
    ];

    const CustomTooltip = (data: any) => {
        if (data.active && data.payload) {
            return (
                <div className={`${style.genericBarChart_tooltipContainer} recharts-custom-tooltip`}>
                    <p>
                        {data.label}
                    </p>
                    <div>
                        {data.payload.map((i: any, index: number) => {
                            const keyIndex = dataKeys.findIndex((dataKey) => dataKey === i.dataKey);
                            return (
                                <div className={style.genericBarChart_customToolTipContainer} key={index}>
                                    <div
                                        className={style.genericBarChart_bulletContainer}
                                        style={{ backgroundColor: barColors[keyIndex] }}
                                    />
                                    <span className={style.genericBarChart_value}>
                                        {labelFormatter(i.payload, i.dataKey)}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className={style.genericBarChart_lineChartContainer}>
            <ResponsiveContainer aspect={10}>
                <BarChart
                    data={data}
                    layout="vertical"
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }}
                >
                    <Tooltip content={CustomTooltip} wrapperStyle={{ padding: "0px" }} />
                    <XAxis hide type="number" domain={['dataMin', 'dataMax']} />
                    <YAxis hide dataKey={yAxisDataKey} type="category" />
                    {dataKeys.map((dataKey, index) => {
                        const strokeColor = barColors[index % barColors.length];
                        return <Bar
                            type="monotone"
                            dataKey={dataKey}
                            stackId="a"
                            fill={strokeColor}
                            stroke={strokeColor}
                            strokeWidth={1.5}
                            maxBarSize={40}
                            key={index}
                        />;
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default HorizontalStackedBarChart;
