import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { FoundationError } from "../models/FoundationError";
import { IUserProfile } from "../models/User";
import { Config } from "./Config";

export class Auth {
  private static instance: Auth;

  private static readonly userSettingsStorageKey = "userSettings";

  private constructor() {
    initializeApp(Config.getInstance().getFirebaseConfig());
  }

  public static getInstance() {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  public async getToken(): Promise<string | FoundationError> {
    return new Promise<string | FoundationError>(function (resolve, reject) {
      getAuth().onAuthStateChanged(async function (user) {
        if (user) {
          const token = await user.getIdToken();
          if (token) {
            resolve(token);
          }
          reject(new FoundationError("token not found"));
        } else {
          reject(new FoundationError("user not found"));
        }
      });
    }).catch((error) => {
      if (error instanceof FoundationError) {
        return error;
      }
      console.log(error);
      return new FoundationError("Something went wrong");
    });
  }

  public async refreshToken() {
    await getAuth().currentUser?.getIdToken(true);
  }

  public isLoggedIn() {
    return this.getUserSettings() ? true : false;
  }

  public logout() {
    getAuth().signOut();
    this.clearUserSettings();
  }

  public saveUserSettings(userSettings: IUserProfile) {
    window.localStorage.setItem(Auth.userSettingsStorageKey, JSON.stringify(userSettings));
  }

  public getUserSettings(): IUserProfile | undefined {
    const userSettingsJson = window.localStorage.getItem(Auth.userSettingsStorageKey);
    if (userSettingsJson) {
      return JSON.parse(userSettingsJson);
    }

    return undefined;
  }

  private clearUserSettings() {
    window.localStorage.removeItem(Auth.userSettingsStorageKey);
  }

  public async signInWithToken(
    token: string
  ): Promise<FoundationError | undefined> {

    return await signInWithCustomToken(getAuth(), token)
      .then((result) => {
        return undefined;
      })
      .catch((error) => {
        console.log(error);
        return new FoundationError("Something went wrong");
      });
  }

}
