import style from "./GenericChartsWithTitle.module.css";
import GenericLineChart, { IGenericLineChartProps } from '../genericLineChart/GenericLineChart';
import { Button, Card, CardContent } from "@mui/material";
import { useContext, useState } from "react";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import GenericBarChart, { IGenericBarChartProps } from "../genericBarChart/GenericBarChart";
import { IStackedAreaProps, StackedAreaChart } from "../stackedAreaChart/StackedAreaChart";
import InfoIcon from '@mui/icons-material/Info';
import { ErrorComponent } from "../errorComponent/ErrorComponent";
import { Loading } from "../loading/Loading";
import { useNavigate, useLocation } from "react-router-dom";

interface IProps {
    title: string;
    subTitle?: string;
    showTabs?: boolean;
    showInfoIcon?: boolean;
    showActionButton?: boolean;
    colors?: string[],
    colorLegendHeadings?: string[];
    chartType: "bar" | "line" | "area";
    barChartProps?: IGenericBarChartProps,
    lineChartProps?: IGenericLineChartProps,
    areaChartProps?: IStackedAreaProps,
    bottomText?: string;
    minHeight?: number;
    isLoading?: boolean;
    hideColorLegend?: boolean;
    allowZero?: boolean;
}

const categories = [
    "Months",
    "Quarters",
    "Years"
]

function GenericChartsWithTitle(props: IProps) {
    const {
        title,
        colors,
        showActionButton,
        chartType,
        lineChartProps,
        barChartProps,
        areaChartProps,
        showInfoIcon,
        subTitle,
        showTabs,
        bottomText,
        minHeight = 400,
        isLoading,
        hideColorLegend,
        colorLegendHeadings,
        allowZero = false
    } = props;
    const { primaryTextColor } = useContext(FoundationThemeContext);

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const navigate = useNavigate();
    const queryStr = useLocation().search;

    let yAxisDataKeys = barChartProps?.dataKeys ?? lineChartProps?.yAxisDataKeys ?? areaChartProps?.yAxisDataKeys ?? [];
    const data = barChartProps?.data ?? lineChartProps?.data ?? areaChartProps?.data;

    const isEmpty = data === undefined || data?.length === 0 || (!allowZero &&
        data.map(e => {
            return yAxisDataKeys.map(dataKey => {
                return e[dataKey] === 0;
            }).filter(isZero => isZero === true).length === yAxisDataKeys.length
        }).filter(isZero => isZero === true).length === data.length);

    const categoryOnChange = (category: string) => {
        setSelectedCategory(category)
    }

    const lineColors = colors ?? [
        "#155EA2",
        "#64B5FC",
        "#32E7A4",
        "#FCEA64",
        "#04B1B4",
        "#DAE3F1",
    ]

    const { primaryColorLight, selectedBorderColor } = useContext(FoundationThemeContext);

    return (
        <>
            <Card className={style.genericLineChart_card}>
                <div className={style.genericLineChart_textHeadingConatiner}>
                    <div className={`${style.genericLineChart_title} title`}>{title}</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {showTabs && (
                            <div className={style.tabs}>
                                {categories.map((category, index) => {
                                    return <span key={index} onClick={() => { categoryOnChange(category) }} className={selectedCategory === category ? style.selected : ""}>{category}</span>
                                })}
                            </div>
                        )}
                        {showInfoIcon && (
                            <InfoIcon style={{ color: "#CECECE", marginLeft: "40px" }} />
                        )}
                    </div>
                </div>
                <div className={style.genericLineChart_textSubHeadingConatiner}>
                    {subTitle && <div className={style.genericLineChart_subTitle} style={{ color: primaryTextColor }}>{subTitle}</div>}
                </div>
                <CardContent className={style.genericLineChart_cardContent} style={{ minHeight: minHeight }}>
                    {isLoading && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: minHeight }}>
                            <Loading isLoading />
                        </div>
                    )}
                    {!isLoading && (
                        <>
                            {isEmpty ?
                                (<ErrorComponent />) :
                                (
                                    <>
                                        {!hideColorLegend && (
                                            <div className={style.colorLabels}>
                                                {yAxisDataKeys.map((key, index) => {
                                                    return (
                                                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                                                            <div id={`color${index}`} className={style.colorSample} style={{ backgroundColor: lineColors[index] }}></div>
                                                            <label htmlFor={`color${index}`}>{colorLegendHeadings ? colorLegendHeadings[index] : key}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {chartType === "line" && lineChartProps && (
                                            <GenericLineChart {...lineChartProps} colors={lineColors} />
                                        )}
                                        {chartType === "bar" && barChartProps && (
                                            <GenericBarChart {...barChartProps} colors={lineColors} />
                                        )}
                                        {chartType === "area" && areaChartProps && (
                                            <StackedAreaChart {...areaChartProps} colors={lineColors} />
                                        )}

                                        {bottomText && <p style={{ color: primaryTextColor }} className={style.bottomText}>{bottomText}</p>}

                                        {showActionButton && (
                                            <Button
                                                className={style.submitButton}
                                                style={{
                                                    marginLeft: "100px",
                                                    backgroundColor: primaryColorLight,
                                                    color: "black",
                                                    border: `1px solid ${selectedBorderColor}`,
                                                    float: "right",
                                                    marginTop: "10px",
                                                    textTransform: "none",
                                                    fontFamily: "Poppins"
                                                }}
                                                variant="outlined"
                                                onClick={() => {
                                                    navigate({
                                                        pathname: "/reduction",
                                                        search: queryStr,
                                                    });
                                                }}
                                            >
                                                Take action
                                            </Button>
                                        )}
                                    </>
                                )
                            }
                        </>
                    )}


                </CardContent>
            </Card>
        </>
    )
}

export default GenericChartsWithTitle
