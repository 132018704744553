import { useCallback, useContext, useEffect, useState } from "react";
import style from "./Target.module.css"
import { Button, Card, CardActionArea, CardContent, Grid, IconButton } from '@mui/material';
import { deleteTarget, getAllTargets } from "../../api/Targets";
import { FoundationError } from "../../models/FoundationError";
import { ITargetView } from "../../models/Target";
import { formatTimestampToMonthYear } from "../../utils/DateUtils";
import CreateOrEditTargetDialog from "../../components/createTargetDialog/CreateTargetDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from "../../components/confirmationDialog/ConfirmationDialog";
import { ToastContext } from "../../contexts/ToastContext";

export const Target: React.FC = () => {

    const [isCreateTargetDialogOpen, setIsCreateTargetDialogOpen] = useState(false);
    const [targets, setTargets] = useState<ITargetView[]>([]);
    const [isDeleteing, setIsDeleteing] = useState(false);
    const [selectedTarget, setSelectedTarget] = useState<ITargetView>();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const { showToast } = useContext(ToastContext);

    const getTargets = useCallback(async () => {
        const targets = await getAllTargets();
        if (targets instanceof FoundationError) {
            const message = targets?.message ?? "Something went wrong!";
            showToast(message, "error");
        } else {
            setTargets([...targets]);
        }
    }, [showToast]);

    useEffect(() => {
        getTargets();
    }, [getTargets]);

    const onDeleteTarget = async (targetId: string) => {
        setIsDeleteing(true);
        const response = await deleteTarget(targetId);
        setIsDeleteing(false);

        if (response instanceof FoundationError) {
            const message = response?.message ?? "Unable to delete target";
            showToast(message, "error");
            return;
        }
        setTargets(targets.filter(target => target.id !== selectedTarget?.id));
        showToast("Target deleted successfully", "success");
        setSelectedTarget(undefined);
    };

    return (
        <div className={style.actionRoot}>
            {selectedTarget && (
                <ConfirmationDialog
                    buttonText={"Confirm"}
                    bodyText={`Are you sure you want to delete the Target with emission ${selectedTarget.targetEmission}?`}
                    open={showConfirmationDialog}
                    onClose={() => {
                        setShowConfirmationDialog(false);
                    }}
                    onApprove={() => onDeleteTarget(selectedTarget.id)}
                    isApproving={isDeleteing}
                />
            )}

            {isCreateTargetDialogOpen && (
                <CreateOrEditTargetDialog
                    target={selectedTarget}
                    open={true}
                    onClose={() => {
                        setSelectedTarget(undefined);
                        setIsCreateTargetDialogOpen(false)
                    }}
                    onNewTargetCreated={(target) => {
                        setSelectedTarget(undefined);
                        setIsCreateTargetDialogOpen(false);
                        const newTargets = targets.concat(target);
                        setTargets([...newTargets]);
                    }}
                    onTargetUpdated={() => {
                        setSelectedTarget(undefined);
                        getTargets();
                        setIsCreateTargetDialogOpen(false);
                    }}
                />
            )}

            <Grid direction="column" item xs={3}>
                <div className={style.targetDescContainer}>
                    <div className={style.targetDescHeading}>Targets</div>
                    <div className={style.targetDesc}>
                        <p>The goal of target setting is to provide companies with a clear framework for improving their ESG performance,
                            and to allow them to take ownership of their impact on the environment, society and their governance.
                        </p>
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => setIsCreateTargetDialogOpen(true)}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Set target
                    </Button>
                </div>
            </Grid>

            <Grid direction="column" item xs={12}>
                <Grid container spacing={1} padding={3}>
                    {targets
                        .sort((a, b) => (a.targetEmission - b.targetEmission)) // sort so smallest targets ( largest reductions ) are at top
                        .map(item => {
                            return (
                                <Card key={item.id}
                                    className={style.targetCardContainer}>
                                    <CardActionArea onClick={() => {
                                        setIsCreateTargetDialogOpen(true);
                                        setSelectedTarget(item);
                                    }}>
                                        <CardContent sx={{ display: "flex", justifyContent: "space-around" }}>
                                            <Grid direction="row" item xs={9} key={item.startDate}>
                                                <div className={style.cardDesc}>Reduce my emissions to {item.targetEmission} tCO2e from {formatTimestampToMonthYear(item.startDate)} to {formatTimestampToMonthYear(item.endDate)}</div>
                                            </Grid>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowConfirmationDialog(true);
                                                    setSelectedTarget(item);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )
                        })}
                </Grid>
            </Grid>
        </div>
    );
}
