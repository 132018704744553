import { Button, TextField } from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import styles from "./RecoveryPage.module.css"
import FormControl from '@mui/material/FormControl';
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../api/Auth";
import { FoundationError } from "../../models/FoundationError";
import { Auth } from "../../utils/Auth";
import { PasswordRecoveryType } from "../../types/PasswordRecoveryType";
import { Loading } from "../../components/loading/Loading";
import { ToastContext } from "../../contexts/ToastContext";

const RecoveryPage = () => {

  const location = useLocation();
  const search = location.search;
  const type = new URLSearchParams(search).get('type');
  const code = new URLSearchParams(search).get('code');

  const { primaryColorLight, logoDark, primaryTextColor } = useContext(
    FoundationThemeContext
  );

  const [values, setValues] = useState({
    "newPassword": "",
    "confirmPassword": "",
  });

  const { showToast } = useContext(ToastContext);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    })
  }

  let titleText = "Reset Password"

  if (type === PasswordRecoveryType.FIRST_TIME_PASSWORD_CREATION) {
    titleText = "Set Password"
  }

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const reset = async () => {
    // TODO: add password validation rules
    if (values.newPassword.length < 8 || values.newPassword !== values.confirmPassword) {
      displayToast("Password entered is invalid", "error");
      return;
    }

    setIsLoading(true);
    const response = await resetPassword(values.newPassword, code ?? "");
    setIsLoading(false);

    if (response instanceof FoundationError) {
      setErrorMessage(response.message);
      displayToast(response.message, "error")
      return;
    }

    const error = await Auth.getInstance().signInWithToken(response.customToken)
    if (error instanceof FoundationError) {
      displayToast(error.message, "error")
      return;
    }
    setErrorMessage(undefined);
    const message = "Password has been reset successfully";
    displayToast(message, "success")
    const settings = response.getSettingsResponse
    Auth.getInstance().saveUserSettings(settings.userProfile)
    navigate("/")
  }

  const displayToast = (message: string, type: "error" | "success") => {
    showToast(message, type);
  }

  return (
    <div className={styles.recoveryPage_root} style={{ backgroundColor: primaryColorLight }}>
      <div className={styles.recoveryDiv}>
        <img src={logoDark} alt="logo" />
        <h2 style={{ color: primaryTextColor }}>{titleText}</h2>
        <h4 style={{ color: "red" }}>{errorMessage}</h4>
        <FormControl style={{ width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
            onChange={handleInputChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={reset}
          >
            <Loading text={"Submit"} isLoading={isLoading} />
          </Button>
        </FormControl>
      </div>
    </div>
  );
}

export default RecoveryPage;
