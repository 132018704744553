import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, Line, Scatter } from "recharts";
import { getEmissionsAnalytics } from "../../../api/Company";
import { FoundationThemeContext } from "../../../contexts/FoundationThemeContext";
import { IMonthlyEmissionAnalyticsData } from "../../../models/ActionPlan";
import { FoundationError } from "../../../models/FoundationError";
import { Auth } from "../../../utils/Auth";
import { formatTimestampToMonthYear } from "../../../utils/DateUtils";
import { createArrayWithNumbers, toFixed } from "../../../utils/Utils";
import styles from "./ReductionAnalytics.module.css";
import FlagIcon from '@mui/icons-material/Flag';
import { ToastContext } from "../../../contexts/ToastContext";

const COLOR_BUSINESS_AS_USUAL = "#FCEA64";
const COLOR_PREDICTIVE_BUSINESS_AS_USUAL = "#FCC464";
const COLOR_EMISSION_WITH_REDUCTION = "#32E7A4";
const COLOR_TARGETS = "#155EA2";

interface IAnalyticsEmissionsChartData {
    yearMonth: string;
    businessAsUsualEmissions?: number;
    predictiveBusinessAsUsualEmissions?: number;
    emissionsWithReductions?: number;
    targets: number[];
    skipTooltipForPredictiveEmissions?: boolean;
}

interface IAnalyticsChartData {
    emissions: IAnalyticsEmissionsChartData[];
    maxTargetsInAMonth: number;
}

function transformAnalyticsForChart(analytics: IMonthlyEmissionAnalyticsData[]): IAnalyticsChartData {
    const emissions: IAnalyticsEmissionsChartData[] = [];
    let maxTargetsInAMonth = 0;
    analytics.forEach((monthData) => {
        const data: IAnalyticsEmissionsChartData = {
            yearMonth: formatTimestampToMonthYear(monthData.yearMonth),
            businessAsUsualEmissions: monthData.isPredictive ? undefined : monthData.businessAsUsualEmissions,
            predictiveBusinessAsUsualEmissions: monthData.isPredictive ? monthData.businessAsUsualEmissions : undefined,
            emissionsWithReductions: monthData.emissionsWithReductions,
            targets: monthData.applicableTargets.map((value) => value.targetEmission)
        };

        emissions.push(data);
        if (maxTargetsInAMonth < monthData.applicableTargets.length) {
            maxTargetsInAMonth = monthData.applicableTargets.length;
        }
    });

    for (let i = 0; i < emissions.length; i++) {
        const data = emissions.at(i);
        if (data?.predictiveBusinessAsUsualEmissions !== undefined) {
            const previousData = emissions.at(i - 1);
            if (previousData) {
                previousData.predictiveBusinessAsUsualEmissions = previousData.businessAsUsualEmissions;
                previousData.skipTooltipForPredictiveEmissions = true;
            }
            break;
        }
    }

    return {
        emissions,
        maxTargetsInAMonth
    };
}

export const ReductionAnalytics: React.FC = () => {
    const [analyticsData, setAnalyticsData] = useState<IAnalyticsChartData>();
    const [fetchingAnalytics, setFetchingAnalytics] = useState(false);
    const { showToast } = useContext(ToastContext);

    const getAnalytics = useCallback(async (companyId: string) => {
        setFetchingAnalytics(true);
        const response = await getEmissionsAnalytics(companyId);
        setFetchingAnalytics(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setAnalyticsData(transformAnalyticsForChart(response));
    }, [showToast]);

    useEffect(() => {
        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings) {
            getAnalytics(userSettings.companyView.id);
        }
    }, [getAnalytics]);

    const CustomTargetIconShape = (data: any) => {
        const { cx, cy, y } = data;
        if (y > 0) {
            return (
                <svg x={cx - 10} y={cy - 10} width={20} height={20} viewBox="0 0 1024 1024">
                    <FlagIcon style={{ fill: COLOR_TARGETS }} />
                </svg>
            );
        }

        return <g />;
    }

    const CustomTooltip = (data: any) => {
        if (data.active && data.payload) {
            return (
                <div className={`${styles.reductionAnalytics_tooltipContainer} recharts-custom-tooltip`}>
                    <p>
                        {data.label}
                    </p>
                    <div>
                        {data.payload.map((i: { color: string, name: string, value: number, payload: IAnalyticsEmissionsChartData }, index: number) => {
                            if (i.payload.skipTooltipForPredictiveEmissions === true) {
                                let skipIndex = data.payload.findIndex((value: { color: string, name: string, value: number }) => value.name === "Emissions (predicted)");
                                if (index === skipIndex) {
                                    return <></>;
                                }
                            }
                            return (
                                <div className={styles.reductionAnalytics_customToolTipContainer} key={index}>
                                    <div
                                        className={styles.reductionAnalytics_bulletContainer}
                                        style={{ backgroundColor: i.color }}
                                    />
                                    <span className={styles.reductionAnalytics_value}>
                                        {`${i.name}: ${toFixed(i.value)} tCO2e`}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    };

    const { primaryTextColor } = useContext(FoundationThemeContext);
    return (
        <>
            {fetchingAnalytics && (
                <div className={styles.reductionAnalytics_loadingDiv}>
                    <CircularProgress
                        size={30}
                        color={"inherit"}
                    />
                </div>
            )}
            {!fetchingAnalytics && analyticsData && (
                <Grid container padding={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <h3 className={styles.reductionAnalytics_title} style={{ color: primaryTextColor }}>Emission Forecast</h3>
                            <CardContent>
                                <div className={styles.reductionAnalytics_legend}>
                                    <div className={styles.reductionAnalytics_legendItem}>
                                        <div
                                            id="legendForBusinessAsUsual"
                                            className={styles.reductionAnalytics_legendColor}
                                            style={{ backgroundColor: COLOR_BUSINESS_AS_USUAL }}
                                        />
                                        <label htmlFor="legendForBusinessAsUsual">Emissions (actual)</label>
                                    </div>
                                    <div className={styles.reductionAnalytics_legendItem}>
                                        <div
                                            id="legendForpredictiveBusinessAsUsual"
                                            className={styles.reductionAnalytics_legendColor}
                                            style={{ backgroundColor: COLOR_PREDICTIVE_BUSINESS_AS_USUAL }}
                                        />
                                        <label htmlFor="legendForpredictiveBusinessAsUsual">Emissions (predicted)</label>
                                    </div>
                                    <div className={styles.reductionAnalytics_legendItem}>
                                        <div
                                            id="legendForEmissionsWithReductions"
                                            className={styles.reductionAnalytics_legendColor}
                                            style={{ backgroundColor: COLOR_EMISSION_WITH_REDUCTION }}
                                        />
                                        <label htmlFor="legendForEmissionsWithReductions">Emissions based on action plan</label>
                                    </div>
                                    <div className={styles.reductionAnalytics_legendItem}>
                                        <div
                                            id="legendForTargets"
                                            className={styles.reductionAnalytics_legendColor}
                                            style={{ backgroundColor: COLOR_TARGETS }}
                                        />
                                        <label htmlFor="legendForTargets">Targets</label>
                                    </div>
                                </div>
                                <ResponsiveContainer width="100%" minHeight={400}>
                                    <ComposedChart
                                        data={analyticsData.emissions}
                                        margin={{
                                            top: 10,
                                            left: 10,
                                            bottom: 5,
                                            right: 10
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="yearMonth" angle={-10} dy={5} />
                                        <YAxis orientation="left" label={{ value: "tCO2e", angle: -90, position: 'insideLeft' }} />
                                        <Tooltip content={CustomTooltip} wrapperStyle={{ padding: "0px" }} />
                                        <Area
                                            type="monotone"
                                            dataKey="businessAsUsualEmissions"
                                            fill={COLOR_BUSINESS_AS_USUAL}
                                            stroke={COLOR_BUSINESS_AS_USUAL}
                                            name="Emissions (actual)"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="predictiveBusinessAsUsualEmissions"
                                            fill={COLOR_PREDICTIVE_BUSINESS_AS_USUAL}
                                            stroke={COLOR_PREDICTIVE_BUSINESS_AS_USUAL}
                                            name="Emissions (predicted)"
                                        />
                                        <Line
                                            strokeWidth={2}
                                            type="monotone"
                                            dataKey="emissionsWithReductions"
                                            stroke={COLOR_EMISSION_WITH_REDUCTION}
                                            dot={{ stroke: COLOR_EMISSION_WITH_REDUCTION, strokeWidth: 2 }}
                                            name="Emissions based on action plan"
                                        />
                                        {createArrayWithNumbers(analyticsData.maxTargetsInAMonth)
                                            .map((index) =>
                                                <Scatter
                                                    key={index}
                                                    fill={COLOR_TARGETS}
                                                    dataKey={`targets[${index}]`}
                                                    shape={CustomTargetIconShape}
                                                    name="Target"
                                                />
                                            )}
                                    </ComposedChart>
                                </ResponsiveContainer>
                                <div className={styles.reductionAnalytics_chartDescription}>
                                    This chart shows your current emissions since your first target, and the predicted emissions trajectory till the last target based on your current emitting activities. Each time an action is planned, your emissions reduction trajectory will decrease.
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
