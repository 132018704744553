import { format, getYear, isAfter } from "date-fns";

export const formatStartEndDatesToMonthYear = (
    start: Date,
    end: Date,
    allowEndAfterToday: boolean = false
): [string, string] => {
    if (!allowEndAfterToday && isAfter(end, new Date())) {
        // end date is after today's date, so just set end to be today
        end = new Date();
    }

    return [format(start, "MMM-yyyy"), format(end, "MMM-yyyy")];
};

export const addMonthsToDate = (date: Date, numberOfMonths: number) => {
    date.setMonth(date.getMonth() + numberOfMonths);
    return date;
}

export const formatTimestampToMonthYear = (timestamp: number): string => {
    const date = new Date(timestamp);
    return format(date, "MMM-yyyy");
}

export const formatTimestampToDateMonthYear = (timestamp: number): string => {
    const date = new Date(timestamp);
    return format(date, "dd-MMM-yyyy");
}

export const getDateFromMonthYear = (monthYear: string): Date => {
    const date = new Date(monthYear);
    return date;
}

export const getPreviousMonthTimestamp = (timestamp: number): number => {
    const date = new Date(timestamp)
    const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1, 5, 30);
    return previousMonth.getTime();
}

export const getYearFromDate = (date: number): number => {
    return getYear(new Date(date))
}

export const getMonthsDifference = (start: Date, end: Date): number => {
    return end.getMonth() - start.getMonth() +
        (12 * (end.getFullYear() - start.getFullYear()))
}
