import { useCallback, useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { IReportView } from "../../models/Report";
import { FoundationError } from "../../models/FoundationError";
import { deleteReport } from "../../api/Company";

interface IProps {
    report: IReportView;
    companyId: string;
    onClose: (didDeleteReport: boolean) => void;
}

const DeleteReportConfirmationDialog: React.FC<IProps> = (props) => {
    const { report, companyId, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const onDeleteReport = useCallback(async () => {
        setIsLoading(true);
        const response = await deleteReport(companyId, report.id);
        setIsLoading(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("Report deleted successfully", "success");
        onClose(true);
    }, [showToast, onClose, report, companyId]);

    return (
        <GenericDialog
            isOpenModal={true}
            onClose={() => {
                onClose(false);
            }}
            title={"Delete Report"}
            btnText={"Delete"}
            btnAction={onDeleteReport}
            btnLoading={isLoading}
        >
            <div style={{
                fontSize: 20
            }}>
                <p>Are you sure you want to delete {report.name}?</p>
            </div>
        </GenericDialog>
    )
}

export default DeleteReportConfirmationDialog;
