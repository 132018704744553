import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { GenericSummaryComponent } from "../../components/companyEmissionsOverview/summary/GenericSummaryComponent";
import { CompanyDataContextV2 } from "../../contexts/CompanyDataContextV2";
import { KpiSection, KpiSectionGroup } from "../../models/Kpi";
import { getFacilitySectionSummaryData, getPieChartDataForSectionGroup } from "../../utils/FacilityEmissionUtils";
import { TableWithPieOrBarChart } from "../../components/tableWithPieOrBarChart/TableWithPieOrBarChart";
import FacilityEmissionsOverTime from "../../components/facilityEmissionsOverTime/FacilityEmissionsOverTime";

interface IProps {
    section: KpiSection;
    hidePieChart?: boolean;
    pieChartSectionGroup?: KpiSectionGroup;
}

const pieChartColors = ["#32E7A4", "#64B5FC", "#FCEA64", "#FF5733", "#33FF57", "#5733FF", "#FF33A6", "#A633FF", "#FFA633", "#33FFE0", "#E033FF", "#FFE033", "#33FF6E", "#6E33FF", "#FF6E33", "#33FFD4", "#D433FF", "#FFD433", "#33FF4B", "#4B33FF", "#FF4B33", "#33FF91", "#9133FF", "#FF9133", "#33FFBB", "#BB33FF", "#FFBB33", "#33FF23", "#2333FF", "#FF2333", "#33FFC9", "#C933FF", "#FFC933", "#33FF78", "#7833FF", "#FF7833", "#33FFFD", "#FD33FF", "#FFFD33", "#33FF0F", "#0F33FF", "#FF0F33", "#33FF47", "#4733FF", "#FF4749", "#33FFFD", "#FD33FF", "#FFFD33", "#33FF0F", "#0F33FF", "#FF0F33", "#33FF47", "#4733FF"];

const EmissionsWithSummaryAndPieChart: React.FC<IProps> = (props: IProps) => {
    const {
        section,
        pieChartSectionGroup = KpiSectionGroup.NONE,
        hidePieChart = false
    } = props;
    const {
        isLoadingCompanyEmissions,
        selectedFacilityEmissions
    } = useContext(CompanyDataContextV2);

    const [pieTab, setPieTab] = useState<"total" | "perEmployee">("total");

    const summaryData = getFacilitySectionSummaryData(selectedFacilityEmissions, section);
    const pieChartData = hidePieChart
        ? { chartDataTotal: [], chartDataPerEmployee: [], chartTitle: "" }
        : getPieChartDataForSectionGroup(selectedFacilityEmissions, section, pieChartSectionGroup);

    return (
        <Grid container spacing={2} paddingX={4} paddingBottom={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <GenericSummaryComponent
                    showDates={false}
                    isLoading={isLoadingCompanyEmissions}
                    data={summaryData}
                />
            </Grid>
            {pieChartData.chartDataTotal.length > 0 && (
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <TableWithPieOrBarChart
                        title={pieChartData.chartTitle}
                        orient={"column"}
                        tabs={["Total", "Per Employee"]}
                        defaultTab={pieTab === "total" ? "Total" : "Per Employee"}
                        isStriped
                        showLegendColors
                        showActionButton
                        showInfoIcon
                        isLoading={isLoadingCompanyEmissions}
                        showHeadingRow
                        headers={["", "tCO2e"].map((header) => { return { header } })}
                        data={pieTab === "total" ? pieChartData.chartDataTotal : pieChartData.chartDataPerEmployee}
                        dataRenderer={(data, column) => {
                            switch (column) {
                                case "":
                                    return data.key;
                                case "tCO2e":
                                    return data.value;
                            }
                        }}
                        maxHeight={"300px"}
                        chartType={"pie"}
                        colors={pieChartColors}
                        pieChartProps={{
                            data: pieTab === "total" ? pieChartData.chartDataTotal : pieChartData.chartDataPerEmployee,
                            unit: "tCO2e",
                            dataKey: "value",
                            toolTipLabelFormatter: (value, _, percent) => `${value} tCO2e (${percent}%)`,
                            minWidth: 330,
                            minHeight: 330,
                            legendLabelKey: "key",
                            toolTipLabelKey: "key",
                        }}
                        onTabChange={(category) => {
                            switch (category) {
                                case 'Total':
                                    setPieTab("total");
                                    return pieChartData.chartDataTotal;
                                case 'Per Employee':
                                    setPieTab("perEmployee");
                                    return pieChartData.chartDataPerEmployee;
                                default:
                                    return [];
                            }
                        }}
                    />
                </Grid>
            )}

            <Grid item lg={8} md={8} sm={12} xs={12}>
                <FacilityEmissionsOverTime
                    isLoadingCompanyEmissions={isLoadingCompanyEmissions}
                    selectedFacilityEmissions={selectedFacilityEmissions}
                    section={section}
                />
            </Grid>
        </Grid>
    );
}

export default EmissionsWithSummaryAndPieChart;
