import { CircularProgress, Grid, MenuItem, Select } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { getSocialData } from "../../api/Company";
import GenericBarChartWithTitle from "../../components/genericBarChart/GenericBarChartWithTitle";
import GenericChartsWithTitle from "../../components/genericChartsWithTitle/GenericChartsWithTitle";
import { TableWithPieOrBarChart } from "../../components/tableWithPieOrBarChart/TableWithPieOrBarChart";
import { FoundationError } from "../../models/FoundationError";
import { IConsumerComplaintsView, IMinimumWageView, ISocialView } from "../../models/Social";
import { Auth } from "../../utils/Auth";
import styles from "./SocialDashboard.module.css";
import { ToastContext } from "../../contexts/ToastContext";

interface IMinimumWageBarChartData {
    gender: string;
    numberEarningMinimumWage: number;
    numberEarningAboveMinimumWage: number;
}

interface IConsumerComplaintsBarChartData {
    issue: string;
    receivedCount: number;
    pendingResolutionCount: number;
}

function barChartDataForMinimumWage(minimumWageView?: IMinimumWageView): IMinimumWageBarChartData[] {
    const chartData: IMinimumWageBarChartData[] = [];
    if (minimumWageView === undefined) {
        return chartData;
    }
    chartData.push({
        gender: "Male",
        numberEarningMinimumWage: minimumWageView.noOfMalesEarningMinimumWage,
        numberEarningAboveMinimumWage: minimumWageView.noOfMalesEarningAboveMinimumWage
    })

    chartData.push({
        gender: "Female",
        numberEarningMinimumWage: minimumWageView.noOfFemalesEarningMinimumWage,
        numberEarningAboveMinimumWage: minimumWageView.noOfFemalesEarningAboveMinimumWage
    })
    return chartData;
}

function barChartDataForConsumerComplaints(consumerComplaintsView?: IConsumerComplaintsView[]): IConsumerComplaintsBarChartData[] {
    const chartData: IConsumerComplaintsBarChartData[] = [];
    if (consumerComplaintsView === undefined) {
        return chartData;
    }
    consumerComplaintsView.forEach((element) => {
        chartData.push({
            issue: element.issue,
            receivedCount: element.receivedCount,
            pendingResolutionCount: element.pendingResolutionCount
        })
    })
    return chartData;
}

const SocialDashboard: React.FC = () => {
    const [socialData, setSocialData] = useState<ISocialView | null>();
    const [selectedYear, setSelectedYear] = useState<number>();
    const [reportYears, setReportYears] = useState<number[]>();
    const [yearlySocialData, setYearlySocialData] = useState<ISocialView[]>();
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const fetchSocialDashboardData = useCallback(async (companyId: string) => {
        setIsLoading(true);
        const response = await getSocialData(companyId);
        setIsLoading(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setYearlySocialData(response.socialViews);
    }, [showToast]);

    const changeSelectedYear = async (year: number) => {
        setSelectedYear(year);
        setSocialData(yearlySocialData && yearlySocialData?.find(data => data.year === year));
    }

    useEffect(() => {
        if (!yearlySocialData) {
            return;
        }
        const allYears = yearlySocialData && yearlySocialData.map(data => data.year);
        setReportYears(allYears);
        setSocialData(yearlySocialData[0]);
        setSelectedYear(allYears[0])
    }, [yearlySocialData]);

    useEffect(() => {
        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings) {
            fetchSocialDashboardData(userSettings.parentCompanyId);
        }
    }, [fetchSocialDashboardData]);

    const genderDistributionPieChartData = [
        { key: "Male", value: socialData ? socialData.genderDistribution.noOfMales : 0 },
        { key: "Female", value: socialData ? socialData.genderDistribution.noOfFemales : 0 },
    ];

    const ageDistributionPieChartData = [
        { key: "18-34", value: socialData ? socialData.ageDistribution.age18To34 : 0 },
        { key: "35-44", value: socialData ? socialData.ageDistribution.age35To44 : 0 },
        { key: "45-54", value: socialData ? socialData.ageDistribution.age45To54 : 0 },
        { key: "Above 54", value: socialData ? socialData.ageDistribution.ageAbove54 : 0 },
    ]

    const peopleWellBeingMeasuresBarChartData = [
        { key: "Health Insurance", value: socialData ? socialData.peopleWellBeingMeasures.healthInsurance : 0 },
        { key: "Accident Insurance", value: socialData ? socialData.peopleWellBeingMeasures.accidentInsurance : 0 },
        { key: "Maternity Benefits", value: socialData ? socialData.peopleWellBeingMeasures.maternityBenefits : 0 },
        { key: "Paternity Benefits", value: socialData ? socialData.peopleWellBeingMeasures.paternityBenefits : 0 },
        { key: "Day Care Facilities", value: socialData ? socialData.peopleWellBeingMeasures.dayCareFacilities : 0 },
    ]

    const accidentBarChartData = [
        { key: "Lost Time Injury Frequency Rate", value: socialData ? socialData.accidents?.currentFinancialYear.lostTimeInjuryFrequencyRate : 0 },
        { key: "Recordable Work Related Injuries", value: socialData ? socialData.accidents?.currentFinancialYear.recordableWorkRelatedInjuries : 0 },
        { key: "High Consequence Work Related Injuries", value: socialData ? socialData.accidents?.currentFinancialYear.highConsequenceWorkRelatedInjuries : 0 },
        { key: "Fatalities", value: socialData ? socialData.accidents?.currentFinancialYear.noOfFatalities : 0 },
    ]

    const differentlyAbledDistributionPieChartData = [
        { key: "Male", value: socialData ? socialData.differentlyAbledDistribution.noOfMales : 0 },
        { key: "Female", value: socialData ? socialData.differentlyAbledDistribution.noOfFemales : 0 },
    ];

    const complaintsBarChartData = [
        { key: "Received Count", value: socialData ? socialData.complaintsView.receivedCount : 0 },
        { key: "Resolved Count", value: socialData ? socialData.complaintsView.resolvedCount : 0 },
    ];

    const trainingBarChartData = [
        { key: "Male", value: socialData ? socialData.trainingView.noOfMales : 0 },
        { key: "Female", value: socialData ? socialData.trainingView.noOfFemales : 0 },
    ];

    const trainingHoursBarChartData = [
        { key: "Permanent Employee", value: socialData ? socialData.trainingHoursView.numberOfVoluntaryTrainingHoursPermanentEmployee : 0 },
        { key: "Non Permanent Employee", value: socialData ? socialData.trainingHoursView.numberOfVoluntaryTrainingHoursNonPermanentEmployee : 0 },
        { key: "Permanent Worker", value: socialData ? socialData.trainingHoursView.numberOfVoluntaryTrainingHoursPermanentWorker : 0 },
        { key: "Non Permanent Worker", value: socialData ? socialData.trainingHoursView.numberOfVoluntaryTrainingHoursNonPermanentWorker : 0 },
    ];

    const minimumWageBarChartData = barChartDataForMinimumWage(socialData?.minimumWageView)
    const consumerComplaintsBarChartData = barChartDataForConsumerComplaints(socialData?.consumerComplaintsView)

    const weeklyWorkingHoursBarChart = [
        { key: "Permanent Employee", value: socialData ? socialData.weeklyWorkingHoursView.numberOfWeeklyWorkingHoursPermanentEmployee : 0 },
        { key: "Non Permanent Employee", value: socialData ? socialData.weeklyWorkingHoursView.numberOfWeeklyWorkingHoursNonPermanentEmployee : 0 },
        { key: "Permanent Worker", value: socialData ? socialData.weeklyWorkingHoursView.numberOfWeeklyWorkingHoursPermanentWorker : 0 },
        { key: "Non Permanent Worker", value: socialData ? socialData.weeklyWorkingHoursView.numberOfWeeklyWorkingHoursNonPermanentWorker : 0 },
    ];

    const leavesPieChartData = [
        { key: "0-6", value: socialData ? socialData.leavesView.numberOfLeaves0To6 : 0 },
        { key: "7-12", value: socialData ? socialData.leavesView.numberOfLeaves7To12 : 0 },
        { key: "13-18", value: socialData ? socialData.leavesView.numberOfLeaves13To18 : 0 },
        { key: "19-24", value: socialData ? socialData.leavesView.numberOfLeaves19To24 : 0 },
        { key: "25-30", value: socialData ? socialData.leavesView.numberOfLeaves25To30 : 0 },
    ]

    return (
        <>
            {isLoading && (
                <div className={styles.socialDashboard__loadingDiv}>
                    <CircularProgress
                        size={30}
                        color={"inherit"}
                    />
                </div>
            )}

            {!isLoading && !socialData && (
                <div className={styles.socialDashboard__loadingDiv}>
                    No ESG Report submitted
                </div>
            )}

            {!isLoading && socialData && (
                <>
                    <div className={styles.socialDashboard__YearContainer}>
                        <div className={styles.socialDashboard__YearText}>Data based on ESG Report for </div>
                        <Select
                            id="selectedYear"
                            value={selectedYear}
                            onChange={(event) => {
                                const value = event.target.value;
                                changeSelectedYear(Number(value));
                            }}
                        >
                            {reportYears && reportYears.map(year => {
                                return (
                                    <MenuItem
                                        key={year}
                                        value={year}
                                    >
                                        {year}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <TableWithPieOrBarChart
                                    showTable={false}
                                    title={"Gender distribution across all the offices / plants / operations"}
                                    headers={[]}
                                    data={[]}
                                    dataRenderer={() => ""}
                                    chartType={"pie"}
                                    pieChartProps={{
                                        data: genderDistributionPieChartData,
                                        dataKey: "value",
                                        minWidth: 200,
                                        minHeight: 300,
                                        showLegend: true,
                                        legendLabelKey: "key",
                                        toolTipLabelKey: "key",
                                        unit: "total",
                                        toolTipLabelFormatter: (_, index) => `${genderDistributionPieChartData[index].key}: ${genderDistributionPieChartData[index].value}`
                                    }} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <TableWithPieOrBarChart
                                    showTable={false}
                                    title={"Age distribution across all the offices / plants / operations"}
                                    headers={[]}
                                    data={[]}
                                    dataRenderer={() => ""}
                                    chartType={"pie"}
                                    pieChartProps={{
                                        data: ageDistributionPieChartData,
                                        dataKey: "value",
                                        minWidth: 200,
                                        minHeight: 300,
                                        showLegend: true,
                                        legendLabelKey: "key",
                                        toolTipLabelKey: "key",
                                        unit: "total",
                                        colors: ['#5B3A96', '#BB308A', '#F64663', '#FFA600'],
                                        toolTipLabelFormatter: (_, index) => `${ageDistributionPieChartData[index].key}: ${ageDistributionPieChartData[index].value}`
                                    }} />
                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <TableWithPieOrBarChart
                                    showTable={false}
                                    title={"Differently abled people working across all the offices / plants / operations"}
                                    headers={[]}
                                    data={[]}
                                    dataRenderer={() => ""}
                                    chartType={"pie"}
                                    pieChartProps={{
                                        data: differentlyAbledDistributionPieChartData,
                                        dataKey: "value",
                                        minWidth: 200,
                                        minHeight: 300,
                                        showLegend: true,
                                        legendLabelKey: "key",
                                        toolTipLabelKey: "key",
                                        unit: "total",
                                        colors: ['#FFA600', '#F07111'],
                                        toolTipLabelFormatter: (_, index) => `${differentlyAbledDistributionPieChartData[index].key}: ${differentlyAbledDistributionPieChartData[index].value}`
                                    }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <GenericBarChartWithTitle
                                    title={"Measures for the well-being of Employees & Workers"}
                                    data={peopleWellBeingMeasuresBarChartData}
                                    barLabelKey={"key"}
                                    dataKeys={["value"]}
                                    colors={['#155EA2']}
                                    aspect={1}
                                    angle={-5}
                                    maxHeight={400}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <GenericBarChartWithTitle
                                    title={"Number of Employees given training"}
                                    data={trainingBarChartData}
                                    barLabelKey={"key"}
                                    dataKeys={["value"]}
                                    colors={['#64B5FC']}
                                    aspect={1}
                                    maxHeight={400}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <GenericBarChartWithTitle
                                    title={"Incidents/ Accidents"}
                                    data={accidentBarChartData}
                                    barLabelKey={"key"}
                                    dataKeys={["value"]}
                                    colors={["#F07111"]}
                                    aspect={1}
                                    maxHeight={400}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <GenericBarChartWithTitle
                                    title={"Measures to monitor company's investment in Human Capital Development"}
                                    data={trainingHoursBarChartData}
                                    barLabelKey={"key"}
                                    dataKeys={["value"]}
                                    colors={['#FCEA64']}
                                    aspect={1}
                                    angle={-6}
                                    maxHeight={400}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <GenericBarChartWithTitle
                                    title={"Number of Complaints received by employees/workers and resolved"}
                                    data={complaintsBarChartData}
                                    barLabelKey={"key"}
                                    dataKeys={["value"]}
                                    colors={['#8D6BEB']}
                                    aspect={1}
                                    maxHeight={400}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item lg={5} md={6} sm={12} xs={12}>
                                <GenericChartsWithTitle
                                    title={"Minimum wages paid to employees and workers"}
                                    chartType={'bar'}
                                    colorLegendHeadings={["Earning Minimum Wage", "Earning Above Minimum Wage"]}
                                    colors={["#32E7A4", "#04B1B4"]}
                                    barChartProps={{
                                        stack: true,
                                        multipleColorBars: true,
                                        data: minimumWageBarChartData,
                                        dataKeys: ["numberEarningMinimumWage", "numberEarningAboveMinimumWage"],
                                        barLabelKey: "gender",
                                        xAxisHeight: 40,
                                        xAxisFontSize: 10,
                                        maxHeight: 400
                                    }}
                                />
                            </Grid>

                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                <GenericChartsWithTitle
                                    title={"Consumer Complaints"}
                                    chartType={'bar'}
                                    colors={['#64B5FC', '#155EA2',]}
                                    allowZero
                                    colorLegendHeadings={["Complaints Received", "Complaints Pending Resolution"]}
                                    barChartProps={{
                                        stack: true,
                                        multipleColorBars: true,
                                        data: consumerComplaintsBarChartData,
                                        dataKeys: ["receivedCount", "pendingResolutionCount"],
                                        barLabelKey: "issue",
                                        xAxisHeight: 40,
                                        xAxisFontSize: 10,
                                        maxHeight: 400,
                                        angle: -12
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <GenericBarChartWithTitle
                                    title={"Average number of weekly working hours"}
                                    data={weeklyWorkingHoursBarChart}
                                    barLabelKey={"key"}
                                    dataKeys={["value"]}
                                    colors={['#04B1B4']}
                                    aspect={1}
                                    angle={-6}
                                    maxHeight={400}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <TableWithPieOrBarChart
                                    showTable={false}
                                    title={"Leaves taken out of total paid leaves"}
                                    headers={[]}
                                    data={[]}
                                    allowZero
                                    dataRenderer={() => ""}
                                    chartType={"pie"}
                                    pieChartProps={{
                                        data: leavesPieChartData,
                                        dataKey: "value",
                                        minWidth: 200,
                                        minHeight: 300,
                                        showLegend: true,
                                        legendLabelKey: "key",
                                        toolTipLabelKey: "key",
                                        unit: "total",
                                        toolTipLabelFormatter: (_, index) => `${leavesPieChartData[index].key}: ${leavesPieChartData[index].value}`
                                    }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

export default SocialDashboard;
