import { Button, InputAdornment, styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { QuestionType } from "../../models/Report";
import styles from "./DataCollectionInput.module.css";
import { IDataCollectionQuestionWithAnswerV2 } from "../../models/DataCollection";

interface IProps {
    selectedQuestion: IDataCollectionQuestionWithAnswerV2;
    onSave: (input: number | undefined, isLastQuestion: boolean) => void;
    isLastQuestion: boolean;
}

export const DataCollectionInputV2: React.FC<IProps> = (props) => {

    const { selectedQuestion, onSave, isLastQuestion } = props;

    const [input, setInput] = useState<number>();
    const type = selectedQuestion.questionType;

    useEffect(() => {
        setInput(selectedQuestion.input);
    }, [selectedQuestion])

    const StyledTextField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            paddingRight: 0,
        },
        '& .MuiInputAdornment-root': {
            border: `1px solid ${theme.palette.divider}`,
            padding: '28px 50px',
        },
    }));

    return (
        <>
            <div className={styles.dataCollectionInputContainer}>
                <div className={styles.dataCollectionInputs}>
                    <h4>{selectedQuestion.title}</h4>

                    {(type === QuestionType.NUMBER_DECIMAL || type === QuestionType.NUMBER_WHOLE) && (
                        <StyledTextField
                            style={{ width: "500px" }}
                            type={"number"}
                            placeholder="Enter the reading value"
                            label="Value*"
                            autoFocus
                            value={input ?? ""}
                            onChange={(e) => {
                                if (e.target.value?.length === 0) {
                                    setInput(undefined);
                                    return;
                                }

                                if (type === QuestionType.NUMBER_WHOLE) {
                                    setInput(parseInt(e.target.value));
                                } else {
                                    setInput(parseFloat(e.target.value));
                                }
                            }}
                            InputProps={{
                                endAdornment: selectedQuestion.unit ? (
                                    <InputAdornment position="end">
                                        {selectedQuestion.unit}
                                    </InputAdornment>
                                ) : undefined,
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    )}

                    <h4>{"Instructions"}</h4>
                    {selectedQuestion.instructions?.map((instruction, index) => <p key={index} style={{ whiteSpace: "pre-line", marginLeft: "30px", width: "60%", fontSize: "small" }}>{instruction}</p>)}
                </div>
                <div className={styles.dataCollectionActionButtons}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setInput(undefined);
                            onSave(undefined, isLastQuestion);
                        }}
                    >
                        Not applicable for this month
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onSave(input, isLastQuestion);
                        }}
                        style={{ marginLeft: "30px" }}
                    >
                        {isLastQuestion ? "Submit" : "Save"}
                    </Button>
                </div>
            </div>
        </>
    );
}
