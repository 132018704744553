import WarningIcon from '@mui/icons-material/Warning';

interface IErrorProps {
    height?: number;
    title?: string;
    subTitle?: string
}

export const ErrorComponent = (props: IErrorProps) => {

    const { height = 300, title, subTitle } = props;

    return (
        <div style={{ color: "#CECECE", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: height }} >
            <WarningIcon fontSize={"large"}/>
            <h3 style={{padding: 0, margin: 0, marginTop: "10px"}}>{title ? title : "No data found"}</h3>
            <h4 style={{width: "60%"}}>{subTitle ? subTitle : "We tried to generate the report but it seems that something went wrong with the data"}</h4>
        </div>
    );
} 
