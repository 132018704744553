import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { createUser, updateUser } from "../../api/Users";
import { FacilitiesDataContext } from "../../contexts/FacilityDataContext";
import { FoundationError } from "../../models/FoundationError";
import { Role, RoleList } from "../../models/Role";
import { isRoleAdmin } from "../../models/User";
import { Config } from "../../utils/Config";
import GenericDialog from "../genericDialog/GenericDialog";
import styles from "./AddOrModifyUserDialog.module.css";
import { ToastContext } from "../../contexts/ToastContext";

export interface IUserForm {
  id?: string,
  name: string;
  email: string;
  role: string;
  phoneNumber: string;
  designationInCompany: string;
  facilityIdsAssigned?: string[] | null;
}

export const AddOrModifyUserDialog: React.FC<{
  selectedUser: IUserForm | null;
  onClose: (success?: boolean, message?: string) => void;
  open: boolean
}> = (props) => {

  const supportedCountries = Config.getInstance().getSupportedCountries();
  const [countryPrefix, setCountryPrefix] = useState(
    supportedCountries[0].prefix
  );

  const { selectedUser, onClose, open } = props;
  const [role, setRole] = useState(selectedUser?.role ?? undefined);
  const { register, handleSubmit, formState: { errors } } =
    useForm<IUserForm>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);
  const editMode = selectedUser !== null;

  const title = editMode ? "Update User" : "Add User";
  const buttonText = editMode ? "Update" : "Add";

  const { facilities } = useContext(FacilitiesDataContext);

  const { showToast } = useContext(ToastContext);

  const isValidEmail = (email?: string) => {
    if (email && email.length > 0) {
      // if email is provided, no further validation required
      // FIXME: do some basic email validation
      return true;
    }

    return false;
  };

  const onSubmit = async (userInfo: IUserForm) => {
    userInfo.phoneNumber = countryPrefix.concat(userInfo.phoneNumber);

    setSubmitting(true);
    let hasErrors = false;
    let errorMessage = "";

    if (userInfo.facilityIdsAssigned?.length === 0) {
      userInfo.facilityIdsAssigned = null;
    }

    if (editMode) {
      userInfo.id = selectedUser?.id;

      const error = await updateUser(userInfo)
      setSubmitting(false);
      if (error instanceof FoundationError) {
        hasErrors = true;
        errorMessage = error.message;
      }
    } else {
      if (userInfo.role === Role.ADMIN || userInfo.role === Role.SUPER_ADMIN) {
        userInfo.facilityIdsAssigned = null;
      }
      const error = await createUser(userInfo);
      setSubmitting(false);
      if (error instanceof FoundationError) {
        hasErrors = true;
        errorMessage = error.getErrorMessage();
      }
    }

    if (hasErrors) {
      showToast(errorMessage, "error");
      return;
    }

    const successMessage = `User has been ${editMode ? "updated" : "created"} successfully`;
    onClose(true, successMessage)
  };

  const removeCountryPrefix = (mobileNumber?: string) => {
    return mobileNumber ? mobileNumber.replace(countryPrefix, "") : "";
  };

  return (
    <>
      <GenericDialog
        isOpenModal={open}
        title={title}
        btnText={buttonText}
        btnAction={handleSubmit(onSubmit)}
        maxWidth={"sm"}
        btnLoading={isSubmitting}
        onClose={() => { onClose() }}
      >
        <div className={styles.modalBody}>
          <TextField
            type={"text"}
            label="Name*"
            error={errors.name !== undefined}
            helperText={
              errors.name ? "Name required" : ""
            }
            defaultValue={selectedUser?.name ?? ""}
            fullWidth
            margin="normal"
            variant="outlined"
            {...register("name", { required: true })}
          />

          <TextField
            type={"text"}
            label="Email*"
            error={errors.email !== undefined}
            helperText={
              errors.email ? "Email required" : ""
            }
            defaultValue={selectedUser?.email ?? ""}
            fullWidth
            margin="normal"
            variant="outlined"
            {...register("email", { required: true, validate: isValidEmail })}
          />

          <div style={{ display: "flex", alignItems: "flex-start", width: "500px" }}>
            <Select
              native
              value={countryPrefix}
              disabled={editMode && true}
              onChange={(e) => {
                setCountryPrefix(e.target.value as string);
              }}
              style={{
                width: "100px",
                height: "56px",
                marginRight: "10px",
              }}
            >
              {supportedCountries.map((country, index) => {
                return (
                  <option value={country.prefix} key={index}>
                    {country.prefix}
                  </option>
                );
              })}
            </Select>

            <TextField
              className={styles.label}
              id="phone-number"
              type={"number"}
              label="Contact Number"
              helperText={errors.phoneNumber ? "Contact Number required" : ""}
              error={errors.phoneNumber !== undefined}
              {...register("phoneNumber", {
                required: true,
                validate: (number: string) => {
                  number = countryPrefix.concat(number);
                  return isValidPhoneNumber(number);
                },
              })}
              fullWidth
              defaultValue={removeCountryPrefix(selectedUser?.phoneNumber)}
            />

          </div>

          <FormControl fullWidth style={{ marginTop: "20px" }}>
            <InputLabel id="role-select-label">Role*</InputLabel>
            {/* TODO: show display name of role */}
            <Select
              labelId="role-select-label"
              id="role-select"
              label="Role*"
              value={role}
              error={errors.role !== undefined}
              defaultValue={selectedUser ? selectedUser.role : ""}
              {...register("role", { required: true })}
              onChange={(event) => {
                const value = event.target.value as string;
                setRole(value)
              }}
            >
              {RoleList.filter(role => role !== Role.SUPER_ADMIN)
                .map(role => {
                  return (<MenuItem value={role} key={role}>{role}</MenuItem>)
                })}
            </Select>
            <FormHelperText error id="role-error">
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: "20px" }} disabled={isRoleAdmin(role as Role)}>
            <InputLabel id="facility-select-label">Facilities</InputLabel>
            <Select
              labelId="facility-select-label"
              multiple={true}
              id="facility-select"
              label="Facilities"
              defaultValue={null}
              value={selectedFacilities}
              renderValue={(selected) => {
                const selectedFacilitesText = facilities.filter((facility) => {
                  return selected ? (selected.indexOf(facility.id) > -1) : false;
                }).map((facility) => {
                  return facility.name
                }).join(", ")
                return (
                  <div>{selectedFacilitesText}</div>
                )
              }}
              {...register("facilityIdsAssigned")}
              onChange={(event) => {
                const value = event.target.value as string[];
                setSelectedFacilities(value)
              }}
            >
              {facilities.map(facility => {
                return (
                  <MenuItem value={facility.id} key={facility.id}>
                    <Checkbox checked={selectedFacilities.indexOf(facility.id) > -1} />
                    <ListItemText primary={facility.name} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <TextField
            type={"text"}
            label="Designation*"
            error={errors.designationInCompany !== undefined}
            helperText={
              errors.designationInCompany ? "Designation required" : ""
            }
            defaultValue={selectedUser?.designationInCompany ?? ""}
            fullWidth
            margin="normal"
            variant="outlined"
            {...register("designationInCompany", { required: true })}
          />
        </div>
      </GenericDialog>
    </>
  );
}
