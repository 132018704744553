import { IDataCollectionSectionV2, IDataCollectionSidebarData } from "../models/DataCollection";
import { IFacility } from "../models/Facility";
import { FoundationError } from "../models/FoundationError";
import { NetZeroKpiType } from "../models/Kpi";
import { BACKEND_URL_V1, BACKEND_URL_V2, deletee, get, post } from "../utils/Api";
import { formatStartEndDatesToMonthYear, formatTimestampToMonthYear } from "../utils/DateUtils";
import { downloadFile } from "../utils/FileUtils";


interface IFacilityCreateResponse {
  id: string;
}

interface IFacilityDataCollectionViewResponse {
  dataCollectionView: IDataCollectionSidebarData;
}

interface IFacilityDataCollectionViewResponseV2 {
  sections: IDataCollectionSectionV2[];
}

export interface IFacilityCreateRequest {
  facilityName: string;
  spaceInMeterSquare: number;
  numberOfEmployees: number;
  street: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  supervisorsIds: string[]
}

interface IGetFacilitiesResponse {
  facilities: IFacility[]
}

export const getFacilityUploadTemplate = async (
  facilityId: string,
  startDate: Date,
  endDate: Date,
): Promise<FoundationError | null> => {
  try {
    const [start, end] = formatStartEndDatesToMonthYear(startDate, endDate);
    const response = await get(
      `${BACKEND_URL_V1}/facilities/${facilityId}/uploadReportFormat?startDate=${start}&endDate=${end}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Facility_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Facility template could not be downloaded";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const uploadFacilityData = async (
  files: File[],
  facilityId: string,
  startDate: Date,
  endDate: Date
): Promise<FoundationError | null> => {
  try {
    const [start, end] = formatStartEndDatesToMonthYear(startDate, endDate);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadedFile", files[i]);
    }
    const response = await post(
      `${BACKEND_URL_V1}/facilities/${facilityId}/uploadReport?startDate=${start}&endDate=${end}`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Unable to upload Facility Data";

    return new FoundationError(errorMessage);
  } catch (err) {
    return new FoundationError("Something went wrong");
  }
};

export const getFacilityUploadTemplateV2 = async (
  facilityId: string,
  startDate: Date,
  endDate: Date,
): Promise<FoundationError | null> => {
  try {
    const [start, end] = formatStartEndDatesToMonthYear(startDate, endDate);
    const response = await get(
      `${BACKEND_URL_V2}/facilities/${facilityId}/dataUploadTemplate?startDate=${start}&endDate=${end}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Facility_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Facility template could not be downloaded";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const uploadFacilityDataV2 = async (
  files: File[],
  facilityId: string,
  startDate: Date,
  endDate: Date
): Promise<FoundationError | null> => {
  try {
    const [start, end] = formatStartEndDatesToMonthYear(startDate, endDate);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadedFile", files[i]);
    }
    const response = await post(
      `${BACKEND_URL_V2}/facilities/${facilityId}/dataUpload?startDate=${start}&endDate=${end}`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Unable to upload Facility Data";

    return new FoundationError(errorMessage);
  } catch (err) {
    return new FoundationError("Something went wrong");
  }
};

export const createFacility = async (
  facilityCreateRequest: IFacilityCreateRequest
): Promise<IFacilityCreateResponse | FoundationError> => {

  try {
    const response = await post<IFacilityCreateResponse>(
      `${BACKEND_URL_V1}/facilities`,
      facilityCreateRequest
    );

    if (response.status / 100 === 2 && response.parsedBody !== undefined) {
      return response.parsedBody;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to create facility";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const getAllFacilities = async (): Promise<IFacility[] | FoundationError> => {
  try {
    const response = await get<IGetFacilitiesResponse>(`${BACKEND_URL_V1}/facilities/`);

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.facilities) {
        return response.parsedBody.facilities;
      }
      return [];
    }
    const errorMessage = response.serverError?.error.message ?? "Failed to fetch facilities";
    return new FoundationError(errorMessage);
  }
  catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const updateFacility = async (facilityId: string,
  facilityUpdateRequest: IFacilityCreateRequest): Promise<null | FoundationError> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/facilities/${facilityId}`,
      facilityUpdateRequest
    );

    if (response.status / 100 === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to update Facility";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
}

export const getFacilityDataCollectionView = async (facilityId: string, date: Date): Promise<IDataCollectionSidebarData | FoundationError> => {
  try {
    const yearMonth = formatTimestampToMonthYear(date.getTime());
    const response = await get<IFacilityDataCollectionViewResponse>(`${BACKEND_URL_V1}/facilities/${facilityId}/dataCollectionQuestions?date=${yearMonth}`);

    if (Math.floor(response.status / 100) === 2 && response.parsedBody !== undefined) {
      return response.parsedBody.dataCollectionView;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to fetch data collection view";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const getFacilityDataCollectionQuestions = async (facilityId: string, date: Date): Promise<IDataCollectionSectionV2[] | FoundationError> => {
  try {
    const yearMonth = formatTimestampToMonthYear(date.getTime());
    const response = await get<IFacilityDataCollectionViewResponseV2>(`${BACKEND_URL_V2}/facilities/${facilityId}/dataCollectionQuestions?date=${yearMonth}`);

    if (Math.floor(response.status / 100) === 2 && response.parsedBody !== undefined) {
      return response.parsedBody.sections;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to fetch data collection view";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const postDataCollectionAnswerV2 = async (facilityId: string, date: Date, questionKpiType: NetZeroKpiType, answer: number | null | undefined): Promise<null | FoundationError> => {
  try {
    const yearMonth = formatTimestampToMonthYear(date.getTime());
    const response = await post(
      `${BACKEND_URL_V2}/facilities/${facilityId}/dataCollectionQuestion?date=${yearMonth}`,
      { kpiType: questionKpiType, answer }
    );

    if (response.status / 100 === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to update Facility data";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
}

export const postDataCollectionAnswer = async (facilityId: string, date: Date, questionId: string, answer: string | null | undefined): Promise<null | FoundationError> => {
  try {
    const yearMonth = formatTimestampToMonthYear(date.getTime());
    const response = await post(
      `${BACKEND_URL_V1}/facilities/${facilityId}/dataCollectionQuestion?date=${yearMonth}`,
      { questionId, answer }
    );

    if (response.status / 100 === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to update Facility data";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
}

export const deleteFacility = async (facilityId: string): Promise<null | FoundationError> => {
  try {
    const response = await deletee(`${BACKEND_URL_V1}/facilities/${facilityId}`);

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to delete Facility";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
}
