import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";
import { ErrorComponent } from "../errorComponent/ErrorComponent";
import style from "./GenericBarChart.module.css";

export interface IGenericBarChartProps {
    data: any[];
    barLabelKey: string;
    dataKeys: string[];
    labelFormatter?: (data: any, key: string) => string;
    colors?: string[];
    highlightBarIndex?: number;
    stack?: boolean;
    barSize?: number;
    layout?: "horizontal" | "vertical"
    barCategoryGap?: number;
    aspect?: number;
    maxHeight?: number;
    xAxisHeight?: number,
    yAxisWidth?: number,
    xAxisLabel?: string;
    yAxisLabel?: string;
    xAxisLabelMargin?: number;
    yAxisLabelMargin?: number;
    angle?: number;
    multipleColorBars?: boolean;
    yAxisFontSize?: number;
    xAxisFontSize?: number;
    getXAxisLabelColor?: (index: number) => string;
}

function GenericBarChart(props: IGenericBarChartProps) {
    const {
        data,
        barLabelKey,
        dataKeys,
        labelFormatter = (data, key) => data[key],
        colors,
        highlightBarIndex,
        stack = false,
        barSize = 40,
        layout = "horizontal",
        barCategoryGap,
        aspect = 3,
        maxHeight = 200,
        xAxisHeight,
        yAxisWidth,
        xAxisLabel,
        yAxisLabel,
        xAxisLabelMargin = 10,
        yAxisLabelMargin = -10,
        angle = 0,
        multipleColorBars = false,
        yAxisFontSize = 12,
        xAxisFontSize = 12,
        getXAxisLabelColor
    } = props;

    const barColors = colors ?? [
        '#64B5FC',
        '#5B3A96',
        '#CE1D26',
        '#FFA600',
        '#7FC91B',
    ];

    const CustomTooltip = (data: any) => {
        if (data.active && data.payload) {
            return (
                <div className={`${style.genericBarChart_tooltipContainer} recharts-custom-tooltip`}>
                    <p>
                        {data.label}
                    </p>
                    <div>
                        {data.payload.map((i: any, index: number) => {
                            const keyIndex = dataKeys.findIndex((dataKey) => dataKey === i.dataKey);
                            return (
                                <div className={style.genericBarChart_customToolTipContainer} key={index}>
                                    <div
                                        className={style.genericBarChart_bulletContainer}
                                        style={{ backgroundColor: barColors[keyIndex] }}
                                    />
                                    <span className={style.genericBarChart_value}>
                                        {labelFormatter(i.payload, i.dataKey)}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className={style.genericBarChart_lineChartContainer}>
            <ResponsiveContainer aspect={aspect} maxHeight={maxHeight}>
                {data.length === 0 ? (
                    <ErrorComponent />
                ) : (
                    <BarChart
                        data={data}
                        margin={{ top: 5, left: 0, bottom: 5, right: 4 }}
                        layout={layout}
                        barCategoryGap={barCategoryGap}
                    >
                        <CartesianGrid stroke="#EEEEEE" />
                        <XAxis
                            type={layout === "vertical" ? "number" : "category"}
                            angle={angle}
                            interval={0}
                            textAnchor="end"
                            dataKey={layout === "vertical" ? undefined : barLabelKey}
                            height={xAxisHeight}
                            label={xAxisLabel && { value: xAxisLabel, angle: 0, position: 'middle', dy: xAxisLabelMargin, fill: "#878787" }}
                            tick={(e) => {
                                const { payload: { value, index } } = e;
                                const color = getXAxisLabelColor ? getXAxisLabelColor(index) : undefined;

                                e["fill"] = color;
                                return <Text {...e} >{value}</Text>;
                            }}
                            fontFamily="inherit"
                            fontSize={xAxisFontSize}
                        />
                        <YAxis
                            type={layout === "vertical" ? "category" : "number"}
                            dataKey={layout === "vertical" ? barLabelKey : undefined}
                            width={yAxisWidth}
                            label={yAxisLabel && { value: yAxisLabel, angle: -90, position: 'middle', dx: yAxisLabelMargin, fill: "#878787" }}
                            fontFamily="inherit"
                            fontSize={yAxisFontSize}
                        />
                        <Tooltip content={CustomTooltip} wrapperStyle={{ padding: "0px" }} />

                        {dataKeys.map((dataKey, index) => {
                            return (
                                <Bar dataKey={dataKey} stackId={stack ? 1 : undefined} barSize={barSize} key={index}>
                                    {data.map((_, subInd) => {
                                        let strokeColor = barColors[index];
                                        if (multipleColorBars || highlightBarIndex === index) {
                                            strokeColor = barColors[((stack) ? index : subInd) % barColors.length];
                                        }
                                        return <Cell key={`cell-${subInd}${index}`} fill={strokeColor} strokeWidth={1.5} />
                                    })}
                                </Bar>
                            )
                        })}

                    </BarChart>
                )}
            </ResponsiveContainer>
        </div>
    );
}

export default GenericBarChart;
