import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./SupplierCreation.module.css";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { createFacility, IFacilityCreateRequest, updateFacility } from "../../api/Facility";
import { FoundationError } from "../../models/FoundationError";
import { Button, TextField } from "@mui/material";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { useForm } from "react-hook-form";
import { Loading } from "../../components/loading/Loading";
import { getUsers } from "../../api/Users";
import { Role } from "../../models/Role";
import { useLocation } from "react-router-dom";
import { IFacility } from "../../models/Facility";
import { useNavigate } from "react-router-dom";
import { FacilitiesDataContext } from "../../contexts/FacilityDataContext";
import { ToastContext } from "../../contexts/ToastContext";

type IKeyValuePair = {
    key: string;
    value: string;
}

const SupplierCreation: React.FC = () => {

    const location = useLocation();
    const facility = location.state as IFacility;

    const [supervisorIds, setSupervisorIds] = useState<IKeyValuePair[]>([]);
    const [selectedSupervisors, setSelectedSupervisors] = useState<string[]>([]);
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<IFacilityCreateRequest>();
    const [facilityToUpdate, setFacilityToUpdate] = useState<IFacility>(facility);

    useEffect(() => {
        if (facility) {
            setFacilityToUpdate(facility);
            const selectedSupervisorIds = facility.supervisors?.map(f => {
                return f.id;
            })
            setSelectedSupervisors(selectedSupervisorIds);
            setValue("supervisorsIds", selectedSupervisorIds)
        }
    }, [facility, setValue])


    const { refreshFacilities } = useContext(FacilitiesDataContext);

    const navigate = useNavigate();

    const { primaryTextColor, primaryColorLight } = useContext(FoundationThemeContext);

    const clearForm = useCallback(() => {
        reset();
        setSelectedSupervisors([]);
    }, [reset]);

    const { showToast } = useContext(ToastContext);

    const onSubmit = useCallback(async (facilityInfo: IFacilityCreateRequest) => {
        let errorMessage = "";
        let response = null;
        setIsSubmiting(true);
        if (facilityToUpdate !== null && facilityToUpdate !== undefined) {
            response = await updateFacility(facilityToUpdate.id, facilityInfo);
        } else {
            response = await createFacility(facilityInfo);
        }
        setIsSubmiting(false);
        if (response instanceof FoundationError) {
            errorMessage = response.message;
            showToast(errorMessage, "error");
        } else {
            clearForm();
            refreshFacilities();
            navigate(`/facility-details`);
        }
    }, [clearForm, navigate, refreshFacilities, facilityToUpdate, showToast]);

    const [isFetchingSupervisors, setIsFetchingSupervisors] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const fetchUsers = useCallback(async () => {
        setIsFetchingSupervisors(true);
        const users = await getUsers();
        setIsFetchingSupervisors(false);
        if (users instanceof FoundationError) {
            showToast(users.getErrorMessage(), "error");
            return;
        }

        const supervisorDetails: IKeyValuePair[] = [];
        users
            .forEach(user => {
                if (user.role === Role.SUPERVISOR) {
                    supervisorDetails.push({ key: user.name, value: user.id });
                }
            })

        setSupervisorIds(supervisorDetails);
    }, [showToast]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const helperTextRootStyle = {
        "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: primaryColorLight,
            margin: 0,
            paddingLeft: "15px",
            paddingTop: "2px"
        },
    }

    return (
        <div>
            <Grid container spacing={2} padding={4}>
                <Grid item xs={4}>
                    {
                        facilityToUpdate ? (<h2 style={{ color: primaryTextColor }}>Update Facility</h2>) : (
                            <h2 style={{ color: primaryTextColor }}>{`${facilityToUpdate ? "Update" : "Add"} Supplier`}</h2>
                        )
                    }
                    <div className={styles.facilityCreationDesc}>
                        {facilityToUpdate ? (<><p style={{ textAlign: "center" }}>Update your facility details information here</p><ul className={styles.facilityCreationDescList}>
                            Simply make the desired changes to the fields provided, and be sure to click the "Update Facility" button when you are finished.
                        </ul></>) : (
                            <>
                                <p>Enter your Supplier's information here. You will need to complete this step before beginning Carbon Emission reporting for
                                    your company.
                                </p>
                                <ul className={styles.facilityCreationDescList}>
                                    <li><b>Choose one of your Supplier.</b> You can provide a Supplier Name, something you will refer to the Supplier by.</li>
                                    <li><b>Enter the Supplier information.</b> Ensure to be as accurate as possible regarding the information.</li>
                                </ul>
                                <p>Tip: You can name your suppliers by location or significance, like <i>"Headquarters, Warehouse 15</i> or <i>Bangalore Office</i>
                                </p>
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Grid id="createForm" component="form" onSubmit={handleSubmit(onSubmit)} sx={{ p: "50px" }} container>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                defaultValue={facilityToUpdate ? facilityToUpdate.name : ""}
                                margin="normal"
                                fullWidth
                                id="facilityName"
                                type="text"
                                label="Supplier Name*"
                                error={errors.facilityName !== undefined}
                                helperText={
                                    errors.facilityName ? "Facility name required" : ""
                                }
                                autoFocus
                                {...register("facilityName", { required: true })}
                            />
                        </Grid>


                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                margin="normal"
                                fullWidth
                                label="Type of Supplier*"
                                type="number"
                                id="numberOfEmployees"
                                defaultValue={facilityToUpdate ? facilityToUpdate.numberOfEmployees : ""}
                                error={errors.numberOfEmployees !== undefined}
                                helperText={
                                    errors.numberOfEmployees ? "Employee numbers required" : ""
                                }
                                {...register("numberOfEmployees", { required: true })}
                            />
                        </Grid>

                        <Grid item xs={12} padding={1} >
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="supervisors-label">Supervisors*</InputLabel>
                                <Select
                                    sx={{ backgroundColor: "#fff" }}
                                    labelId="supervisors-label"
                                    id="supervisors"
                                    multiple
                                    value={selectedSupervisors}
                                    {...register("supervisorsIds", { required: true })}
                                    onChange={(event) => {
                                        const value = event.target.value as string[];
                                        const maxSelectableItems = 5;
                                        if (maxSelectableItems) {
                                            if (value.length > maxSelectableItems) {
                                                return;
                                            }
                                        }
                                        setSelectedSupervisors(value);
                                    }}
                                    error={errors.supervisorsIds !== undefined}
                                    input={<OutlinedInput label="supervisors" />}
                                    MenuProps={MenuProps}

                                >
                                    {isFetchingSupervisors && (
                                        <MenuItem>
                                            <Loading isLoading={isFetchingSupervisors} />
                                        </MenuItem>
                                    )}
                                    {!isFetchingSupervisors && supervisorIds.length === 0 && (
                                        <MenuItem>
                                            {"No Supervisors present"}
                                        </MenuItem>
                                    )}
                                    {!isFetchingSupervisors && supervisorIds.map((supervisorDetail) => (
                                        <MenuItem
                                            key={supervisorDetail.key}
                                            value={supervisorDetail.value}
                                        >
                                            {supervisorDetail.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error id="numberOfEmployeesError">
                                    {errors.supervisorsIds ? "Supervisors required" : ""}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                margin="normal"
                                fullWidth
                                defaultValue={facilityToUpdate ? facilityToUpdate.address.street : ""}
                                label="Street*"
                                type="text"
                                id="street"
                                error={errors.street !== undefined}
                                helperText={
                                    errors.street ? "Street required" : ""
                                }
                                {...register("street", { required: true })}
                            />
                        </Grid>

                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                margin="normal"
                                fullWidth
                                defaultValue={facilityToUpdate ? facilityToUpdate.address.city : ""}
                                label="City*"
                                type="text"
                                id="city"
                                error={errors.city !== undefined}
                                helperText={
                                    errors.city ? "City required" : ""
                                }
                                {...register("city", { required: true })}
                            />
                        </Grid>

                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                margin="normal"
                                fullWidth
                                label="State*"
                                type="text"
                                id="state"
                                defaultValue={facilityToUpdate ? facilityToUpdate.address.state : ""}
                                error={errors.state !== undefined}
                                helperText={
                                    errors.state ? "State required" : ""
                                }
                                {...register("state", { required: true })}
                            />
                        </Grid>

                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                margin="normal"
                                fullWidth
                                label="Country*"
                                type="text"
                                id="country"
                                defaultValue={facilityToUpdate ? facilityToUpdate.address.country : ""}
                                error={errors.country !== undefined}
                                helperText={
                                    errors.country ? "Country required" : ""
                                }
                                {...register("country", { required: true })}
                            />
                        </Grid>

                        <Grid item xs={6} padding={1}>
                            <TextField
                                className={styles.facilityCreationFormItem}
                                sx={helperTextRootStyle}
                                margin="normal"
                                fullWidth
                                defaultValue={facilityToUpdate ? facilityToUpdate.address.pinCode : ""}
                                label="Postal Code*"
                                type="text"
                                id="pinCode"
                                error={errors.pinCode !== undefined}
                                helperText={
                                    errors.pinCode ? "Pincode required" : ""
                                }
                                {...register("pinCode", { required: true })}
                            />
                        </Grid>

                        <Grid item xs={6} padding={1}>
                        </Grid>
                        <Grid item xs={12} padding={1}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                <Loading text={`${facilityToUpdate ? "Update" : "Add"} Supplier`} isLoading={isSubmiting} />
                            </Button>

                            <Button
                                sx={{ mt: 3, mb: 2 }}
                                onClick={clearForm}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default SupplierCreation;
