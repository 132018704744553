import { IActionPlanView, IActionPlanItemView, ActionPlanStatus, ActionPlanItem } from "../models/ActionPlan";
import { IFacility } from "../models/Facility";
import { FoundationError } from "../models/FoundationError";
import { BACKEND_URL_V1, BACKEND_URL_V2, get, post } from "../utils/Api";

interface IGetActionPlansResponse {
    actionPlanViewList: IActionPlanView[]
}

interface IGetActionPlanItemsForFacilityResponse {
    actionPlanItems: IActionPlanItemView[]
}

export const getAllActionPlans = async (): Promise<FoundationError | IActionPlanView[]> => {
    try {
        const response = await get<IGetActionPlansResponse>(`${BACKEND_URL_V1}/actionPlans`);

        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody?.actionPlanViewList) {
                return response.parsedBody?.actionPlanViewList;
            }
            return [];
        }

        const errorMessage =
            response.serverError?.error.message ??
            "Action plans could not be fetched";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export const getActionItems = async (facility: IFacility): Promise<FoundationError | IActionPlanItemView[]> => {
    try {
        const response = await get<IGetActionPlanItemsForFacilityResponse>(`${BACKEND_URL_V2}/actionPlans/items/facilities/${facility.id}`);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody?.actionPlanItems) {
                return response.parsedBody?.actionPlanItems;
            }
            return [];
        }

        const errorMessage =
            response.serverError?.error.message ??
            "Action items could not be fetched";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const createActionPlan = async (
    facilityId: string,
    actionPlanItem: ActionPlanItem,
    startDate: string,
    endDate: string,
): Promise<FoundationError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/actionPlans`, {
            facilityId,
            actionPlanItem,
            startDate,
            endDate
        });
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Action plan could not be created";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const updateActionPlan = async (
    actionPlan: IActionPlanView,
    startDate: string,
    endDate: string,
): Promise<FoundationError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/actionPlans/${actionPlan.id}`, {
            startDate,
            endDate
        });
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Action plan could not be updated";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const dismissActionPlan = async (actionPlanId: string): Promise<FoundationError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/actionPlans/${actionPlanId}/dismiss`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Action plan could not be dismissed";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const updateActionPlanStatus = async (actionPlanId: string, status: ActionPlanStatus): Promise<FoundationError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/actionPlans/${actionPlanId}/statuses/${status}`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Action plan status could not be updated";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}
