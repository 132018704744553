import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import style from "./StackedAreaChart.module.css";

export interface IStackedAreaProps {
  data: any[];
  xAxisDataKey: string;
  yAxisDataKeys: string[];
  xAxisHeight?: number;
  xAxisLabelFormatter?: (value: number, index: number) => string;
  colors?: string[];
  labelFormatter?: (data: any, key: string) => string;
}

export const StackedAreaChart = (props: IStackedAreaProps) => {

  const {
    data,
    colors,
    xAxisDataKey,
    yAxisDataKeys,
    xAxisHeight = 80,
    labelFormatter = (data, key) => data[key],
  } = props;

  const lineColors = colors ?? [
    '#32E7A4',
    '#64B5FC',
    '#155EA2',
    '#0069E8',
    '#5B3A96',
    '#CE1D26',
    '#FFA600',
    '#7FC91B',
    '#04B1B4',
    '#FCEA64',
    '#CECECE',
    '#8D6BEB',
    '#878787'
  ];

  const CustomTooltip = (data: any) => {
    if (data.active && data.payload) {
      return (
        <div className={`${style.genericBarChart_tooltipContainer} recharts-custom-tooltip`}>
          <p>
            {data.label}
          </p>
          <div>
            {data.payload.map((i: any, index: number) => {
              const keyIndex = yAxisDataKeys.findIndex((dataKey) => dataKey === i.dataKey);
              return (
                <div className={style.genericBarChart_customToolTipContainer} key={index}>
                  <div
                    className={style.genericBarChart_bulletContainer}
                    style={{ backgroundColor: lineColors[keyIndex] }}
                  />
                  <span className={style.genericBarChart_value}>
                    {labelFormatter(i.payload, i.dataKey)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" aspect={3} maxHeight={230}>
      <AreaChart width={200} height={800} data={data}>
        <CartesianGrid />
        <XAxis dataKey={xAxisDataKey} height={xAxisHeight} fontFamily="inherit" angle={-90} textAnchor={"end"} />
        <YAxis />
        <Tooltip content={CustomTooltip} wrapperStyle={{ padding: "0px" }} />
        {yAxisDataKeys.map((dataKey: any, index: number) => {
          let strokeColor = (index >= data?.length) ? "#DEDFE2" : lineColors[index % lineColors.length];;
          return <Area fillOpacity={1} type="monotone" key={index} dataKey={dataKey} stroke={strokeColor} fill={strokeColor} />
        })}
      </AreaChart>
    </ResponsiveContainer>
  )
}
