import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, styled, tableCellClasses, tableRowClasses, Grid, TableSortLabel, FormControl, Select, MenuItem } from "@mui/material";
import { ReactNode, useState, useContext, useEffect, useCallback } from "react";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { createArrayWithNumbers, toFixed } from "../../utils/Utils";
import styles from "./GenericTable.module.css"

interface ITableHeaderProps<T> {
    header: string;
    sortByField?: string;
    filter?: IFilterProps<T>;
}

interface IFilterProps<T> {
    filterValues: string[];
    isValid: (val: T, selectedFilter: string) => boolean;
}

interface ISortState {
    selectedFieldToSort: string;
    direction: "asc" | "desc";
}

export interface IGenericTableProps<T> {
    headers: ITableHeaderProps<T>[];
    data: T[];
    defaultSortByField?: string;
    showHeadingRow?: boolean;
    showLegendColors?: boolean;
    dataRenderer: (row: T, column: string) => string | number | ReactNode;
    getWidth?: (column: string) => number | undefined;
    onRowClick?: (row: T) => void;
    colors?: string[];
    pagination?: boolean;
    maxHeight?: string;
    isStriped?: boolean;
    hover?: boolean;
    cursor?: "pointer";
    hoverIndex?: number;
    setHoverIndex?: (index?: number) => void;
}

function GenericTable<T>(props: IGenericTableProps<T>) {

    const { primaryColor } = useContext(FoundationThemeContext);

    const {
        headers,
        data,
        defaultSortByField,
        showHeadingRow,
        showLegendColors,
        dataRenderer,
        onRowClick = (_) => { },
        colors,
        pagination = false,
        maxHeight,
        getWidth,
        isStriped = false,
        hover = true,
        cursor,
        hoverIndex,
        setHoverIndex
    } = props;

    const [state, setState] = useState<T[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<Map<number, string>>(new Map());

    const [sortState, setSortState] = useState<ISortState>({
        selectedFieldToSort: "",
        direction: "asc"
    });

    const addFilter = (key: number, filterValue: string) => {
        const newState = new Map(selectedFilters);
        newState.set(key, filterValue);
        setSelectedFilters(newState);
    }

    const sortByField = useCallback((arr: T[], key: string, ascending: boolean): T[] => {
        const newData = [...arr];
        newData.sort((a: any, b: any) => {
            if (a[key] > b[key]) {
                return ascending ? 1 : -1;
            } else if (a[key] < b[key]) {
                return ascending ? -1 : 1;
            }
            return 0;
        });
        return newData;
    }, []);

    const filterData = useCallback((headers: ITableHeaderProps<T>[], dataList: T[]): T[] => {
        let setList: Set<T>[] = []
        headers.forEach((header, index) => {
            if (header.filter === undefined) {
                setList.push(new Set([...dataList]));
                return;
            }
            let collector: Set<T> = new Set();
            dataList.forEach(e => {
                const isValid = header.filter?.isValid;

                if (header.filter && (selectedFilters.get(index) === "All" || selectedFilters.get(index) === undefined ||
                    (isValid !== undefined && isValid(e, selectedFilters.get(index) ?? "")))) {
                    collector.add(e);
                }
            });
            setList.push(collector)
        });

        const nonEmptySetsList = setList.filter((e, index) => {
            if (headers[index].filter !== undefined || e.size !== 0) {
                return true;
            }
            return false;
        });
        const commonObjects = nonEmptySetsList.length > 0 ?
            nonEmptySetsList.reduce((accumulator, currentSet) => {
                return new Set(Array.from(accumulator).filter(obj => currentSet.has(obj)));
            }) : new Set<T>();

        return Array.from(commonObjects);
    }, [selectedFilters]);

    useEffect(() => {
        let newData: T[] = [];
        if (data && data.length > 0) {
            newData = [...data];

            newData = filterData(headers, [...newData])
            if (defaultSortByField) {
                setSortState({
                    selectedFieldToSort: defaultSortByField,
                    direction: "asc"
                })
                newData = sortByField(newData, defaultSortByField, true);
            }
        }

        setState(newData);
        if (!pagination) {
            setRowsPerPage(newData.length);
        }
    }, [data, selectedFilters, sortByField, defaultSortByField, headers, filterData, pagination])

    const sort = useCallback((key: string) => {
        let newSortState: ISortState = {
            selectedFieldToSort: "",
            direction: "asc"
        };
        if (sortState.selectedFieldToSort === key) {
            const prevSortDirection = sortState.direction;
            newSortState = {
                ...sortState,
                direction: prevSortDirection === "asc" ? "desc" : "asc"
            };
        } else {
            newSortState = {
                selectedFieldToSort: key,
                direction: "asc"
            };
        }
        setSortState(newSortState);

        const ascending = newSortState.direction === "asc";

        const newData = sortByField([...state], key, ascending);

        setState(newData);
        if (!pagination) {
            setRowsPerPage(newData.length);
        }
    }, [sortState, state, sortByField, pagination]);

    const numberOfRows = state.length;

    const GenericTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            padding: "10 20",
            textAlign: "center",
            fontSize: 14,
            fontWeight: "bold"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        [`&.${tableCellClasses.root}`]: {
            border: `1.5px solid ${primaryColor}`,
            borderTop: showHeadingRow ? 0 : "",
        },
        [`&:first-of-type`]: {
            borderLeft: 0,
        },
        [`&:last-of-type`]: {
            borderRight: 0
        }

    }));

    const GenericTableRow = styled(TableRow)(() => ({
        [`&.${tableRowClasses.root}`]: {
            border: 0,
            cursor: cursor
        },
    }));

    const StripedTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#fff",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        [`&.${tableCellClasses.root}`]: {
            borderTop: showHeadingRow ? 0 : "",
            padding: "0 8px",
            borderBottom: 0,
        },
        [`&:first-of-type`]: {
            borderLeft: 0,
            borderTopLeftRadius: "10px !important",
            borderBottomLeftRadius: "10px !important",
        },
        [`&:last-of-type`]: {
            borderRight: 0,
            borderTopRightRadius: "10px !important",
            borderBottomRightRadius: "10px !important",
            textAlign: "right",
        }

    }));

    const StripedTableRow = styled(TableRow)(() => ({
        [`&.${tableRowClasses.root}`]: {
            backgroundColor: "#fff",
            border: 0,
            height: "16px",
        },
        '&:nth-of-type(odd)': {
            backgroundColor: primaryColor,
        },
    }));

    const CustomStyledTableCell = isStriped ? StripedTableCell : GenericTableCell;
    const CustomStyledTableRow = isStriped ? StripedTableRow : GenericTableRow;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pagination ? 10 : numberOfRows);

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tableColors = colors ?? [
        '#155EA2',
        '#8D6BEB',
        '#64B5FC',
        '#32E7A4',
        '#04B1B4',
        '#FCEA64',
        '#C2B484',
        '#CECECE',
        '#878787'
    ];

    return (
        <>
            <TableContainer className={styles.genericTable_container} style={{ maxHeight: maxHeight ?? "" }}>
                <Table size='small' sx={{ maxWidth: "100%" }}>
                    {showHeadingRow && (
                        <TableHead>
                            <GenericTableRow>
                                {headers.map((header, headerIndex) =>
                                    <CustomStyledTableCell
                                        className={`${styles.genericTable_headCell} title`}
                                        width={getWidth ? getWidth(header.header) : ""}
                                        key={headerIndex}
                                        sx={{
                                            verticalAlign: "top"
                                        }}
                                        style={headerIndex === 0 ? { fontWeight: "bold", textAlign: "left" } : {}}
                                    >
                                        <Grid flexDirection={"column"} alignItems={"flex-start"} >
                                            <Grid>
                                                {header.sortByField === undefined && (
                                                    <>
                                                        {header.header}
                                                    </>
                                                )}
                                                {header.sortByField && (
                                                    <TableSortLabel
                                                        active={sortState.selectedFieldToSort === header.sortByField}
                                                        direction={sortState.direction}
                                                        onClick={() => sort(header.sortByField!)}
                                                    >
                                                        {header.header}
                                                    </TableSortLabel>
                                                )}
                                            </Grid>

                                            {header.filter && (
                                                <FormControl sx={{ m: 1 }} size="small" >
                                                    <Select
                                                        defaultValue={"All"}
                                                        value={selectedFilters.get(headerIndex)}
                                                        onChange={(e) => {
                                                            const filterValue = e.target.value;
                                                            addFilter(headerIndex, filterValue)
                                                        }}
                                                        name={headerIndex.toString()}
                                                        variant="standard"
                                                        sx={{ textAlign: "center" }}
                                                    >
                                                        <MenuItem value="All">All</MenuItem>
                                                        {header.filter
                                                            .filterValues
                                                            .map(value =>
                                                                <MenuItem
                                                                    value={value}
                                                                    key={value}>
                                                                    {value}
                                                                </MenuItem>
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </CustomStyledTableCell>
                                )}
                            </GenericTableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {numberOfRows === 0 && (
                            <CustomStyledTableRow>
                                {headers.map((column, index) =>
                                    <CustomStyledTableCell
                                        className={styles.genericTable_tableCellData}
                                        key={index}
                                        width={getWidth ? getWidth(column.header) : ""}>
                                        -
                                    </CustomStyledTableCell>
                                )}
                            </CustomStyledTableRow>
                        )}
                        {state.length > 0 && dataRenderer && (
                            <>
                                {createArrayWithNumbers(state.length)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((rowIndex) => (
                                        <CustomStyledTableRow
                                            className={styles.genericTable_tableDataRow}
                                            key={rowIndex}
                                            hover={hover}
                                            onMouseOver={() => {
                                                if (setHoverIndex) {
                                                    setHoverIndex(rowIndex);
                                                }
                                            }}
                                            onMouseOut={() => {
                                                if (setHoverIndex) {
                                                    setHoverIndex(undefined);
                                                }
                                            }}
                                            sx={{
                                                backgroundColor: rowIndex === hoverIndex ? "#0000000a" : undefined
                                            }}
                                            onClick={() => {
                                                onRowClick(state[rowIndex]);
                                            }}
                                        >
                                            {headers.map((column, columnIndex) => {
                                                const value = dataRenderer(state[rowIndex], column.header) ?? "";
                                                return (
                                                    <CustomStyledTableCell
                                                        className={styles.SummaryTable_tableCellData}
                                                        key={`${rowIndex}_${columnIndex}`}
                                                        width={getWidth ? getWidth(column.header) : ""}
                                                    >

                                                        {showLegendColors ?
                                                            (
                                                                <>
                                                                    {columnIndex === 0 &&
                                                                        <div className={styles.genericTable_LegendRow}>
                                                                            <div className={styles.genericTable_LegendIcon} style={{ backgroundColor: tableColors[rowIndex], height: '12px', width: '12px' }}></div>
                                                                            {value}
                                                                        </div>}
                                                                    {columnIndex !== 0 && (typeof value === "number" ? (toFixed(value)) : value)}
                                                                </>
                                                            ) :
                                                            (
                                                                value
                                                            )
                                                        }
                                                    </CustomStyledTableCell>
                                                );
                                            })}
                                        </CustomStyledTableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={[7, 10, 25]}
                        component="div"
                        count={numberOfRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        </>
    );
}

export default GenericTable;
