import React, { useContext, useState } from "react";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { TopNav } from "../topNav/TopNav";
import styles from "./TopNavBar.module.css"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Role } from "../../models/Role";
import { Auth } from "../../utils/Auth";

interface IProps {
  username: string;
}

interface IMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: any;
}

const ITEM_HEIGHT = 48;

const ProfileDropdown = (props: IMenuProps) => {
  const { anchorEl, setAnchorEl } = props;
  const user = Auth.getInstance().getUserSettings();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();
  const queryStr = useLocation().search;

  let menuOptions = [
    {
      title: "User Details",
      allowedRoles: [Role.ADMIN, Role.SUPER_ADMIN],
      onClick: () => {
        navigate({
          pathname: `/users`,
          search: queryStr
        });
        handleClose();
      }
    },
    {
      title: "Facility Details",
      allowedRoles: [Role.ADMIN, Role.SUPER_ADMIN],
      onClick: () => {
        navigate({
          pathname: `/facility-details`,
          search: queryStr
        });
        handleClose();
      }
    },
    {
      title: "Supplier Details",
      allowedRoles: [Role.ADMIN, Role.SUPER_ADMIN],
      onClick: () => {
        navigate({
          pathname: `/supplier-details`,
          search: queryStr
        });
        handleClose();
      }
    },
    {
      title: "Profile",
      allowedRoles: [],
      onClick: () => {
        navigate({
          pathname: `/profile`,
          search: queryStr
        });
        handleClose();
      }
    },
    {
      title: "Logout",
      allowedRoles: [],
      onClick: () => {
        navigate({
          pathname: `/logout`,
          search: queryStr
        });
        handleClose();
      }
    },
  ]

  if (user) {
    menuOptions = menuOptions.filter(obj => obj.allowedRoles.includes(user?.role) || obj.allowedRoles.length === 0)
  }

  return (
    <div>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
          },
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.title} onClick={option.onClick}>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const ProfileSection: React.FC<{ username: string }> = (props) => {
  const { username } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <div className={styles.profileSection}>
      {/* TODO: add info button */}
      <div className={styles.profileItems}>
        {username ?? "User"}
        <AccountCircleIcon className={styles.profilePictureImg} />
        <KeyboardArrowDownIcon onClick={(e: any) => { setAnchorEl(e.currentTarget) }} />
        <ProfileDropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </div>
    </div>
  );
}

const TopNavBar: React.FC<IProps> = (props) => {
  const { light } = useContext(FoundationThemeContext);
  const { username } = props;

  return (
    <div
      className={styles.topNavBar_root}
      style={{ backgroundColor: light }}
    >
      <TopNav />
      <ProfileSection username={username} />
    </div>
  );
};

export default TopNavBar;
