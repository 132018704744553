import React, { useContext } from "react";
import styles from "./SidebarNav.module.css";
import { List, ListItem, Button, ButtonProps, Accordion, AccordionSummary, AccordionDetails, Icon } from "@mui/material";
import { LinkProps, Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { ISideBarView } from "../sideBar/SideBar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
    pages: ISideBarView[];
}

interface IButtonProps {
    buttonPath: string;
    fullWidth: boolean;
    customStyles: React.CSSProperties | undefined;
}

const iconStyle = {
    height: "auto",
    width: "auto",
    display: "flex",
    justifyContent: "center",
}

const LinkButton: React.FC<ButtonProps & LinkProps & IButtonProps> = (
    props
) => {
    const { buttonPath, customStyles, ...rest } = props;
    return (
        <Button
            className={styles.sideBarNav_button}
            style={customStyles}
            component={Link}
            {...(rest as any)}
        />
    );
};

const SidebarNav: React.FC<IProps> = (props) => {
    const { primaryTextColor, selectedColor, primaryColor, dark } = useContext(FoundationThemeContext);

    const buttons: ISideBarView[] = []
    const subButtons: ISideBarView[] = []

    let navigate = useNavigate();
    const queryStr = useLocation().search;

    const { pages } = props;

    pages.forEach(page => {
        if (page.items === undefined) {
            buttons.push(page)
        } else {
            subButtons.push(page)
        }
    })

    const currentPath = useLocation().pathname;
    let [searchParams] = useSearchParams();

    return (

        <div className={`${styles.sideBarNav_root} ${styles.sideBarNav_nav}`}>
            <hr className={styles.sideBar_divider} />
            <List classes={{ root: styles.sideBarNav_list }}>
                {pages
                    .filter((page) => page.hide === undefined || page.hide === false)
                    .map((page, index) => {
                        if (page.items === undefined) {
                            return (
                                <div className={styles.sideBarNav__section} key={page.title} >
                                    <ListItem disableGutters
                                        className={
                                            currentPath.endsWith(page.href!!) ?
                                                styles.sideBarNav_listItemMainSelected :
                                                styles.sideBarNav_listItemMain
                                        }
                                    >
                                        <LinkButton
                                            fullWidth
                                            className={styles.sideBarNav_mainButton}
                                            buttonPath={page.href!!}
                                            to={page.href + "?" + searchParams.toString()}
                                            customStyles={{ backgroundColor: currentPath.endsWith(page.href!!) ? selectedColor : "" }}
                                        >
                                            <Icon sx={iconStyle}>
                                                {typeof page.icon === "string" ?
                                                    (<img src={page.icon} alt="" />) :
                                                    page.icon
                                                }
                                            </Icon>
                                            <div
                                                className={`${styles.sideBarNav_title} title`}
                                                style={{ color: currentPath.endsWith(page.href!!) ? dark : primaryTextColor }}
                                            >
                                                {page.title}
                                            </div>
                                        </LinkButton>
                                    </ListItem>
                                    {(index < pages.length - 1) && <hr className={styles.sideBar_divider} key={"divider" + index} />}
                                </div>
                            );
                        } else {
                            return (
                                <div className={styles.sideBarNav__section} key={page.title} >
                                    {
                                        <>
                                            <div className={`${styles.accordionRoot} ${index === (pages.length - 1) ? styles.accordionRoot_lastChild : undefined}`}>
                                                <Accordion
                                                    sx={{
                                                        backgroundColor: primaryColor,
                                                        boxShadow: "none",
                                                        width: 1
                                                    }}
                                                    defaultExpanded={true}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        sx={{
                                                            width: 1
                                                        }}
                                                    >
                                                        {page.title}
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        sx={{ padding: "0px 0px" }}
                                                    >
                                                        {page.items.map(subButtonPage => {
                                                            return (
                                                                <ListItem disableGutters
                                                                    key={page.title + subButtonPage.title}
                                                                    className={
                                                                        currentPath.endsWith(subButtonPage.href!!) ?
                                                                            styles.sideBarNav_listItemSubSelected :
                                                                            styles.sideBarNav_listItemSub
                                                                    }
                                                                >
                                                                    <LinkButton
                                                                        fullWidth
                                                                        buttonPath={subButtonPage.href ?? ""}
                                                                        to={subButtonPage.href + "?" + searchParams.toString()}
                                                                        className={styles.sideBarNav_subButton}
                                                                        customStyles={{ backgroundColor: currentPath.endsWith(subButtonPage.href!!) ? selectedColor : "", justifyContent: "flex-start" }}
                                                                        onClick={() => {
                                                                            navigate({
                                                                                pathname: subButtonPage.href,
                                                                                search: queryStr,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <div className={styles.subButtonIcon}>
                                                                            <Icon sx={iconStyle}>
                                                                                {typeof subButtonPage.icon === "string" ?
                                                                                    (<img src={subButtonPage.icon} alt="" />) :
                                                                                    subButtonPage.icon
                                                                                }
                                                                            </Icon>
                                                                        </div>
                                                                        <div
                                                                            className={styles.sideBarNav_title}
                                                                            style={{ color: primaryTextColor }}
                                                                        >
                                                                            {subButtonPage.title}
                                                                        </div>
                                                                    </LinkButton>
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            {(index < pages.length - 1) && <hr className={styles.sideBar_divider} key={"divider" + index} />}
                                        </>
                                    }
                                </div>
                            )
                        }
                    }
                    )}
            </List>
        </div>
    );
};

export default SidebarNav;
