import TextField from "@mui/material/TextField";
import { useCallback, useContext, useEffect, useState } from "react";
import { getAllActionPlans } from "../../../api/ActionPlan";
import { ActionTable } from "../../../components/actionTable/ActionTable";
import { FoundationThemeContext } from "../../../contexts/FoundationThemeContext";
import { IActionPlanView } from "../../../models/ActionPlan";
import { FoundationError } from "../../../models/FoundationError";
import SearchIcon from '@mui/icons-material/Search';
import style from "./ActionPlans.module.css"
import { Button, styled } from "@mui/material";
import { CreateOrUpdateActionPlanDialog } from "../../../components/createActionPlanDialog/CreateActionPlanDialog";
import { ToastContext } from "../../../contexts/ToastContext";

export const ActionPlans: React.FC = () => {
    const [actionPlans, setActionPlans] = useState<IActionPlanView[]>([]);
    const [selectedActionPlan, setSelectedActionPlan] = useState<IActionPlanView>();
    const [searchResults, setSearchResults] = useState<IActionPlanView[]>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [showCreateActionDialog, setShowCreateActionDialog] = useState(false);

    useEffect(() => {
        setSearchResults(actionPlans.filter(actionPlan => actionPlan.description.toLowerCase().search(searchString.toLowerCase()) >= 0));
    }, [searchString, actionPlans]);

    const { showToast } = useContext(ToastContext);

    const getActionPlans = useCallback(async () => {
        const actionPlans = await getAllActionPlans();
        if (actionPlans instanceof FoundationError) {
            const message = actionPlans?.message ?? "Something went wrong!";
            showToast(message, "error");
        } else {
            setActionPlans([...actionPlans]);
        }
    }, [showToast]);

    useEffect(() => {
        getActionPlans();
    }, [getActionPlans]);

    const StyledTextField = styled(TextField)(() => ({
        [`& .MuiInputBase-root`]: {
            backgroundColor: "#fff",
            width: "400px",
            "& fieldset": {
                borderColor: selectedBorderColor
            },
            "&:hover fieldset": {
                borderColor: selectedBorderColor
            },
            "&.Mui-focused fieldset": {
                borderColor: selectedBorderColor
            }
        }
    }));

    const { selectedBorderColor } = useContext(FoundationThemeContext);

    return (
        <div className={style.actionRoot}>
            {showCreateActionDialog && (
                <CreateOrUpdateActionPlanDialog
                    actionPlan={selectedActionPlan}
                    isOpenModal={showCreateActionDialog}
                    onClose={(success) => {
                        setShowCreateActionDialog(false);
                        setSelectedActionPlan(undefined);
                        if (success) {
                            getActionPlans();
                        }
                    }}
                />
            )}
            <div className={style.operationsDiv}>
                <StyledTextField
                    key="searchInput"
                    type={"text"}
                    size={"small"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Search action"
                    placeholder="Type in action to search..."
                    value={searchString}
                    autoFocus
                    onChange={(e) => {
                        setSearchString(e.target.value as string);
                    }}
                    InputProps={{
                        endAdornment: <SearchIcon fontSize="small" />,
                    }}
                    sx={{
                        width: "400px"
                    }}
                />
                <div className={style.actionButtons}>
                    <Button
                        variant="outlined"
                        onClick={() => setShowCreateActionDialog(true)}
                    >
                        Create
                    </Button>
                </div>
            </div>

            <ActionTable
                actionPlansData={searchResults}
                refreshTable={getActionPlans}
                onActionPlanClicked={(actionPlan) => {
                    setSelectedActionPlan(actionPlan);
                    setShowCreateActionDialog(true);
                }}
            />
        </div>
    );
}
