import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { formatTimestampToMonthYear } from '../../utils/DateUtils';
import GenericChartsWithTitle from '../genericChartsWithTitle/GenericChartsWithTitle';
import { GenericSummaryComponent } from './summary/GenericSummaryComponent';
import { CompanyDataContextV2 } from '../../contexts/CompanyDataContextV2';
import { ICompanyEmissionsV2 } from '../../models/CompanyEmissionsV2';
import { EmissionsPerFacilityV2 } from './emissionsPerFacility.tsx/EmissionsPerFacilityV2';
import { useSearchParams } from 'react-router-dom';
import { toFixed } from '../../utils/Utils';

interface ICompanyEmissionsOverTime {
    key: string;
    scope1?: number;
    scope2?: number;
    scope3?: number;
    scope1Emissions?: number
    scope2Emissions?: number
    scope3Emissions?: number
}

function getSummaryData(companyEmissions: ICompanyEmissionsV2) {
    const lastMonthEmissions = companyEmissions.monthlyScopeWiseEmissions.at(companyEmissions.monthlyScopeWiseEmissions.length - 1);
    const secondLastMonthEmissions = companyEmissions.monthlyScopeWiseEmissions.at(companyEmissions.monthlyScopeWiseEmissions.length - 2);

    let emissionsSinceLastMonth = 0;
    if (lastMonthEmissions && secondLastMonthEmissions) {
        const totalLastMonthEmissions = (lastMonthEmissions.scopeEmissionsMap.SCOPE_1 ?? 0) + (lastMonthEmissions.scopeEmissionsMap.SCOPE_2 ?? 0) + (lastMonthEmissions.scopeEmissionsMap.SCOPE_3 ?? 0);
        const totalSecondLastMonthEmissions = (secondLastMonthEmissions.scopeEmissionsMap.SCOPE_1 ?? 0) + (secondLastMonthEmissions.scopeEmissionsMap.SCOPE_2 ?? 0) + (secondLastMonthEmissions.scopeEmissionsMap.SCOPE_3 ?? 0);
        emissionsSinceLastMonth = totalLastMonthEmissions - totalSecondLastMonthEmissions;
    }

    return [
        [
            { key: "Company Emissions", value: companyEmissions.totalEmissions, unit: " tCO2" },
        ],
        [
            { key: "Since last month", value: emissionsSinceLastMonth, unit: " tCO2" },
        ],
        [
            { key: "Number of countries", value: companyEmissions.numberOfCountries },
            { key: "Number of cities", value: companyEmissions.numberOfCities },
            { key: "Number of facilities", value: companyEmissions.facilityEmissions.length }
        ]
    ];
}

function getEmissionsOverTimeForChart(companyEmissions?: ICompanyEmissionsV2) {
    const data: ICompanyEmissionsOverTime[] = [];
    const xAxisLabelColorList: string[] = [];

    if (!companyEmissions) {
        return { data, xAxisLabelColorList };
    }

    const totalCompanyEmissions = companyEmissions.totalEmissions;

    companyEmissions.monthlyScopeWiseEmissions.forEach((scopeWiseEmissions) => {
        const companyEmissionsOverTime: ICompanyEmissionsOverTime = {
            key: formatTimestampToMonthYear(scopeWiseEmissions.yearMonth)
        };

        const scope1Emissions = scopeWiseEmissions.scopeEmissionsMap.SCOPE_1;
        const scope2Emissions = scopeWiseEmissions.scopeEmissionsMap.SCOPE_2;
        const scope3Emissions = scopeWiseEmissions.scopeEmissionsMap.SCOPE_3;

        if (totalCompanyEmissions > 0) {
            companyEmissionsOverTime.scope1 = scope1Emissions ? (scope1Emissions / totalCompanyEmissions) * 100 : undefined;
            companyEmissionsOverTime.scope1Emissions = scope1Emissions ?? undefined;
            companyEmissionsOverTime.scope2 = scope2Emissions ? (scope2Emissions / totalCompanyEmissions) * 100 : undefined;
            companyEmissionsOverTime.scope2Emissions = scope2Emissions ?? undefined;
            companyEmissionsOverTime.scope3 = scope3Emissions ? (scope3Emissions / totalCompanyEmissions) * 100 : undefined;
            companyEmissionsOverTime.scope3Emissions = scope3Emissions ?? undefined;
        }

        data.push(companyEmissionsOverTime);
    });

    data.forEach((entry) => {
        if (entry.scope1 === undefined && entry.scope2 === undefined && entry.scope3 === undefined) {
            xAxisLabelColorList.push("red");
        } else {
            xAxisLabelColorList.push("black")
        }
    });

    return { data, xAxisLabelColorList };
}

const CompanyEmissionsOverviewV2: React.FC = React.memo(() => {
    const {
        isLoadingCompanyEmissions,
        companyEmissions,
        selectedDateRange,
        refreshCompanyEmissions
    } = useContext(CompanyDataContextV2);

    const [searchParams, setSearchParams] = useSearchParams();

    const companyEmissionsOverTimeForChartData = getEmissionsOverTimeForChart(companyEmissions);

    const colors = [
        "#155EA2",
        "#32E7A4",
        "#FCEA64",
        "#DAE3F1"
    ];

    useEffect(() => {
        if (searchParams.get("refresh") === "1") {
            refreshCompanyEmissions();
            searchParams.delete("refresh");
            setSearchParams(searchParams);
        }
    }, [searchParams, refreshCompanyEmissions, setSearchParams]);

    return (
        <div>
            <Grid container spacing={2} paddingX={4} paddingBottom={4}>
                <Grid item lg={3} md={4.5} sm={12} xs={12} >
                    <GenericSummaryComponent
                        minHeight={323}
                        selectedDateRange={selectedDateRange}
                        isLoading={isLoadingCompanyEmissions}
                        data={companyEmissions ? getSummaryData(companyEmissions) : []}
                    />
                </Grid>

                <Grid item lg={9} md={7.5} sm={12} xs={12} >
                    <GenericChartsWithTitle
                        title={'Company Emissions Over Time'}
                        chartType={'bar'}
                        isLoading={isLoadingCompanyEmissions}
                        minHeight={300}
                        bottomText={"This chart shows total scope-based emissions for all relevant facilities of your company over time."}
                        colors={colors}
                        colorLegendHeadings={["Scope 1", "Scope 2", "Scope 3"]}
                        barChartProps={{
                            stack: true,
                            multipleColorBars: true,
                            data: companyEmissionsOverTimeForChartData.data,
                            dataKeys: ["scope1", "scope2", "scope3"],
                            barLabelKey: "key",
                            angle: -28,
                            labelFormatter: (data, key) => `${toFixed(data[key], 2)}% (${data[`${key}Emissions`]} tCO2)`,
                            getXAxisLabelColor: (index) => {
                                return index >= companyEmissionsOverTimeForChartData.xAxisLabelColorList.length ? "black" : companyEmissionsOverTimeForChartData.xAxisLabelColorList[index];
                            },
                            xAxisHeight: 38
                        }}
                    />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} >
                    <EmissionsPerFacilityV2 minHeight={400} facilityEmissions={companyEmissions?.facilityEmissions} />
                </Grid>

            </Grid>
        </div >
    );
});

export default CompanyEmissionsOverviewV2;

