import { CircularProgress, Grid, MenuItem, Select } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { getGovernanceData } from "../../api/Company";
import GenericChartsWithTitle from "../../components/genericChartsWithTitle/GenericChartsWithTitle";
import { IKeyValuePair } from "../../components/genericPieChart/GenericPieChart";
import { TableWithPieOrBarChart } from "../../components/tableWithPieOrBarChart/TableWithPieOrBarChart";
import { FoundationError } from "../../models/FoundationError";
import { IComplaintsAndMeasuresView, IGovernanceView } from "../../models/Governance";
import { Auth } from "../../utils/Auth";
import styles from "./GovernanceDashboard.module.css";
import { ToastContext } from "../../contexts/ToastContext";

interface IComplaintsAndMeasuresBarChartData {
    type: string;
    pending: number;
    completed: number;
}

interface IDirectorsKnowledgeBarChartData {
    key: string;
    value: number;
}

function barChartDataForComplaintsAndMeasures(complaintsAndMeasurementView?: IComplaintsAndMeasuresView): IComplaintsAndMeasuresBarChartData[] {
    const chartData: IComplaintsAndMeasuresBarChartData[] = [];
    if (complaintsAndMeasurementView === undefined) {
        return chartData;
    }

    const currentYearData = complaintsAndMeasurementView.currentYearData;

    chartData.push({
        type: "Communities",
        pending: currentYearData.communities.pendingCount,
        completed: currentYearData.communities.filedCount - currentYearData.communities.pendingCount
    });

    chartData.push({
        type: "Investors (other than shareholders)",
        pending: currentYearData.investorsOtherThanShareholders.pendingCount,
        completed: currentYearData.investorsOtherThanShareholders.filedCount - currentYearData.investorsOtherThanShareholders.pendingCount
    });

    chartData.push({
        type: "Shareholders",
        pending: currentYearData.shareholders.pendingCount,
        completed: currentYearData.shareholders.filedCount - currentYearData.shareholders.pendingCount
    });

    chartData.push({
        type: "Employees & Workers",
        pending: currentYearData.employeesAndWorkers.pendingCount,
        completed: currentYearData.employeesAndWorkers.filedCount - currentYearData.employeesAndWorkers.pendingCount
    });

    chartData.push({
        type: "Customers",
        pending: currentYearData.customers.pendingCount,
        completed: currentYearData.customers.filedCount - currentYearData.customers.pendingCount
    });

    chartData.push({
        type: "Value Chain Partners",
        pending: currentYearData.valueChainPartners.pendingCount,
        completed: currentYearData.valueChainPartners.filedCount - currentYearData.valueChainPartners.pendingCount
    });

    chartData.push({
        type: "Others",
        pending: currentYearData.others.pendingCount,
        completed: currentYearData.others.filedCount - currentYearData.others.pendingCount
    });

    return chartData;
}

const GovernanceDashboard: React.FC = () => {
    const [selectedYear, setSelectedYear] = useState<number>();
    const [reportYears, setReportYears] = useState<number[]>();
    const [governanceData, setGovernanceData] = useState<IGovernanceView | null>();
    const [yearlyGovernanceData, setYearlyGovernanceData] = useState<IGovernanceView[]>();
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const fetchGovernanceDashboardData = useCallback(async (companyId: string) => {
        setIsLoading(true);
        const response = await getGovernanceData(companyId);
        setIsLoading(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setYearlyGovernanceData(response.governanceViews);
    }, [showToast]);

    const changeSelectedYear = async (year: number) => {
        setSelectedYear(year);
        setGovernanceData(yearlyGovernanceData && yearlyGovernanceData?.find(data => data.year === year));
    }

    useEffect(() => {
        if (!yearlyGovernanceData) {
            return;
        }
        const allYears = yearlyGovernanceData && yearlyGovernanceData.map(data => data.year);
        setReportYears(allYears);
        setGovernanceData(yearlyGovernanceData[0]);
        setSelectedYear(allYears[0])
    }, [yearlyGovernanceData]);


    useEffect(() => {
        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings) {
            fetchGovernanceDashboardData(userSettings.parentCompanyId);
        }
    }, [fetchGovernanceDashboardData]);

    const womenRepresentationPieChartData = [
        { key: "Other", value: governanceData ? (governanceData.womenRepresentation.totalNoOfDirectors - governanceData.womenRepresentation.totalNoOfWomenDirectors - governanceData.womenRepresentation.totalNoOfWomenAtKMP) : 0 },
        { key: "Women Directors", value: governanceData?.womenRepresentation.totalNoOfWomenDirectors },
        { key: "Women at KMP level", value: governanceData?.womenRepresentation.totalNoOfWomenAtKMP },
    ];

    const complaintsAndMeasures = barChartDataForComplaintsAndMeasures(governanceData?.complaintsAndMeasures);

    let pieChartTop5ClientsData: IKeyValuePair[] = [
        { key: "Top 5", value: governanceData?.revenueFromTopClientsView.percentageRevenueFromTopFiveClients ?? 0 },
        { key: "Other", value: (100 - (governanceData?.revenueFromTopClientsView.percentageRevenueFromTopFiveClients ?? 0)) }
    ];

    let pieChartTop10ClientsData: IKeyValuePair[] = [
        { key: "Top 10", value: governanceData?.revenueFromTopClientsView.percentageRevenueFromTopTenClients ?? 0 },
        { key: "Other", value: (100 - (governanceData?.revenueFromTopClientsView.percentageRevenueFromTopTenClients ?? 0)) }
    ];

    let pieChartTopClientsData = pieChartTop5ClientsData;
    let pieChartBoardAgeGroupData: IKeyValuePair[] = governanceData ?
        Object.entries(governanceData.boardAgeGroupView.ageGroupToCountMap)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(entry => {
                return { key: entry[0], value: entry[1] }
            }
            ) : []

    let pieChartTopProductsData: IKeyValuePair[] = governanceData ?
        Object.entries(governanceData.revenueFromTopProductsView.topProductsPercentage)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(entry => {
                return { key: entry[0], value: entry[1] }
            }
            ) : []

    let pieChartIndependentDirectorsData: IKeyValuePair[] = governanceData ? [
        { key: "Independent Directors", value: governanceData.directorsKnowledgeRepresentationView.independentDirectorPercentage ?? 0 },
        { key: "Others", value: (100 - (governanceData.directorsKnowledgeRepresentationView.independentDirectorPercentage ?? 0)) }
    ] : []

    let barChartDataForDirectorsKnowledge: IDirectorsKnowledgeBarChartData[] = [
        { key: "Supply Chain", value: governanceData?.directorsKnowledgeRepresentationView.supplyChain ?? 0 },
        { key: "Finance", value: governanceData?.directorsKnowledgeRepresentationView.finance ?? 0 },
        { key: "Technology", value: governanceData?.directorsKnowledgeRepresentationView.technology ?? 0 },
        { key: "Strategy", value: governanceData?.directorsKnowledgeRepresentationView.strategy ?? 0 },
        { key: "Risk Management", value: governanceData?.directorsKnowledgeRepresentationView.riskManagement ?? 0 },
    ]

    const pieChartColors = ["#32E7A4", "#64B5FC", "#FCEA64"];

    return (
        <>
            {isLoading && (
                <div className={styles.governanceDashboard__loadingDiv}>
                    <CircularProgress
                        size={30}
                        color={"inherit"}
                    />
                </div>
            )}

            {!isLoading && !governanceData && (
                <div className={styles.governanceDashboard__loadingDiv}>
                    No ESG Report submitted
                </div>
            )}

            {!isLoading && governanceData && (
                <>
                    <div className={styles.governanceDashboard__YearContainer}>
                        <div className={styles.governanceDashboard__YearText}>Data based on ESG Report for </div>
                        <Select
                            id="selectedYear"
                            value={selectedYear}
                            onChange={(event) => {
                                const value = event.target.value;
                                changeSelectedYear(Number(value));
                            }}
                        >
                            {reportYears && reportYears.map(year => {
                                return (
                                    <MenuItem
                                        key={year}
                                        value={year}
                                    >
                                        {year}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <Grid container spacing={2} padding={2}>
                        <Grid item lg={4} md={4} sm={12} xs={12} >
                            <TableWithPieOrBarChart
                                showHeadingRow
                                orient="row"
                                title={"Representation of women at the Board Management and KMP level"}
                                headers={["Total Directors", "Women Directors", "Women at KMP level"].map((header) => { return { header } })}
                                data={[0]}
                                dataRenderer={(_, column) => {
                                    switch (column) {
                                        case "Total Directors":
                                            return governanceData.womenRepresentation.totalNoOfDirectors;
                                        case "Women Directors":
                                            return governanceData.womenRepresentation.totalNoOfWomenDirectors;
                                        case "Women at KMP level":
                                            return governanceData.womenRepresentation.totalNoOfWomenAtKMP;
                                    }
                                }}
                                maxHeight={"300px"}
                                chartType={"pie"}
                                pieChartProps={{
                                    data: womenRepresentationPieChartData,
                                    dataKey: "value",
                                    minWidth: 200,
                                    minHeight: 300,
                                    legendLabelKey: "key",
                                    toolTipLabelKey: "key",
                                    unit: "total",
                                    toolTipLabelFormatter: (_, index) => `${womenRepresentationPieChartData[index].key}: ${womenRepresentationPieChartData[index].value}`
                                }}
                            />
                        </Grid>
                        <Grid item lg={8} md={8} sm={12} xs={12} >
                            <GenericChartsWithTitle
                                title={'Complaints and Measures'}
                                chartType={'bar'}
                                isLoading={isLoading}
                                colors={["#32E7A4", "#04B1B4"]}
                                barChartProps={{
                                    stack: true,
                                    multipleColorBars: true,
                                    data: complaintsAndMeasures,
                                    dataKeys: ["pending", "completed"],
                                    barLabelKey: "type",
                                    angle: -10,
                                    xAxisHeight: 40,
                                    xAxisFontSize: 10,
                                    maxHeight: 400
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TableWithPieOrBarChart
                                showLegendColors
                                isStriped
                                showInfoIcon
                                showHeadingRow
                                showActionButton
                                headers={["", "Percentage"].map((header) => { return { header } })}
                                tabs={["Top 5", "Top 10"]}
                                data={pieChartTopClientsData}
                                dataRenderer={(data, column) => {
                                    switch (column) {
                                        case '':
                                            return data.key;
                                        case 'Percentage':
                                            return data.value;
                                    }
                                }}
                                onTabChange={(category) => {
                                    switch (category) {
                                        case 'Top 5':
                                            pieChartTopClientsData = pieChartTop5ClientsData;
                                            return pieChartTop5ClientsData;
                                        case 'Top 10':
                                            pieChartTopClientsData = pieChartTop10ClientsData;
                                            return pieChartTop10ClientsData;
                                        default:
                                            return []
                                    }
                                }}
                                chartType="pie"
                                isLoading={isLoading}
                                pieChartProps={{
                                    data: pieChartTopClientsData,
                                    unit: "%",
                                    dataKey: "value",
                                    minWidth: 330,
                                    minHeight: 330,
                                    legendLabelKey: "key",
                                    toolTipLabelKey: "key",
                                }}
                                colors={pieChartColors}
                                orient={"row"}
                                title={'Revenue from Top Clients'}
                            />
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TableWithPieOrBarChart
                                showLegendColors
                                isStriped
                                showInfoIcon
                                showActionButton
                                showHeadingRow
                                headers={["", "Count"].map((header) => { return { header } })}
                                data={pieChartBoardAgeGroupData}
                                dataRenderer={(data, column) => {
                                    switch (column) {
                                        case '':
                                            return data.key;
                                        case 'Count':
                                            return data.value;
                                    }
                                }}
                                chartType="pie"
                                isLoading={isLoading}
                                pieChartProps={{
                                    data: pieChartBoardAgeGroupData,
                                    dataKey: "value",
                                    minWidth: 330,
                                    minHeight: 330,
                                    legendLabelKey: "key",
                                    toolTipLabelKey: "key",
                                }}
                                orient={"row"}
                                title={'Board members Age group'}
                            />
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TableWithPieOrBarChart
                                showLegendColors
                                isStriped
                                showInfoIcon
                                showActionButton
                                showHeadingRow
                                headers={["", "Percentage"].map((header) => { return { header } })}
                                data={pieChartTopProductsData}
                                dataRenderer={(data, column) => {
                                    switch (column) {
                                        case '':
                                            return data.key;
                                        case 'Percentage':
                                            return data.value;
                                    }
                                }}
                                chartType="pie"
                                isLoading={isLoading}
                                pieChartProps={{
                                    data: pieChartTopProductsData,
                                    unit: "%",
                                    dataKey: "value",
                                    minWidth: 330,
                                    minHeight: 330,
                                    legendLabelKey: "key",
                                    toolTipLabelKey: "key",
                                }}
                                colors={pieChartColors}
                                orient={"row"}
                                title={'Revenue from top Products'}
                            />
                        </Grid>
                        <Grid item lg={8} md={8} sm={12} xs={12} >
                            <GenericChartsWithTitle
                                title={'Directors Knowledge & Expertise'}
                                chartType={'bar'}
                                isLoading={isLoading}
                                colors={["#32E7A4"]}
                                barChartProps={{
                                    data: barChartDataForDirectorsKnowledge,
                                    dataKeys: ["value"],
                                    barLabelKey: "key",
                                    angle: -10,
                                    xAxisHeight: 40,
                                    xAxisFontSize: 10,
                                    maxHeight: 400
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TableWithPieOrBarChart
                                showLegendColors
                                isStriped
                                showInfoIcon
                                showActionButton
                                showHeadingRow
                                headers={["", "Percentage"].map((header) => { return { header } })}
                                data={pieChartIndependentDirectorsData}
                                dataRenderer={(data, column) => {
                                    switch (column) {
                                        case '':
                                            return data.key;
                                        case 'Percentage':
                                            return data.value;
                                    }
                                }}
                                chartType="pie"
                                isLoading={isLoading}
                                pieChartProps={{
                                    data: pieChartIndependentDirectorsData,
                                    unit: "%",
                                    dataKey: "value",
                                    minWidth: 330,
                                    minHeight: 330,
                                    legendLabelKey: "key",
                                    toolTipLabelKey: "key",
                                }}
                                colors={pieChartColors}
                                orient={"row"}
                                title={'Revenue from top Products'}
                            />
                        </Grid>
                    </Grid >
                </>
            )}
        </>
    );
}

export default GovernanceDashboard;
