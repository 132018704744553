import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ReactNode, useContext } from "react";
import style from "./GenericDialog.module.css";
import { Loading } from "../loading/Loading";
import CloseIcon from '@mui/icons-material/Close';
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";

interface IProps {
    isOpenModal: boolean;
    onClose: () => void;
    title: string;
    headerBackgroundColor?: string;
    children: string | ReactNode;
    btnText: string;
    btnLoading?: boolean;
    btnAction?: () => void;
    alignDescription?: "left" | "center";
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

function GenericDialog(props: IProps) {
    const {
        isOpenModal,
        title,
        headerBackgroundColor = "#f6f6f6",
        children,
        btnText,
        alignDescription = "center",
        btnAction = () => { },
        btnLoading = false,
        maxWidth,
        onClose
    } = props;

    const { primaryColor, primaryTextColor } = useContext(FoundationThemeContext);

    return (
        <Dialog open={isOpenModal} sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: maxWidth ?? "600px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #CCCCCC!important",
                    borderRadius: "10px"
                },
            }
        }}
        >
            <DialogTitle className={style.modal_DialogTitleContainer} sx={{ backgroundColor: headerBackgroundColor }}>
                <div className={style.modalTitleText}>{title}</div>
                <IconButton className={style.modal_DialogTitleCross} onClick={onClose}>
                    <CloseIcon className={style.modal_crossButton} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={style.modal_dialogContainer}>
                <div className={style.modal_descriptionContainer} style={{ textAlign: alignDescription }}>
                    {children}
                </div>
                <div className={style.modal_btnContainer}>
                    <Button
                        className={style.modalButton}
                        style={{
                            marginLeft: "10px",
                            color: primaryColor,
                            backgroundColor: primaryTextColor,
                            fontWeight: "bolder",
                            textTransform: "none"
                        }}
                        color="primary"
                        onClick={btnAction}
                        disabled={btnLoading}
                    >
                        <Loading isLoading={btnLoading} text={btnText} />
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default GenericDialog
