import styles from "./UserManagement.module.css";
import GenericTableWithTitle from "../genericTable/GenericTableWithTitle"
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { AddOrModifyUserDialog, IUserForm } from "../modifyUserDialog/AddOrModifyUserDialog";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from "../confirmationDialog/ConfirmationDialog";
import { FoundationError } from "../../models/FoundationError";
import { deleteUser, getUsers } from "../../api/Users";
import { IUserProfile } from "../../models/User";
import { Loading } from "../loading/Loading";
import { Role } from "../../models/Role";
import { ToastContext } from "../../contexts/ToastContext";

const headers = ["Name", "Email", "Role", "Phone Number", "Designation", "Facilities", ""]

export const UserManagement: React.FC = () => {

  const [companyUsers, setCompanyUsers] = useState<IUserProfile[]>([]);
  const [showAddOrModifyUserDialog, setShowAddOrModifyUserDialog] =
    useState<{ show: boolean; selectedUser: IUserForm | null }>
      ({
        show: false,
        selectedUser: null
      })

  const [showDeleteUserDialog, setShowDeleteUserDialog] =
    useState<{ show: boolean; selectedUser: IUserForm | null }>
      ({
        show: false,
        selectedUser: null
      })

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { primaryTextColor } = useContext(FoundationThemeContext);
  const { showToast } = useContext(ToastContext);

  const getFacilities = (userProfile: IUserProfile): string => {
    const names: string[] = [];
    if (userProfile.role === Role.ADMIN || userProfile.role === Role.SUPER_ADMIN) {
      return "ALL";
    }
    if (userProfile.facilityViews !== undefined) {
      for (let i = 0; i < userProfile.facilityViews.length; i++) {
        names.push(userProfile.facilityViews[i].name)
      }
    }
    return names.join()
  };

  const hideAddOrModifyDialog = () => {
    setShowAddOrModifyUserDialog({
      selectedUser: null,
      show: false
    })
  }

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    const users = await getUsers();
    setIsLoading(false);
    if (users instanceof FoundationError) {
      showToast(users.getErrorMessage(), "error");
      setCompanyUsers([]);
      return;
    }
    setCompanyUsers(users);
  }, [showToast]);

  const onDeleteUser = async (userId: string) => {
    setIsDeleting(true);
    const error = await deleteUser(userId)
    setIsDeleting(false);
    if (error instanceof FoundationError) {
      showToast(error.getErrorMessage(), "error");
      return;
    }

    showToast("User has been deleted successfully", "success");
    setShowDeleteUserDialog({
      show: false,
      selectedUser: null
    })
    fetchUsers();
  }

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  companyUsers?.sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });

  return (
    <div className={styles.userManagementRoot}>
      {(showDeleteUserDialog.show && showDeleteUserDialog.selectedUser && showDeleteUserDialog.selectedUser.id !== undefined) && (
        <ConfirmationDialog
          buttonText={"Delete"}
          bodyText={`Do you want to delete the user: ${showDeleteUserDialog.selectedUser.name
            } ?`}
          open={showDeleteUserDialog.show}
          onClose={() => {
            setShowDeleteUserDialog({
              show: false,
              selectedUser: null
            });
          }}
          onApprove={() => onDeleteUser(showDeleteUserDialog.selectedUser!!.id!!)}
          isApproving={isDeleting}
        />
      )}

      {showAddOrModifyUserDialog.show && (
        <AddOrModifyUserDialog
          open={showAddOrModifyUserDialog.show}
          selectedUser={showAddOrModifyUserDialog.selectedUser}
          onClose={(success, message) => {
            hideAddOrModifyDialog();
            if (message) {
              if (success) {
                fetchUsers();
              }

              showToast(message, success ? "success" : "error");
            }
          }}
        />
      )}
      <div className={styles.submitButton}>
        <Button
          style={{ backgroundColor: primaryTextColor, color: "#fff" }}
          variant="outlined"
          onClick={() => {
            setShowAddOrModifyUserDialog({
              selectedUser: null,
              show: true
            })
          }}
        >
          Add User
        </Button>
      </div>
      <Grid container spacing={2} padding={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {companyUsers.length === 0 && (
            <div className={styles.emptyListMessage}>
              <Loading text={"No users found"} isLoading={isLoading} />
            </div>
          )}
          {companyUsers.length > 0 && (
            <GenericTableWithTitle
              showHeadingRow={true}
              title={"Users"}
              headers={headers.map((str) => {
                return { header: str };
              })}
              pagination={true}
              data={companyUsers}
              maxHeight="400px"
              dataRenderer={(data, column) => {
                const facilities = getFacilities(data)
                switch (column) {
                  case 'Name':
                    return data.name;
                  case 'Email':
                    return data.email;
                  case 'Role':
                    return data.role;
                  case 'Phone Number':
                    return (data.phoneNumber ?? "-");
                  case 'Designation':
                    return data.designationInCompany;
                  case 'Facilities':
                    return (facilities);
                  case "":
                    return (<div className={styles.actionButtons}>
                      <IconButton
                        name="close"
                        color="default"
                        onClick={() => {
                          setShowAddOrModifyUserDialog({
                            selectedUser: data as IUserForm,
                            show: true
                          })
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        name="close"
                        color="default"
                        onClick={() => {
                          setShowDeleteUserDialog({
                            selectedUser: data as IUserForm,
                            show: true
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>)
                }
              }}
            />
          )}
        </Grid>
      </Grid>

    </div>
  )
}
