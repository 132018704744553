import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { IFacility } from "../models/Facility";
import { ToastContext } from "./ToastContext";
import { getAllFacilities } from "../api/Facility";
import { FoundationError } from "../models/FoundationError";
import { Auth } from "../utils/Auth";

export interface ICompanyData {
    facilities: IFacility[];
    refreshFacilities: () => void;
}

export const FacilitiesDataContext = createContext<ICompanyData>(undefined!);

export const FacilitiesDataProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const { showToast } = useContext(ToastContext);
    let [facilities, setFacilities] = useState<IFacility[]>([]);

    const fetchFacilities = useCallback(async () => {
        if (!Auth.getInstance().isLoggedIn()) {
            return;
        }

        const response = await getAllFacilities();
        if (response instanceof FoundationError) {
            showToast("Unable to get facilities", "error");
            return;
        }

        const sortedFacilities = response.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });

        setFacilities(sortedFacilities);
    }, [showToast]);

    const refreshFacilities = () => {
        fetchFacilities();
    };


    useEffect(() => {
        fetchFacilities();
    }, [fetchFacilities]);

    return (
        <FacilitiesDataContext.Provider
            value={{
                facilities,
                refreshFacilities
            }}
        >
            {children}
        </FacilitiesDataContext.Provider>
    );
}
