import style from "./GenericBarChart.module.css";
import { Card, CardContent, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import GenericBarChart, { IGenericBarChartProps } from "./GenericBarChart";

interface IProps {
    title: string;
    showInfoIcon?: boolean;
    infoIconText?: string;
}

function GenericBarChartWithTitle(props: IProps & IGenericBarChartProps) {
    const { title, showInfoIcon, infoIconText } = props;

    return (
        <>
            <Card className={style.genericBarChart_card}>
                <div className={style.genericBarChart_textHeadingConatiner}>
                    <div className={`${style.genericBarChart_title} title`}>{title}</div>
                    {showInfoIcon && (
                        <Tooltip title={infoIconText}>
                            <InfoIcon style={{ color: "#CECECE" }} />
                        </Tooltip>
                    )}
                </div>
                <CardContent className={style.genericBarChart_cardContent}>
                    <GenericBarChart {...props} />
                </CardContent>
            </Card>
        </>
    );
}

export default GenericBarChartWithTitle;
