import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { IQuestionAndAnswer, IReportData, QuestionType } from "../../models/Report";
import { createArrayWithNumbers } from "../../utils/Utils";
import styles from "./ReportingInput.module.css";
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
    questionObject: IQuestionAndAnswer;
    questionIndex: number;
    sectionIndex: number;
    sectionId: string;
    setValue: UseFormSetValue<IReportData>;
    getValues: UseFormGetValues<IReportData>;
    allowEdit: boolean;
}

const ReportingInput: React.FC<IProps> = (props) => {
    const {
        sectionIndex,
        questionIndex,
        setValue,
        getValues,
        allowEdit
    } = props;

    const {
        type,
        question,
        choices,
        questionId,
        tableData,
        infoMark
    } = props.questionObject;

    let defaultCheckedValues: string[] = [];
    if (type === QuestionType.MCQ_MULTI) {
        defaultCheckedValues = getValues(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`) as string[] | undefined ?? [];
    }

    const defaultCheckedValue = getValues(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`) as string | undefined;

    const [selectedItems, setSelectedItems] = useState<string[]>(defaultCheckedValues);
    const [selectedItem, setSelectedItem] = useState<string | undefined>(defaultCheckedValue);

    let tableColumnsCount = 0;
    if (tableData) {
        tableData.headers[0].forEach((header) => tableColumnsCount += header.colSpan ?? 1);
    }

    let defaultTableValues: string[][] = [];
    if (type === QuestionType.TABULAR) {
        const tableValues = getValues(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`) as string | undefined;
        if (tableValues) {
            try {
                defaultTableValues = JSON.parse(tableValues);
            } catch {
                console.log("Unable to parse tabular answers");
            }
        }
    }

    const findAndWrapLinks = (text: string) => {
        const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;

        const parts: string[] = [];
        let lastIndex = 0;
        let match;

        text.split("\n").forEach((value) => parts.push(value));
        return parts.map((value) => {
            const content = [];
            while ((match = urlRegex.exec(value)) !== null) {
                const url = match[0];
                const before = value.substring(lastIndex, match.index);
                lastIndex = urlRegex.lastIndex;

                if (before) {
                    content.push(before);
                }

                content.push(
                    <a key={url} href={url} rel="noreferrer" target="_blank">
                        {url}
                    </a>
                );
            }
            const remainingText = value.substring(lastIndex);

            if (remainingText) {
                content.push(remainingText);
            }
            return (
                <>
                    {content}<br /><br />
                </>
            );
        });
    };


    return (
        <Card className={styles.reportingInput__questionContainer}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <div className={styles.reportingInput__questionNumber}>Question {props.questionIndex + 1}</div>
                        <Box className={styles.reportingInput__question}>
                            {question}
                            {infoMark && (
                                <Tooltip
                                    title={
                                        <Typography>{findAndWrapLinks(infoMark ?? "")}</Typography>
                                    }
                                    arrow
                                >
                                    <InfoIcon sx={{ color: "#CECECE", marginLeft: "10px" }} />
                                </Tooltip>
                            )}
                        </Box>
                    </Grid>
                    {type === QuestionType.MCQ_MULTI && (
                        <>
                            <Grid
                                item
                                md={12}
                                key={`${questionId}`}
                            >
                                <FormGroup>
                                    {choices && choices.map((choice, index) =>
                                        <FormControlLabel
                                            key={`mcq-multi_${index}`}
                                            value={choice}
                                            control={<Checkbox checked={selectedItems.includes(choice)} />}
                                            label={choice}
                                            onChange={(_, checked) => {
                                                if (!allowEdit) {
                                                    return;
                                                }

                                                let newSelectedItems: string[];
                                                if (checked) {
                                                    newSelectedItems = [choice, ...selectedItems];
                                                } else {
                                                    newSelectedItems = selectedItems.filter((value => value !== choice));
                                                }

                                                setValue(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`, newSelectedItems, { shouldDirty: true });
                                                setSelectedItems(newSelectedItems);
                                            }}
                                        />
                                    )}
                                </FormGroup>
                            </Grid>
                        </>
                    )}

                    {type === QuestionType.MCQ_SINGLE && (
                        <>
                            <Grid
                                item
                                md={12}
                                className={styles.reportingInput__options}
                                key={`${questionId}`}
                            >
                                <FormControl>
                                    <RadioGroup name={`radio-buttons-group-${questionId}`}>
                                        {choices && choices.map((choice, index) =>
                                            <FormControlLabel
                                                key={`mcq-single_${index}`}
                                                value={choice}
                                                control={<Radio checked={selectedItem ? selectedItem === choice : false} />}
                                                label={choice}
                                                onChange={(_, checked) => {
                                                    if (!allowEdit) {
                                                        return;
                                                    }

                                                    let newSelectedItem = "";
                                                    if (checked) {
                                                        newSelectedItem = choice;
                                                    }

                                                    setSelectedItem(newSelectedItem);
                                                    setValue(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`, newSelectedItem, { shouldDirty: true });
                                                }}
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {type === QuestionType.ONE_LINE && (
                        <Grid item md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                defaultValue={getValues(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`) as string | undefined}
                                inputProps={
                                    { readOnly: !allowEdit }
                                }
                                onBlur={(event) => {
                                    if (allowEdit) {
                                        setValue(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`, event.target.value, { shouldDirty: true });
                                    }
                                }}
                            />
                        </Grid>
                    )}

                    {type === QuestionType.MULTI_LINE && (
                        <Grid item md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={5}
                                defaultValue={getValues(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`) as string | undefined}
                                inputProps={
                                    { readOnly: !allowEdit }
                                }
                                onBlur={(event) => {
                                    if (allowEdit) {
                                        setValue(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer`, event.target.value, { shouldDirty: true });
                                    }
                                }}
                            />
                        </Grid>
                    )}

                    {type === QuestionType.TABULAR && tableData && (
                        <Table>
                            <TableHead>
                                {tableData.headers.map((headerRow, headerRowIndex) =>
                                    <TableRow key={`${questionId}_headerRow_${headerRowIndex}`}>
                                        {headerRow.map((header, headerIndex) =>
                                            <TableCell
                                                key={`${questionId}_header${headerRowIndex}_${headerIndex}`}
                                                colSpan={header.colSpan ?? 1}
                                                rowSpan={header.rowSpan ?? 1}
                                                align="center">
                                                {header.title}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                                {tableData.rowTitles.map((title, rowIndex) =>
                                    <TableRow key={`${questionId}_row_${rowIndex}`}>
                                        <TableCell>{title}</TableCell>
                                        {createArrayWithNumbers(tableColumnsCount - 1).map((columnIndex) => {
                                            let defaultValue = undefined;
                                            if (defaultTableValues[rowIndex] !== undefined && defaultTableValues[rowIndex] !== null
                                                && defaultTableValues[rowIndex][columnIndex] !== undefined && defaultTableValues[rowIndex][columnIndex] !== null) {
                                                defaultValue = defaultTableValues[rowIndex][columnIndex];
                                            }
                                            return (
                                                <TableCell
                                                    key={`${questionId}_row${rowIndex}_${columnIndex}`}
                                                    align="center">
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        defaultValue={defaultValue}
                                                        inputProps={
                                                            { readOnly: !allowEdit }
                                                        }
                                                        sx={{ minWidth: 80 }}
                                                        onBlur={(event) => {
                                                            if (allowEdit) {
                                                                setValue(`sections.${sectionIndex}.questionAndAnswers.${questionIndex}.answer.${rowIndex}.${columnIndex}`, event.target.value, { shouldDirty: true });
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportingInput;
