import { Grid, IconButton } from "@mui/material";
import GenericTableWithTitle from "../../components/genericTable/GenericTableWithTitle";
import styles from "./SupplierDetails.module.css";
import EditIcon from '@mui/icons-material/Edit';

const SupplierDetails: React.FC = () => {
    const headers = ["Name", "Type of Supplier", "Location", "Supervisor", ""]

    const hardcodedSuppliers = [
        {
            name: "MahaLakshmi Transporters",
            typeOfSupplier: "Transport",
            location: "Bangalore",
            supervisor: "Prashan"
        },
        {
            name: "Bharatiya Telecom Group",
            typeOfSupplier: "Telecommunications",
            location: "Indiranagar, Bangalore",
            supervisor: "Aditya"
        },
        {
            name: "Surya Systems Ltd.",
            typeOfSupplier: "Service Provider",
            location: "Chennai",
            supervisor: "Ishita"
        },
        {
            name: "AJs Electric Equipments",
            typeOfSupplier: "Equipments",
            location: "New Delhi",
            supervisor: "Ajinkya"
        },
        {
            name: "Calorifree Industries",
            typeOfSupplier: "Vendor",
            location: "Vasant Vihar, Bangalore",
            supervisor: "Harsh"
        }
    ];

    return (
        <div className={styles.root}>
            <Grid container spacing={2} padding={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <GenericTableWithTitle
                        showHeadingRow={true}
                        pagination={true}
                        title={"Suppliers"}
                        headers={headers.map((str) => {
                            return { header: str };
                        })}
                        data={hardcodedSuppliers}
                        maxHeight="400px"
                        getWidth={(column) => {
                            if (column === "Supervisors" || column === "Name") {
                                return 160;
                            } else {
                                return 80;
                            }
                        }}
                        dataRenderer={(data, column) => {
                            switch (column) {
                                case 'Name':
                                    return data.name;
                                case 'Type of Supplier':
                                    return data.typeOfSupplier;
                                case 'Location':
                                    return data.location;
                                case 'Supervisor':
                                    return data.supervisor;
                                case '':
                                    return (<div className={styles.actionButtons}>
                                        <IconButton
                                            name="close"
                                            color="default"
                                            onClick={() => { }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </div>)
                            }
                        }}
                        hover={false}
                    />

                </Grid>
            </Grid>
        </div>
    )
}

export default SupplierDetails;
