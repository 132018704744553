import { IUserForm } from "../components/modifyUserDialog/AddOrModifyUserDialog";
import { FoundationError } from "../models/FoundationError";
import { IUserProfile } from "../models/User";
import { BACKEND_URL_V1, deletee, get, post } from "../utils/Api";
import { Auth } from "../utils/Auth";

export interface IGetSettings {
  userProfile: IUserProfile;
}

export interface IGetUsers {
  userProfiles: IUserProfile[];
}

export const getUsers = async (): Promise<IUserProfile[] | FoundationError> => {
  try {
    const response = await get<IGetUsers>(`${BACKEND_URL_V1}/users`);
    if (response.parsedBody?.userProfiles) {
      return response.parsedBody.userProfiles;
    }

    const errorMessage = response.serverError?.error.message ?? "Users could not be retrieved";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const getSettings = async (): Promise<IUserProfile | FoundationError> => {
  try {
    const response = await get<IGetSettings>(`${BACKEND_URL_V1}/users/me/settings`);
    if (response.parsedBody?.userProfile) {
      // for each get setting call, save the latest in auth session
      Auth.getInstance().saveUserSettings(response.parsedBody.userProfile);
      return response.parsedBody.userProfile;
    }

    const errorMessage = response.serverError?.error.message ?? "User settings could not be retrieved";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
};

export const createUser = async (userFormdata: IUserForm): Promise<null | FoundationError> => {

  try {
    const response = await post(
      `${BACKEND_URL_V1}/users`, 
      userFormdata
    );

    if (response.status / 100 === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to create User";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }

}

export const updateUser = async (userFormdata: IUserForm): Promise<null | FoundationError> => {

  try {
    const response = await post(
      `${BACKEND_URL_V1}/users/${userFormdata.id}`,
      userFormdata
    );

    if (response.status / 100 === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to update User";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }

}

export const deleteUser = async (userId: string): Promise<null | FoundationError> => {

  try {
    const response = await deletee(
      `${BACKEND_URL_V1}/users/${userId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to delete User";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }

}
