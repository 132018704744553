import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import RecoveryPage from './pages/recovery/RecoveryPage';
import PrivateRouteWithLayout from './utils/PrivateRouteWithLayout';
import Logout from './pages/logout/logout';
import FacilityCreation from './pages/facilityCreation/FacilityCreation';
import Suppliers from './pages/emissions/suppliers/Suppliers';
import { UserManagement } from './components/userManagement/UserManagement';
import FacilityDetails from './pages/facilityDetails/FacilityDetails';
import Offsetting from './pages/offsetting/Offsetting';
import CreateReport from './pages/reports/createReport/CreateReport';
import ReportDetails from './pages/reports/reportDetails/ReportDetails';
import ReportListing from './pages/reports/reportsListing/ReportListing';
import ProfilePage from './pages/profile/ProfilePage';
import { ActionPlans } from './pages/reduction/actionPlans/ActionPlans';
import GovernanceDashboard from './pages/governanceDashboard/GovernanceDashboard';
import SocialDashboard from './pages/socialDashboard/SocialDashboard';
import { Target } from './pages/targets/Target';
import { ReductionAnalytics } from './pages/reduction/analytics/ReductionAnalytics';
import CreateCustomReport from './pages/reports/createCustomReport/CreateCustomReport';
import SupplierCreation from './pages/supplierCreation/SupplierCreation';
import SupplierDetails from './pages/supplierDetails/SupplierDetails';
import { DataEntryV2 } from './pages/dataEntry/DataEntryV2';
import CompanyEmissionsOverviewV2 from './components/companyEmissionsOverview/CompanyEmissionsOverviewV2';
import FacilityEmissionsOverviewV2 from './components/facilityEmissionOverview/FacilityEmissionOverviewV2';
import { DataCollectionV2 } from './pages/dataCollection/DataCollectionV2';
import EmissionsWithSummaryAndPieChart from './pages/emissions/EmissionsWithPieChart';
import { KpiSection, KpiSectionGroup } from './models/Kpi';
import { CompanyDataProviderV2 } from './contexts/CompanyDataContextV2';
import { FacilitiesDataProvider } from './contexts/FacilityDataContext';
import Electricity from './pages/emissions/electricity/Electricity';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <FacilitiesDataProvider>
                    <CompanyDataProviderV2>

                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/data-entry" element={<PrivateRouteWithLayout path="/data-entry" element={<DataEntryV2 />} />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/recover" element={<RecoveryPage />} />

                            <Route path="/profile" element={<PrivateRouteWithLayout path="/profile" element={<ProfilePage />} />} />
                            <Route path="/users" element={<PrivateRouteWithLayout path="/users" element={<UserManagement />} />} />
                            <Route path="/create-facility" element={<PrivateRouteWithLayout path="/create-facility" element={<FacilityCreation />} />} />
                            <Route path="/facility-details" element={<PrivateRouteWithLayout path="/facility-details" element={<FacilityDetails />} />} />
                            <Route path="/create-supplier" element={<PrivateRouteWithLayout path="/create-supplier" element={<SupplierCreation />} />} />
                            <Route path="/supplier-details" element={<PrivateRouteWithLayout path="/supplier-details" element={<SupplierDetails />} />} />
                            <Route path="/data-collection" element={<PrivateRouteWithLayout path="/data-collection" element={<DataCollectionV2 />} />} />
                            <Route path="/offsetting" element={<PrivateRouteWithLayout path="/offsetting" element={<Offsetting />} />} />

                            <Route path="/reduction">
                                <Route path="" element={<PrivateRouteWithLayout path="/reduction" element={<ReductionAnalytics />} />} />
                                <Route path="plans" element={<PrivateRouteWithLayout path="/reduction/plans" element={<ActionPlans />} />} />
                                <Route path="targets" element={<PrivateRouteWithLayout path="/reduction/targets" element={<Target />} />} />
                            </Route>

                            <Route path="/reports">
                                <Route path="" element={<PrivateRouteWithLayout path="/reports" element={<ReportListing />} />} />
                                <Route path="create" element={<PrivateRouteWithLayout path="/reports/create" element={<CreateReport />} />} />
                                <Route path="custom">
                                    <Route path="" element={<PrivateRouteWithLayout path="/reports/custom" element={<CreateCustomReport />} />} />
                                    <Route path=":reportId" element={<PrivateRouteWithLayout path="/reports/custom/:reportId" element={<CreateCustomReport />} />} />
                                </Route>
                                <Route path=":reportId" element={<PrivateRouteWithLayout path="/reports/:reportId" element={<ReportDetails />} />} />
                            </Route>

                            <Route path="/" element={<PrivateRouteWithLayout path="/E/company-emissions" element={<CompanyEmissionsOverviewV2 />} showESGFilters="E" showDates />} />
                            <Route path="/E">
                                <Route path="" index element={<PrivateRouteWithLayout path="/E/company-emissions" element={<CompanyEmissionsOverviewV2 />} showESGFilters="E" showDates />} />
                                <Route path="company-emissions" index element={<PrivateRouteWithLayout path="/E/company-emissions" element={<CompanyEmissionsOverviewV2 />} showESGFilters="E" showDates />} />
                                <Route path="facility-emissions" index element={<PrivateRouteWithLayout path="/E/facility-emissions" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showDates showFacilityFilter />} />
                                <Route path="diesel" element={<PrivateRouteWithLayout path="/E/diesel" element={<EmissionsWithSummaryAndPieChart section={KpiSection.DIESEL_CONSUMPTION} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="petrol" element={<PrivateRouteWithLayout path="/E/petrol" element={<EmissionsWithSummaryAndPieChart section={KpiSection.PETROL_CONSUMPTION} hidePieChart />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="lpg" element={<PrivateRouteWithLayout path="/E/lpg" element={<EmissionsWithSummaryAndPieChart section={KpiSection.LPG_CONSUMPTION} hidePieChart />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="refrigerants" element={<PrivateRouteWithLayout path="/E/refrigerants" element={<EmissionsWithSummaryAndPieChart section={KpiSection.REFRIGERANTS} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="electricity" element={<PrivateRouteWithLayout path="/E/electricity" element={<Electricity />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="oil" element={<PrivateRouteWithLayout path="/E/oil" element={<EmissionsWithSummaryAndPieChart section={KpiSection.OIL_PRODUCTS} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="coal" element={<PrivateRouteWithLayout path="/E/coal" element={<EmissionsWithSummaryAndPieChart section={KpiSection.COAL_PRODUCTS} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="natural-gas" element={<PrivateRouteWithLayout path="/E/natural-gas" element={<EmissionsWithSummaryAndPieChart section={KpiSection.NATURAL_GAS} hidePieChart />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="biomass" element={<PrivateRouteWithLayout path="/E/biomass" element={<EmissionsWithSummaryAndPieChart section={KpiSection.BIOMASS} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="other-fuels" element={<PrivateRouteWithLayout path="/E/biomass" element={<EmissionsWithSummaryAndPieChart section={KpiSection.OTHER_FUELS} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="business-travel" element={<PrivateRouteWithLayout path="/E/business-travel" element={<EmissionsWithSummaryAndPieChart section={KpiSection.BUSINESS_TRAVEL} pieChartSectionGroup={KpiSectionGroup.BUSINESS_TRAVEL} />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="employee-commute" element={<PrivateRouteWithLayout path="/E/employee-commute" element={<EmissionsWithSummaryAndPieChart section={KpiSection.EMPLOYEE_COMMUTE} pieChartSectionGroup={KpiSectionGroup.EMPLOYEE_COMMUTE} />} showESGFilters="E" showFacilityFilter showDates />} />

                                <Route path="upstream-transportation-distribution" element={<PrivateRouteWithLayout path="/E/upstream-transportation-distribution" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showFacilityFilter />} />
                                <Route path="upstream-leased-assets" element={<PrivateRouteWithLayout path="/E/upstream-leased-assets" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="suppliers" element={<PrivateRouteWithLayout path="/E/suppliers" element={<Suppliers />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="waste" element={<PrivateRouteWithLayout path="/E/waste" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="purchased-goods" element={<PrivateRouteWithLayout path="/E/purchased-goods" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="capital-goods" element={<PrivateRouteWithLayout path="/E/capital-goods" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showFacilityFilter showDates />} />
                                <Route path="fuel-and-energy" element={<PrivateRouteWithLayout path="/E/fuel-and-energy" element={<FacilityEmissionsOverviewV2 />} showESGFilters="E" showFacilityFilter showDates />} />
                            </Route>

                            <Route path="/G" element={<PrivateRouteWithLayout path="/G" element={<GovernanceDashboard />} showESGFilters="G" />} />
                            <Route path="/S" element={<PrivateRouteWithLayout path="/S" element={<SocialDashboard />} showESGFilters="S" />} />
                        </Routes>
                    </CompanyDataProviderV2>
                </FacilitiesDataProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
