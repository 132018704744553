import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./ReportListing.module.css";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import GenericTableWithTitle from "../../../components/genericTable/GenericTableWithTitle";
import { IReportView } from "../../../models/Report";
import { formatTimestampToDateMonthYear } from "../../../utils/DateUtils";
import { Auth } from "../../../utils/Auth";
import { getReports } from "../../../api/Company";
import { FoundationError } from "../../../models/FoundationError";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContext } from "../../../contexts/ToastContext";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteReportConfirmationDialog from "../../../components/deleteReportConfirmationDialog/DeleteReportConfirmationDialog";

const ReportListing: React.FC = () => {
    const [reportsList, setReportsList] = useState<IReportView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDeleteReportDialog, setShowDeleteReportDialog] = useState<IReportView | false>(false);

    const { showToast } = useContext(ToastContext);

    const navigate = useNavigate();
    const queryStr = useLocation().search;

    const fetchReports = useCallback(async (companyId: string) => {
        setIsLoading(true);
        let reportsOrError = await getReports(companyId);
        setIsLoading(false);
        if (reportsOrError instanceof FoundationError) {
            showToast(reportsOrError.getErrorMessage(), "error");
            return;
        }

        setReportsList(reportsOrError.sort((a, b) => {
            if (a.dueDate === b.dueDate) {
                return 0;
            }

            return a.dueDate > b.dueDate ? -1 : 1
        }));
    }, [showToast]);

    const companyId = Auth.getInstance().getUserSettings()?.parentCompanyId;

    useEffect(() => {
        if (companyId) {
            fetchReports(companyId);
        }
    }, [fetchReports, companyId]);

    const handleDeleteReportDialogClose = (didDeleteTask: boolean) => {
        if (didDeleteTask) {
            if (companyId) {
                fetchReports(companyId);
            }
        }
        setShowDeleteReportDialog(false);
    }

    const frameworksForFilter = [...new Set(reportsList.map(report => report.type))];
    const usersForFilter = [...new Set(reportsList.map(report => report.createdByUser.name))];
    const statusForFilter = [...new Set(reportsList.map(report => report.status))];

    return (
        <div className={styles.reportListing_container}>
            {companyId && showDeleteReportDialog && (
                <DeleteReportConfirmationDialog
                    report={showDeleteReportDialog}
                    companyId={companyId}
                    onClose={handleDeleteReportDialogClose}
                />
            )}
            {isLoading && (
                <div className={styles.reportListing_loadingDiv}>
                    <CircularProgress
                        size={30}
                        color={"inherit"}
                    />
                </div>
            )}
            {!isLoading && (
                <Grid container spacing={2} padding={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <GenericTableWithTitle
                            showHeadingRow={true}
                            title={"Reports"}
                            headers={[
                                {
                                    header: "Framework",
                                    filter: frameworksForFilter.length <= 1 ? undefined : {
                                        filterValues: frameworksForFilter,
                                        isValid: (data, selectedFilter) => data.type === selectedFilter
                                    }
                                },
                                { header: "Name" },
                                {
                                    header: "Created By",
                                    filter: usersForFilter.length <= 1 ? undefined : {
                                        filterValues: usersForFilter,
                                        isValid: (data, selectedFilter) => data.createdByUser.name === selectedFilter
                                    }
                                },
                                {
                                    header: "Due Date",
                                    sortByField: "dueDate"
                                },
                                {
                                    header: "Status", filter: statusForFilter.length <= 1 ? undefined : {
                                        filterValues: statusForFilter,
                                        isValid: (data, selectedFilter) => data.status === selectedFilter
                                    }
                                },
                                { header: "Actions" },
                            ]}
                            data={reportsList}
                            cursor={"pointer"}
                            dataRenderer={(data, column) => {
                                switch (column) {
                                    case 'Framework':
                                        return data.type;
                                    case 'Name':
                                        return data.name;
                                    case 'Created By':
                                        return data.createdByUser.name;
                                    case 'Due Date':
                                        return formatTimestampToDateMonthYear(data.dueDate);
                                    case 'Status':
                                        return data.status;
                                    case 'Actions':
                                        return (
                                            <IconButton
                                                aria-label="delete"
                                                color="primary"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowDeleteReportDialog(data);
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        );
                                }
                            }}
                            onRowClick={(report) => {
                                navigate({
                                    pathname: `/reports/${report.id}`,
                                    search: queryStr,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default ReportListing;
