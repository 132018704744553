import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { TableWithPieOrBarChart } from '../tableWithPieOrBarChart/TableWithPieOrBarChart';
import { GenericSummaryComponent } from '../companyEmissionsOverview/summary/GenericSummaryComponent';
import { CompanyDataContextV2 } from '../../contexts/CompanyDataContextV2';
import { IFacilityEmissionsV2 } from '../../models/CompanyEmissionsV2';
import { IKeyValuePair } from '../genericPieChart/GenericPieChart';
import FacilityEmissionsOverTime from '../facilityEmissionsOverTime/FacilityEmissionsOverTime';


function getSummaryData(facilityEmissions: IFacilityEmissionsV2 | undefined) {
    if (facilityEmissions === undefined) {
        return [];
    }

    const lastMonthEmissions = facilityEmissions.monthlyScopeWiseEmissions.at(facilityEmissions.monthlyScopeWiseEmissions.length - 1);
    const secondLastMonthEmissions = facilityEmissions.monthlyScopeWiseEmissions.at(facilityEmissions.monthlyScopeWiseEmissions.length - 2);

    let emissionsSinceLastMonth = 0;
    if (lastMonthEmissions && secondLastMonthEmissions) {
        const totalLastMonthEmissions = (lastMonthEmissions.scopeEmissionsMap.SCOPE_1 ?? 0) + (lastMonthEmissions.scopeEmissionsMap.SCOPE_2 ?? 0) + (lastMonthEmissions.scopeEmissionsMap.SCOPE_3 ?? 0);
        const totalSecondLastMonthEmissions = (secondLastMonthEmissions.scopeEmissionsMap.SCOPE_1 ?? 0) + (secondLastMonthEmissions.scopeEmissionsMap.SCOPE_2 ?? 0) + (secondLastMonthEmissions.scopeEmissionsMap.SCOPE_3 ?? 0);
        emissionsSinceLastMonth = totalLastMonthEmissions - totalSecondLastMonthEmissions;
    }

    return [
        [
            { key: "Facility Emissions", value: facilityEmissions.totalEmissions, unit: " tCO2" },
        ],
        [
            { key: "Since last month", value: emissionsSinceLastMonth, unit: " tCO2" },
        ],
        [
            { key: "Facility Size", value: facilityEmissions.facility.spaceInMeterSquare, unit: " m2" },
            { key: "Number of Employees", value: facilityEmissions.facility.numberOfEmployees }
        ]
    ];
}

function getSectionEmissions(facilityEmissions: IFacilityEmissionsV2 | undefined) {
    const chartDataTotal: IKeyValuePair[] = [];
    const chartDataPerEmployee: IKeyValuePair[] = [];
    if (facilityEmissions === undefined) {
        return { chartDataTotal, chartDataPerEmployee };
    }

    const employeeCount = facilityEmissions.facility.numberOfEmployees;
    facilityEmissions.sectionEmissions.forEach((sectionEmissions) => {
        chartDataTotal.push({ key: sectionEmissions.sectionTitle, value: sectionEmissions.totalEmissions });
        chartDataPerEmployee.push({ key: sectionEmissions.sectionTitle, value: sectionEmissions.totalEmissions / employeeCount });
    });

    return { chartDataTotal, chartDataPerEmployee };
}
const colors = ['#32E7A4', '#64B5FC', '#155EA2', '#0069E8', '#5B3A96', '#CE1D26', '#FFA600', '#7FC91B', '#04B1B4', '#FCEA64', '#CECECE', '#8D6BEB', '#878787'];

const FacilityEmissionsOverviewV2: React.FC = React.memo(() => {
    const {
        isLoadingCompanyEmissions,
        selectedDateRange,
        selectedFacilityEmissions,
    } = useContext(CompanyDataContextV2);

    const [emissionsTab, setEmissionsTab] = useState<"total" | "perEmployee">("total");
    const summaryData = getSummaryData(selectedFacilityEmissions);
    const sectionEmissions = getSectionEmissions(selectedFacilityEmissions);

    return (
        <Grid container spacing={2} paddingX={4} paddingBottom={4}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <GenericSummaryComponent
                    minHeight={323}
                    selectedDateRange={selectedDateRange}
                    isLoading={isLoadingCompanyEmissions}
                    data={summaryData}
                />
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
                <TableWithPieOrBarChart
                    tabs={["Total", "Per Employee"]}
                    defaultTab={emissionsTab === "total" ? "Total" : "Per Employee"}
                    isStriped
                    showLegendColors
                    showActionButton
                    showInfoIcon
                    colors={colors}
                    isLoading={isLoadingCompanyEmissions}
                    title={"Facility CO2 Emissions"}
                    showHeadingRow
                    headers={["", "tCO2e"].map((header) => { return { header } })}
                    data={emissionsTab === "total" ? sectionEmissions.chartDataTotal : sectionEmissions.chartDataPerEmployee}
                    dataRenderer={(data, column) => {
                        switch (column) {
                            case "":
                                return data.key;
                            case "tCO2e":
                                return data.value;
                        }
                    }}
                    maxHeight={"300px"}
                    chartType={"pie"}
                    pieChartProps={{
                        data: emissionsTab === "total" ? sectionEmissions.chartDataTotal : sectionEmissions.chartDataPerEmployee,
                        unit: "tCO2e",
                        dataKey: "value",
                        toolTipLabelFormatter: (value, _, percent) => `${value} tCO2e (${percent}%)`,
                        minWidth: 330,
                        minHeight: 330,
                        legendLabelKey: "key",
                        toolTipLabelKey: "key",
                    }}
                    onTabChange={(category) => {
                        switch (category) {
                            case 'Total':
                                setEmissionsTab("total");
                                return sectionEmissions.chartDataTotal;
                            case 'Per Employee':
                                setEmissionsTab("perEmployee");
                                return sectionEmissions.chartDataPerEmployee;
                            default:
                                return [];
                        }
                    }}
                />
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
                <FacilityEmissionsOverTime
                    isLoadingCompanyEmissions={isLoadingCompanyEmissions}
                    selectedFacilityEmissions={selectedFacilityEmissions}
                />
            </Grid>

        </Grid>
    );
});

export default FacilityEmissionsOverviewV2;
