import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getProcessedCompanyDataV2 } from "../api/Company";
import { FoundationError } from "../models/FoundationError";
import { formatTimestampToMonthYear, getDateFromMonthYear, getMonthsDifference } from "../utils/DateUtils";
import { ICompanyEmissionsV2, IFacilityEmissionsV2 } from "../models/CompanyEmissionsV2";
import { Auth } from "../utils/Auth";
import { IFacility } from "../models/Facility";
import { ToastContext } from "./ToastContext";

interface IDateRange {
    start: Date;
    end: Date;
}

export interface ICompanyDataV2 {
    isLoadingCompanyEmissions: boolean;
    companyEmissions: ICompanyEmissionsV2 | undefined;
    selectedDateRange: IDateRange | undefined;
    handleDateRangeChange: (start: Date, end: Date) => void;
    facilities: IFacility[];
    selectedFacility: IFacility | undefined;
    selectFacility: (facilityId: string) => void;
    selectedFacilityEmissions: IFacilityEmissionsV2 | undefined;
    refreshCompanyEmissions: () => void;
}

export const CompanyDataContextV2 = createContext<ICompanyDataV2>(undefined!);

export const CompanyDataProviderV2: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const companyId = Auth.getInstance().isLoggedIn() ? Auth.getInstance().getUserSettings()!.companyView.id : "";
    const facilityId = searchParams.get("facility") as string | undefined;
    const startDateString = searchParams.get("startDate") as string | undefined;
    const endDateString = searchParams.get("endDate") as string | undefined;

    const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>();
    const [companyEmissions, setCompanyEmissions] = useState<ICompanyEmissionsV2>();
    const [isLoadingCompanyEmissions, setIsLoadingCompanyEmissions] = useState(false);
    const [facilities, setFacilities] = useState<IFacility[]>([]);
    const [selectedFacility, setSelectedFacility] = useState<IFacility>();
    const [selectedFacilityEmissions, setSelectedFacilityEmissions] = useState<IFacilityEmissionsV2>();

    const { showToast } = useContext(ToastContext);

    const setSearchParameter = useCallback((key: string, value: string | null) => {
        if (value === null) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const fetchCompanyEmissionsData = useCallback(async (start: Date, end: Date) => {
        if (!Auth.getInstance().isLoggedIn()) {
            return;
        }

        setIsLoadingCompanyEmissions(true);
        const companyEmissions = await getProcessedCompanyDataV2(companyId, start, end);
        setIsLoadingCompanyEmissions(false);

        if (companyEmissions instanceof FoundationError) {
            showToast(companyEmissions.message, "error");
            return;
        }

        setCompanyEmissions(companyEmissions);
    }, [companyId, showToast]);

    useEffect(() => {
        if (!selectedDateRange || selectedDateRange.start === null || selectedDateRange.end === null) {
            return;
        }

        fetchCompanyEmissionsData(selectedDateRange.start, selectedDateRange.end);
    }, [selectedDateRange, fetchCompanyEmissionsData]);

    useEffect(() => {
        if (companyEmissions === undefined) {
            return;
        }

        const facilitesInCompanyEmissions = companyEmissions.facilityEmissions
            .map((facilityEmissions) => facilityEmissions.facility)
            .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));

        if (facilitesInCompanyEmissions.length > 0) {
            setFacilities(facilitesInCompanyEmissions);
            const facilityFromUrl = facilitesInCompanyEmissions.find((facility) => facility.id === facilityId);
            if (facilityFromUrl) {
                setSelectedFacility(facilityFromUrl);
            } else {
                setSelectedFacility(facilitesInCompanyEmissions[0]);
            }
        }
    }, [companyEmissions, facilityId]);

    const handleDateRangeChange = useCallback((start: Date, end: Date) => {

        if (start.getTime() > end.getTime()) {
            showToast("Start date cannot be after end date", "error");
            return;
        }

        if (getMonthsDifference(start, end) > 18) {
            showToast("Maximum date range is 1.5 years", "error");
            return;
        }

        const startYearMonth = formatTimestampToMonthYear(start.getTime());
        const endYearMonth = formatTimestampToMonthYear(end.getTime());
        if (selectedDateRange && selectedDateRange.start !== null && selectedDateRange.end !== null) {
            const selectedDateStartYearMonth = formatTimestampToMonthYear(selectedDateRange.start.getTime());
            const selectedDateEndYearMonth = formatTimestampToMonthYear(selectedDateRange.end.getTime());
            if (selectedDateStartYearMonth === startYearMonth && selectedDateEndYearMonth === endYearMonth) {
                return;
            }
        }

        setSearchParameter("startDate", startYearMonth);
        setSearchParameter("endDate", endYearMonth);
        setSelectedDateRange({
            start: start,
            end: end
        });
    }, [setSearchParameter, showToast, selectedDateRange]);

    useEffect(() => {
        if (startDateString && endDateString) {
            let start = getDateFromMonthYear(startDateString);
            let end = getDateFromMonthYear(endDateString);

            if (!selectedDateRange || selectedDateRange.start === null || selectedDateRange.end === null) {
                handleDateRangeChange(start, end);
            } else if (start.getTime() !== selectedDateRange.start.getTime() && end.getTime() !== selectedDateRange.end.getTime()) {
                handleDateRangeChange(start, end);
            }
        }
    }, [selectedDateRange, startDateString, endDateString, handleDateRangeChange]);

    useEffect(() => {
        if (selectedFacility === undefined || companyEmissions === undefined) {
            return;
        }

        const facilityEmission = companyEmissions.facilityEmissions.find((facilityEmission) => facilityEmission.facility.id === selectedFacility.id);
        setSelectedFacilityEmissions(facilityEmission);
    }, [selectedFacility, companyEmissions]);

    const selectFacility = (facilityId: string) => {
        setSearchParameter("facility", facilityId);
        const facility = facilities.find((facility) => facility.id === facilityId);
        setSelectedFacility(facility);
    }

    const refreshCompanyEmissions = () => {
        if (!selectedDateRange || selectedDateRange.start === null || selectedDateRange.end === null) {
            return;
        }

        fetchCompanyEmissionsData(selectedDateRange.start, selectedDateRange.end);
    }

    return (
        <CompanyDataContextV2.Provider
            value={{
                isLoadingCompanyEmissions,
                selectedDateRange,
                companyEmissions,
                handleDateRangeChange,
                facilities,
                selectedFacility,
                selectFacility,
                selectedFacilityEmissions,
                refreshCompanyEmissions
            }}
        >
            {children}
        </CompanyDataContextV2.Provider >
    );
}
