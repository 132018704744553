import { useState } from "react";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts";
import style from "./GenericPieChart.module.css";
import { toFixed } from "../../utils/Utils";

export interface IGenericPieChartProps {
    data: IKeyValuePair[];
    dataKey: string;
    toolTipLabelKey?: string;
    innerRadius?: number;
    minHeight?: number;
    minWidth?: number;
    colors?: string[];
    displayLabelFormatter?: (index: number) => string;
    toolTipLabelFormatter?: (value: string, index: number, percent: string) => string;
    hoverIndex?: number;
    setHoverIndex?: (index?: number) => void;
    toolTipEnabled?: boolean
    legendLabelKey?: string;
    showLegend?: boolean;
    unit?: string
}

export interface IKeyValuePair {
    key: string;
    value: number | undefined;
}

function GenericPieChart(props: IGenericPieChartProps) {
    const {
        data,
        dataKey,
        toolTipLabelKey,
        hoverIndex,
        colors,
        minHeight = 200,
        minWidth = 200,
        setHoverIndex,
        toolTipLabelFormatter = (value, _) => value,
        displayLabelFormatter,
        toolTipEnabled = true,
        showLegend = false,
        legendLabelKey,
        unit = ""
    } = props;
    const [pieChartActiveIndex, setPieChartActiveIndex] = useState<number>();

    const totalValue = data.reduce((accumulator, object) => {
        return accumulator + (object.value ?? 0);
    }, 0);

    const innerRadius = totalValue ? 60 : 0

    const pieColors = colors ?? [
        '#004395',
        '#3487EC',
        '#67A5F1',
        '#5B3A96',
        '#BB308A',
        '#F64663',
        '#FFA600',
        '#F07111',
        '#E14C1D',
        '#CE1D26'
    ];
    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = (props: {
        x: number,
        cx: number,
        cy: number,
        midAngle: number,
        innerRadius: number,
        outerRadius: number,
        percent: number,
        index: number
    }) => {
        const { cx, cy, midAngle, x, outerRadius, innerRadius, percent, index } = props;
        let multiplier = 0.5;
        if (innerRadius < 40) {
            multiplier = 0.2;
        }
        const radius = innerRadius + (outerRadius - innerRadius) * multiplier;
        const xAxis = cx + radius * Math.cos(-midAngle * RADIAN);
        const yAxis = cy + radius * Math.sin(-midAngle * RADIAN);
        const percentage = percent * 100;

        return (
            <>
                {percent !== 0 && (
                    <text
                        x={xAxis}
                        y={yAxis}
                        fill="black"
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                        className={style.GenericPieChart__percentageText}
                    >
                        {displayLabelFormatter ? displayLabelFormatter(index) : (percentage > 3) ? `${toFixed(percentage, 0)}%` : ""}
                    </text>
                )}
            </>
        );
    };

    const renderActiveShape = (props: any) => {
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill
        } = props;

        return (
            <>
                <g>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 1}
                        outerRadius={outerRadius + 12}
                        fill={fill}
                        opacity={0.3}
                    />
                </g>
            </>
        );
    };

    const total = data
        .map(keyValue => keyValue.value)
        .reduce((a, b) => (a ?? 0) + (b ?? 0))
        ?? 0;

    const CustomTooltip = (data: any) => {
        if (pieChartActiveIndex === undefined && hoverIndex === undefined) {
            return null;
        }

        if (data.active && data.payload) {
            return (
                <div className={`${style.GenericPieChart__tooltipMainContainer} recharts-custom-tooltip`}>
                    <div>
                        {data.payload.map((i: any, index: number) => {
                            const percent = toFixed(((i.value as number) * 100) / total, 0);
                            return (
                                <div key={index} className={style.GenericPieChart__toolTipContainer}>
                                    <div
                                        className={style.GenericPieChart__toolTipContainerColor}
                                        style={{ backgroundColor: pieColors[i.name] }}
                                    />
                                    {toolTipLabelKey && (
                                        <h4>
                                            {i[toolTipLabelKey]}
                                        </h4>
                                    )}
                                    <div>
                                        {toolTipLabelFormatter(toFixed((i.value as number)), i.name, percent)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderCusomizedLegend = (props: any) => {
        const { payload } = props

        return (
            <div className={style.GenericPieChart__legendContainer}>
                {
                    payload.map((entry: any, index: number) => {
                        const { payload } = entry
                        return (
                            <div key={`legend-${index}`} className={style.GenericPieChart__legend} style={{ color: payload.fill }}>
                                <div>{payload[`${legendLabelKey}`]}</div>
                                <div>{payload.value}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <ResponsiveContainer width="100%" minHeight={minHeight} minWidth={minWidth}>
            <PieChart>
                <Pie
                    isAnimationActive={false}
                    activeIndex={hoverIndex ?? pieChartActiveIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    innerRadius={innerRadius}
                    labelLine={false}
                    paddingAngle={0}
                    label={renderCustomizedLabel}
                    dataKey={dataKey}
                    onMouseEnter={(_, index) => {
                        if (setHoverIndex) {
                            setHoverIndex(index);
                        }

                        setPieChartActiveIndex(index);
                    }}
                    onMouseOut={() => {
                        if (setHoverIndex) {
                            setHoverIndex(undefined);
                        }

                        setPieChartActiveIndex(undefined);
                    }}
                >
                    {data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                    ))}
                    {totalValue && (
                        <Label width={30} position="center"
                            content={<CustomLabel value={toFixed(totalValue, 1)} unit={unit} />}>
                        </Label>
                    )}
                </Pie>

                {toolTipEnabled && (
                    <Tooltip content={CustomTooltip} wrapperStyle={{ left: 10, top: 10, padding: "0px" }} />
                )}

                {showLegend && (
                    <Legend content={renderCusomizedLegend} />
                )}
            </PieChart>
        </ResponsiveContainer>
    );
}


const CustomLabel = (props: any) => {
    const { viewBox, value, unit } = props
    const { cx, cy } = viewBox;
    return (
        <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            <tspan x={cx} dy={-10} fontSize={value.toString().length < 7 ? 24 : 20}>{value}</tspan>
            <tspan x={cx} dy={20} fontSize={value.toString().length < 7 ? 14 : 11}>{unit}</tspan>
        </text>
    )
}

export default GenericPieChart;
