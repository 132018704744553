export interface IFoundationServerError {
    error: IFoundationServerErrorDetails;
}

export interface IFoundationServerErrorDetails {
    message: string;
    errorCode?: ErrorCode;
}

export enum ErrorCode {
    ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND"
}
