import { Grid } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenericSummaryComponent } from '../../../components/companyEmissionsOverview/summary/GenericSummaryComponent';
import GenericChartsWithTitle from "../../../components/genericChartsWithTitle/GenericChartsWithTitle";
import GenericTableWithTitle from "../../../components/genericTable/GenericTableWithTitle";

const Suppliers: React.FC = React.memo(() => {

    const summaryData = [
        [{ key: "All suppliers", value: 61.35, unit: "kg" },
        { key: "Since last month", value: 3.25, unit: "kg" },
        { key: "Number of suppliers", value: 30 },
        { key: "Suppliers as percent of total carbon emissions", value: 60.15, unit: "%" }]
    ];

    const headers = ["IT Equipment", "Facility Managers", "Travel Agents", "Hosted Servers", "Catering Services", "Telecom Services"]
    const headerLowSup = ["Name", "Value"]

    const colors = [
        "#64B5FC",
        "#FCEA64",
        "#8D6BEB",
        "#155EA2",
        "#32E7A4",
        "#C2B484"
    ]

    let lineChartData = [
        { key: "Jan", [headers[0]]: 25, [headers[1]]: 45, [headers[2]]: 25, [headers[3]]: 45, [headers[4]]: 25, [headers[5]]: 56 },
        { key: "Feb", [headers[0]]: 34, [headers[1]]: 27, [headers[2]]: 68, [headers[3]]: 32, [headers[4]]: 10, [headers[5]]: 34 },
        { key: "Mar", [headers[0]]: 78, [headers[1]]: 64, [headers[2]]: 34, [headers[3]]: 28, [headers[4]]: 1, [headers[5]]: 22 },
        { key: "Apr", [headers[0]]: 34, [headers[1]]: 32, [headers[2]]: 12, [headers[3]]: 34, [headers[4]]: 34, [headers[5]]: 45 },
        { key: "May", [headers[0]]: 9, [headers[1]]: 12, [headers[2]]: 56, [headers[3]]: 44, [headers[4]]: 36, [headers[5]]: 58 },
        { key: "Jun", [headers[0]]: 15, [headers[1]]: 0, [headers[2]]: 67, [headers[3]]: 23, [headers[4]]: 45, [headers[5]]: 43 },
        { key: "Jul", [headers[0]]: 74, [headers[1]]: 23, [headers[2]]: 23, [headers[3]]: 16, [headers[4]]: 34, [headers[5]]: 12 },
        { key: "Aug", [headers[0]]: 56, [headers[1]]: 45, [headers[2]]: 12, [headers[3]]: 56, [headers[4]]: 32, [headers[5]]: 36 },
    ]

    let lowSuppliersData = [
        { name: "Supplier 1", value: 134 },
        { name: "Supplier 2", value: 200 },
        { name: "Supplier 3", value: 25.7 },
        { name: "Supplier 4", value: 423 },
        { name: "Supplier 5", value: 167 }
    ]

    let highSuppliersData = [
        { name: "Supplier 1", value: 2134 },
        { name: "Supplier 2", value: 5780 },
        { name: "Supplier 3", value: 573 },
        { name: "Supplier 4", value: 897 },
        { name: "Supplier 5", value: 2456 }
    ]

    let navigate = useNavigate();
    const queryStr = useLocation().search;

    return (
        <div>
            <Grid container spacing={2} paddingX={4} paddingBottom={4}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <GenericSummaryComponent
                        minHeight={220}
                        isLoading={false}
                        unit={"CO2e"}
                        data={summaryData}
                        firstButtonText={"Offset Emissions"}
                        secondButtonText={"Take action"}
                        firstButtonActionOnClick={() => {
                            navigate({
                                pathname: `/offsetting`,
                                search: queryStr
                            });
                        }}
                        secondButtonActionOnClick={() => {
                            navigate({
                                pathname: `/reduction/plans`,
                                search: queryStr
                            });
                        }}
                    />

                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <GenericChartsWithTitle
                        title={"Emissions over time"}
                        minHeight={220}
                        showActionButton
                        chartType={"line"}
                        colors={colors}
                        isLoading={false}
                        lineChartProps={{
                            data: lineChartData,
                            xAxisDataKey: "key",
                            yAxisDataKeys: headers,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} paddingX={4}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <GenericTableWithTitle
                        title={"Lowest emitting suppliers"}
                        headers={headerLowSup.map((header) => { return { header } })}
                        data={lowSuppliersData}
                        maxHeight="400px"
                        dataRenderer={(data, column) => {
                            switch (column) {
                                case 'Name':
                                    return data.name;
                                case 'Value':
                                    return data.value;
                            }
                        }}
                        hover={false}
                    />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <GenericTableWithTitle
                        title={"Highest emitting suppliers"}
                        headers={headerLowSup.map((header) => { return { header } })}
                        data={highSuppliersData}
                        maxHeight="400px"
                        dataRenderer={(data, column) => {
                            switch (column) {
                                case 'Name':
                                    return data.name;
                                case 'Value':
                                    return data.value;
                            }
                        }}
                        hover={false}
                    />
                </Grid>
            </Grid>
        </div >
    );
});

export default Suppliers;
