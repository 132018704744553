import { IUserProfile } from "./User";

export enum QuestionType {
    MCQ_MULTI = "MCQ_MULTI",
    MCQ_SINGLE = "MCQ_SINGLE",
    ONE_LINE = "ONE_LINE",
    MULTI_LINE = "MULTI_LINE",
    TABULAR = "TABULAR",
    NUMBER_WHOLE = "NUMBER_WHOLE",
    NUMBER_DECIMAL = "NUMBER_DECIMAL"
}

export enum ReportType {
    ESG = "ESG",
    BRSR = "BRSR",
    TCFD = "TCFD",
    CUSTOM = "CUSTOM"
}

export enum ReportStatus {
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ARCHIVED = "ARCHIVED"
}

export interface IReportTableHeader {
    title: string;
    rowSpan?: number;
    colSpan?: number;
}

export interface IReportTableData {
    headers: IReportTableHeader[][];
    rowTitles: string[];
}

export interface IQuestionAndAnswer {
    questionId: string;
    type: QuestionType;
    question: string;
    required: boolean;
    choices?: string[];
    answer?: string | string[] | string[][];
    tableData?: IReportTableData;
    infoMark?: string;
}

export interface ICustomReportTemplate {
    templateName: string;
    reportData: IReportDataPost;
    isSubmitted: boolean;
}

export interface ISectionPost {
    sectionId: string;
    title: string;
    questions: IQuestionAndAnswer[];
}

export interface IReportDataPost {
    sections: ISectionPost[];
}

export interface ISection {
    sectionId: string;
    title: string;
    questionAndAnswers: IQuestionAndAnswer[];
}

export interface IReportData {
    sections: ISection[];
}

export interface IAnswer {
    questionId: string;
    answer: string | string[] | string[][];
}

export interface IReportView {
    id: string;
    name: string;
    createdByUser: IUserProfile;
    creationTimestamp: number;
    completedByUser: IUserProfile;
    completionTimestamp: number;
    customReportTemplateId?: string,
    dueDate: number;
    type: ReportType;
    status: ReportStatus;
    year: number;
    reportData: IReportData;
}

export interface ICustomReportTemplateView {
    id: string;
    templateName: string;
    createdByUserId: string;
    companyId: string;
    creationTime: number;
    isSubmitted: boolean;
}

export interface ICustomReportTemplateViewWithData {
    id: string;
    templateName: string;
    createdByUserId: string;
    companyId: string;
    creationTime: number;
    isSubmitted: boolean;
    reportData: IReportDataPost;
}

export interface ICreateReportRequest {
    name: string;
    dueDate: Date;
    year: number;
    customReportTemplateId?: string;
}

export interface IQuestionIdToAnswer {
    [questionId: string]: string | string[] | string[][];
}

export interface ISectionIdToQuestionIdToAnswer {
    [sectionId: string]: IQuestionIdToAnswer;
}
