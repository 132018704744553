import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { FoundationThemeContext } from "../../../contexts/FoundationThemeContext";
import styles from "./CreateReport.module.css";
import CreateReportDialog from "../../../components/createReportDialog/CreateReportDialog";
import { ICustomReportTemplateView, ReportType } from "../../../models/Report";
import { Auth } from "../../../utils/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmissionsReport } from "../../../api/Company";
import { FoundationError } from "../../../models/FoundationError";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { getCustomReportTemplateDetails } from "../../../api/CustomReport";
import { ToastContext } from "../../../contexts/ToastContext";

const CreateReport: React.FC = () => {

    const { formatESGIcon, formatAnnualReportIcon, customReportIcon, brsrReportIcon, tcfdReportIcon } = useContext(
        FoundationThemeContext
    );

    const [selectedReportTemplateType, setSelectedReportTemplateType] = useState<ReportType>();
    const [isFetchingCompanyReport, setIsFetchingCompanyReport] = useState(false);
    const [companyId, setCompanyId] = useState<string>();
    const [showDatePickerForAnnualReport, setShowDatePickerForAnnualReport] = useState(false);
    const [customReportTemplateDetails, setCustomReportTemplateDetails] = useState<ICustomReportTemplateView[]>([]);
    const [selectedCustomReportTemplateId, setSelectedCustomReportTemplateId] = useState<string>();
    const [isCreateCustomReportDialogOpen, setIsCreateCustomReportDialogOpen] = useState(false);

    const { showToast } = useContext(ToastContext);

    const navigate = useNavigate();
    const queryStr = useLocation().search;

    const requestAnnualCompanyReport = async (date: Date) => {
        setShowDatePickerForAnnualReport(false);
        setIsFetchingCompanyReport(true);
        const year = date.getFullYear();
        const response = await getEmissionsReport(companyId!, year);
        setIsFetchingCompanyReport(false,);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
    }

    const fetchCustomReportTemplateDetails = useCallback(async () => {
        const response = await getCustomReportTemplateDetails();

        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        setCustomReportTemplateDetails(response);
    }, [showToast]);

    useEffect(() => {
        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings) {
            setCompanyId(userSettings.parentCompanyId);
        }
    }, []);

    useEffect(() => {
        fetchCustomReportTemplateDetails();
    }, [fetchCustomReportTemplateDetails])

    const showCustomReportCreationDialog = (templateId: string) => {
        setSelectedCustomReportTemplateId(templateId);
        setIsCreateCustomReportDialogOpen(true);
    }

    return (
        <div className={styles.reportCreationContainer} >
            <div className={styles.reportCreation_heading}>
                Select a Framework
            </div>
            <div className={styles.reportCreation_desc}>
                One can select a framework to report based on the stakeholder requirement.
                A customized report could be fetched and shared with the stakeholders.
            </div>

            {selectedReportTemplateType && companyId && (
                <CreateReportDialog
                    companyId={companyId}
                    open={true}
                    onClose={() => setSelectedReportTemplateType(undefined)}
                    type={selectedReportTemplateType}
                    onNewReportCreated={(report) => {
                        setSelectedReportTemplateType(undefined);
                        navigate({
                            pathname: `/reports/${report.id}`,
                            search: queryStr,
                        });
                    }}
                />
            )}

            <Grid container spacing={1} padding={1}>
                <Grid item xs={3}>
                    <div style={{ display: "none" }}>
                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <MobileDatePicker
                                open={showDatePickerForAnnualReport}
                                views={['year']}
                                openTo="year"
                                inputFormat="yyyy"
                                value={null}
                                toolbarPlaceholder={"Select Year"}
                                toolbarTitle={"Select Year for Annual Report"}
                                disableFuture
                                closeOnSelect={true}
                                onChange={() => { }}
                                onAccept={(date: Date | null) => {
                                    if (date) {
                                        requestAnnualCompanyReport(date);
                                    }
                                }}
                                onClose={() => setShowDatePickerForAnnualReport(false)}
                                renderInput={(params) =>
                                    <TextField
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        {...params}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </div>
                    <Button
                        className={styles.reportingFormatSelectionButton}
                        disabled={isFetchingCompanyReport}
                        onClick={() => setShowDatePickerForAnnualReport(true)}
                    >
                        {isFetchingCompanyReport && (<CircularProgress size={25} color={"inherit"} />)}
                        {!isFetchingCompanyReport && (<img src={formatAnnualReportIcon} alt="" className={styles.reportingFormat_img} />)}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={3}>
                    <Button
                        className={styles.reportingFormatSelectionButton}
                        onClick={() => setSelectedReportTemplateType(ReportType.ESG)}
                    >
                        <img src={formatESGIcon} alt="" className={styles.reportingFormat_img} />
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        className={styles.reportingFormatSelectionButton}
                        onClick={() => setSelectedReportTemplateType(ReportType.BRSR)}
                    >
                        <img src={brsrReportIcon} alt="" className={styles.reportingFormat_img} />
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        className={styles.reportingFormatSelectionButton}
                        onClick={() => setSelectedReportTemplateType(ReportType.TCFD)}
                    >
                        <img src={tcfdReportIcon} alt="" className={styles.reportingFormat_img} />
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={3}>
                    <Button
                        className={styles.reportingFormatSelectionButton}
                        onClick={() => {
                            navigate({
                                pathname: `/reports/custom`,
                                search: queryStr,
                            });
                        }}
                    >
                        <img src={customReportIcon} alt="" className={styles.reportingFormat_img} />
                    </Button>
                </Grid>
            </Grid>

            <div>
                <h3 style={{ textAlign: "left" }}>Custom Templates:</h3>
                <Grid container spacing={2} padding={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 6, lg: 6 }}>
                    {customReportTemplateDetails.map((reportDetail, index) =>
                        <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
                            <Button
                                key={`${reportDetail.templateName}_${reportDetail.creationTime}`}
                                className={styles.customTemplateSelectionButton}
                                onClick={() => {
                                    if (reportDetail.isSubmitted) {
                                        showCustomReportCreationDialog(reportDetail.id);
                                    } else {
                                        navigate({
                                            pathname: `/reports/custom/${reportDetail.id}`,
                                            search: queryStr,
                                        });
                                    }
                                }}
                            >
                                {reportDetail.templateName ? reportDetail.templateName : "Default"}
                            </Button>
                        </Grid>
                    )}
                    {isCreateCustomReportDialogOpen && selectedCustomReportTemplateId && companyId && (
                        <CreateReportDialog
                            companyId={companyId}
                            open={true}
                            onClose={() => setIsCreateCustomReportDialogOpen(false)}
                            type={ReportType.CUSTOM}
                            customReportTemplateId={selectedCustomReportTemplateId}
                            onNewReportCreated={(report) => {
                                setIsCreateCustomReportDialogOpen(false);
                                navigate({
                                    pathname: `/reports/${report.id}`,
                                    search: queryStr,
                                });
                            }}
                        />
                    )}
                </Grid>
            </div>
        </div>
    );
}

export default CreateReport;
