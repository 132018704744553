import { Autocomplete, Button, TextField } from "@mui/material";
import { useCallback, useState, useContext } from "react";
import { getFacilityUploadTemplateV2, uploadFacilityDataV2 } from "../../api/Facility";
import styles from "./DataCollection.module.css";
import { useDropzone } from "react-dropzone";
import { Loading } from "../../components/loading/Loading";
import { FoundationError } from "../../models/FoundationError";
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { addMonthsToDate } from "../../utils/DateUtils";
import { FacilitiesDataContext } from "../../contexts/FacilityDataContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import GenericDialog from "../../components/genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";

export const DataCollectionV2: React.FC = () => {

    const { facilities } = useContext(FacilitiesDataContext);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isApiIntegrationModalShowing, setIsApiIntegrationModalShowing] = useState(false);
    const [isDownloading, setDownloading] = useState(false);
    const [facilityId, setFacilityId] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [startDate, setStartDate] = useState<Date>(
        addMonthsToDate(new Date(), -5)
    );

    const navigate = useNavigate();

    const [endDate, setEndDate] = useState<Date>(
        new Date()
    );

    const { apiIntegration } = useContext(
        FoundationThemeContext
    );

    const handleStartDateChange = (newDate: Date | null) => {
        setStartDate(newDate ? newDate : new Date());
    };

    const handleEndDateChange = (newDate: Date | null) => {
        setEndDate(newDate ? newDate : new Date());
    };

    const { showToast } = useContext(ToastContext);

    const handleDownloadTemplate = async () => {
        setDownloading(true);
        const error = await getFacilityUploadTemplateV2(
            facilityId,
            startDate,
            endDate
        );
        setDownloading(false);
        if (error) {
            const errorMessage = error.message ?? "Something went wrong!";
            showToast(errorMessage, "error");
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const handleUploadFile = useCallback(async (files: File[]) => {
        setSubmitting(true);
        const response = await uploadFacilityDataV2(files, facilityId, startDate, endDate);
        setSubmitting(false);
        if (response instanceof FoundationError) {
            const errorMessage = response.message ?? "Something went wrong!";
            showToast(errorMessage, "error");
            return;
        } else {
            showToast("Facility Data added successfully!", "success");
            if (!searchParams.has("refresh")) {
                searchParams.set("refresh", "1");
                setSearchParams(searchParams);
            }
        }
    }, [facilityId, startDate, endDate, showToast, searchParams, setSearchParams]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            handleUploadFile(acceptedFiles);
        },
        [handleUploadFile]
    );

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'application/vnd.ms-excel': ['.xls', '.xlsx']
        }
    });

    const goToManualDataEntryPage = () => {
        navigate({
            pathname: `/data-entry`,
            search: `?${searchParams.toString()}`
        })
    }

    const toggleApiIntegrationModal = () => {
        setIsApiIntegrationModalShowing(!isApiIntegrationModalShowing);
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.inputs}>
                    <Autocomplete
                        options={facilities}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) => {
                            if (value === undefined) {
                                setFacilityId("")
                                setIsDisabled(true)
                            }
                            else {
                                setFacilityId(value?.id ? value.id : " ");
                                setIsDisabled(false)
                            }
                        }}
                        defaultValue={null}
                        fullWidth
                        classes={{
                            root: styles.autocompleteRoot,
                            input: styles.autocomplete,
                        }}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={styles.inputField}
                                label="Select Facility"
                                variant="outlined"
                                required
                            />
                        )}
                    />

                    <div className={styles.dateRange}>
                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <MobileDatePicker
                                views={['year', 'month']}
                                inputFormat="MMM yyyy"
                                disableFuture={true}
                                maxDate={new Date()}
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField size="small" style={{ width: "100px" }} {...params} />}
                            />
                            <div className={styles.to}>
                                {"to"}
                            </div>

                            <MobileDatePicker
                                views={['year', 'month']}
                                inputFormat="MMM yyyy"
                                disableFuture={true}
                                maxDate={new Date()}
                                value={endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField size="small" style={{ width: "100px" }} {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        className={styles.submitButton}
                        disabled={isDisabled}
                        color="primary"
                        variant="contained"
                        onClick={handleDownloadTemplate}
                    >
                        <Loading isLoading={isDownloading} text="Download" />
                    </Button>
                    <div {...getRootProps({ className: styles.dropzone })}>
                        <input {...getInputProps()} />
                        <Button
                            className={styles.submitButton}
                            style={{ marginLeft: "40px" }}
                            color="primary"
                            variant="contained"
                            disabled={isDisabled}
                        >
                            <Loading isLoading={isSubmitting} text="Upload" />
                        </Button>
                    </div>

                    <Button
                        style={{ marginLeft: "40px" }}
                        color="primary"
                        variant="contained"
                        onClick={goToManualDataEntryPage}
                    >
                        {"Enter data manually"}
                    </Button>

                    <Button
                        style={{ marginLeft: "40px" }}
                        color="primary"
                        variant="contained"
                        onClick={toggleApiIntegrationModal}
                    >
                        {"API Integration"}
                    </Button>

                </div>

                <GenericDialog
                    isOpenModal={isApiIntegrationModalShowing}
                    title={`API Integrations`}
                    btnText={"Close"}
                    btnAction={toggleApiIntegrationModal}
                    maxWidth={"sm"}
                    btnLoading={isSubmitting}
                    onClose={toggleApiIntegrationModal}
                >

                    <div className={styles.createTargetDialog_modalBody}>
                        <img src={apiIntegration} alt={"API Integration"} height={250} ></img>
                        <p>Net Zero Pro supports direct API integration with leading ESG data systems including Oracle Cloud, SAP Hana as well as custom CRM systems.
                            Reach out to your Customer Success Manager to get the process started.
                        </p>
                    </div>

                </GenericDialog>
            </div>
        </div >

    );
}
