import { Card, CardContent, Chip, Grid } from '@mui/material';
import React, { useContext } from "react";
import styles from "./Offsetting.module.css";
import { Button } from "@mui/material";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";


const OffsettingPage: React.FC = React.memo(() => {

    const { offsettingRenewableEnergy, offsettingDAC, offsettingForestry, offsettingEnergyEfficiency,
        offsettingCleanWater, offsettingSoil, offsettingOcean, offsettingMineralization, cleanWater, fuelEfficientStoves, riceHuskEnergy, solarPark } = useContext(
            FoundationThemeContext
        );

    const offsettingCategoryList = [
        {
            "title": "Renewable Energy",
            "imgPath": offsettingRenewableEnergy,
            "altText": "renewable-energy"
        },
        {
            "title": "Direct Air Capture",
            "imgPath": offsettingDAC,
            "altText": "direct-air-capture"
        },
        {
            "title": "Forestry",
            "imgPath": offsettingForestry,
            "altText": "forestry"
        },
        {
            "title": "Energy Efficiency",
            "imgPath": offsettingEnergyEfficiency,
            "altText": "energy-efficiency"
        },
        {
            "title": "Clean Water",
            "imgPath": offsettingCleanWater,
            "altText": "clean-water"
        },
        {
            "title": "Soil",
            "imgPath": offsettingSoil,
            "altText": "soil"
        },
        {
            "title": "Ocean",
            "imgPath": offsettingOcean,
            "altText": "ocean"
        },
        {
            "title": "Mineralization",
            "imgPath": offsettingMineralization,
            "altText": "mineralization"
        },
    ];

    const projectPortfolioList = [
        {
            "title": "Solar Pak by ABC Renewables",
            "imgPath": solarPark,
            "tags": ["Renewables", "India"]
        },
        {
            "title": "Fuel Efficient Stoves",
            "imgPath": fuelEfficientStoves,
            "tags": ["Energy", "Rwanda"]
        },
        {
            "title": "Rice Husk Energy Generation",
            "imgPath": riceHuskEnergy,
            "tags": ["Energy", "Cambodia"]
        },
        {
            "title": "Production of ceramic water purifiers",
            "imgPath": cleanWater,
            "tags": ["Clean Water", "India"]
        }
    ];

    return (
        <div>
            <Grid container spacing={2} padding={4}>

                <Grid direction="column" item xs={3}>
                    <div className={styles.offsettingDescContainer}>
                        <div className={styles.offsettingDescHeading}>Offsetting</div>
                        <div className={styles.offsettingDesc}>
                            <p>As you have embarked on the climate journey, offset emissions that you cannot yet reduce and avoid.
                                You can support a wide range of certified carbon offset projects across the world for immediate impact.
                                Responsible investment in good quality offset projects sends a strong message to your community that you are
                                committed to paving the way for a sustainable future.
                            </p>

                        </div>
                    </div>
                </Grid>

                <Grid direction="column" item xs={9}>
                    <Grid container spacing={1} padding={3}>
                        {offsettingCategoryList.map(item => {
                            return (
                                <Grid direction="row" item xs={3} className={styles.offsettingItem} key={item.title}>
                                    <div className={styles.offsettingItemHeading}>{item.title}</div>
                                    <Button>
                                        <img src={item.imgPath} alt={item.altText} className={styles.offsettingProject_img} />
                                    </Button>
                                </Grid>
                            )

                        })}
                    </Grid>
                </Grid>
            </Grid >
            <div className={styles.offsettingProjects_Container}>
                <h3>Our Portfolio projects</h3>
                <div className={styles.offsettingProjects_InnerContainer}>
                    {projectPortfolioList.map(item => {
                        return (
                            <Card key={item.title} className={styles.offsettingItem_projectCard}>
                                <CardContent>
                                    {/* TODO : add a onclick action once we know what to do here.  */}
                                    <Button>
                                        <div>
                                            <img src={item.imgPath} alt={item.title} className={styles.offsettingProject_img} />
                                            <div>
                                                {item.tags.map(tag => {
                                                    return (
                                                        <Chip label={tag} color="info" variant='outlined' className={styles.offsettingItem_projectCardChips}></Chip>
                                                    )
                                                })}
                                            </div>
                                            <div>
                                                <p>{item.title}</p>
                                            </div>
                                        </div>
                                    </Button>
                                </CardContent>
                            </Card>
                        )
                    })}
                </div>
            </div>
        </div>
    );
});

export default OffsettingPage;

