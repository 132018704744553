import { TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ICreateReportRequest, IReportView, ReportType } from "../../models/Report";
import GenericDialog from "../genericDialog/GenericDialog";
import styles from "./CreateReportDialog.module.css";
import DateFnsUtils from '@date-io/date-fns';
import { createReport } from "../../api/Company";
import { FoundationError } from "../../models/FoundationError";
import { ToastContext } from "../../contexts/ToastContext";

interface ICreateReportDialog {
    companyId: string;
    open: boolean;
    onClose: () => void;
    onNewReportCreated: (report: IReportView) => void;
    type: ReportType;
    customReportTemplateId?: string;
}

const CreateReportDialog: React.FC<ICreateReportDialog> = (props) => {
    const {
        companyId,
        open,
        onClose,
        onNewReportCreated,
        type,
        customReportTemplateId
    } = props;
    const { showToast } = useContext(ToastContext);

    const {
        setValue,
        register,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm<ICreateReportRequest>();

    useEffect(() => {
        if (customReportTemplateId) {
            setValue("customReportTemplateId", customReportTemplateId);
        }
    }, [customReportTemplateId, setValue])

    const [isSubmitting, setSubmitting] = useState(false);
    const [dueDate, setDueDate] = useState<Date | null>(null);
    const [year, setYear] = useState<Date | null>(null);

    const onSubmit = async (createReportRequest: ICreateReportRequest) => {
        setSubmitting(true);
        const response = await createReport(companyId, type, createReportRequest);
        setSubmitting(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        onNewReportCreated(response);
    }

    return (
        <>
            <GenericDialog
                isOpenModal={open}
                title={`Create new ${type} Report`}
                btnText={"Submit"}
                btnAction={handleSubmit(onSubmit)}
                maxWidth={"sm"}
                btnLoading={isSubmitting}
                onClose={onClose}
            >
                <div className={styles.createReportDialog_modalBody}>
                    <TextField
                        type={"text"}
                        label="Report Name*"
                        error={errors.name !== undefined}
                        helperText={errors.name ? "Name required" : ""}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        {...register("name", { required: true })}
                    />
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <MobileDatePicker
                            views={['year']}
                            openTo="year"
                            inputFormat="yyyy"
                            disableFuture={true}
                            value={year}
                            label={"Year*"}
                            toolbarPlaceholder={"Year"}
                            toolbarTitle={"Select"}
                            closeOnSelect={true}
                            onChange={(date: Date | null) => setYear(date)}
                            renderInput={(params) =>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.year !== undefined}
                                    helperText={errors.year ? "Year required" : ""}
                                    {...params}
                                    {...register("year", { required: true })}
                                />
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <MobileDatePicker
                            views={['day']}
                            openTo="day"
                            inputFormat="dd/MM/yyyy"
                            value={dueDate}
                            label={"Due Date*"}
                            toolbarPlaceholder={"Due Date"}
                            toolbarTitle={"Select"}
                            disablePast
                            closeOnSelect={true}
                            onChange={(date: Date | null) => setDueDate(date)}
                            renderInput={(params) =>
                                <TextField
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.dueDate !== undefined}
                                    helperText={errors.dueDate ? "Due date required" : ""}
                                    {...params}
                                    {...register("dueDate", { required: true })}
                                />
                            }
                        />
                    </LocalizationProvider>
                </div>
            </GenericDialog>
        </>
    );
};

export default CreateReportDialog;
