import { FoundationError } from "../models/FoundationError";
import { ITargetView } from "../models/Target";
import { BACKEND_URL_V1, deletee, get, post } from "../utils/Api";

interface IGetTargetsResponse {
    targetViews: ITargetView[]
}

export const getAllTargets = async (): Promise<FoundationError | ITargetView[]> => {
    try {
        const response = await get<IGetTargetsResponse>(`${BACKEND_URL_V1}/targets`);

        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody?.targetViews) {
                return response.parsedBody?.targetViews;
            }
        }

        const errorMessage =
            response.serverError?.error.message ??
            "Targets could not be fetched";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
};

export interface ICreateTargetRequest {
    startDate: string;
    endDate: string;
    targetEmission: number;
}

export interface ICreateTargetResponse {
    targetView: ITargetView;
}

export const createTarget = async (request: ICreateTargetRequest): Promise<FoundationError | ITargetView> => {
    try {
        const response = await post<ICreateTargetResponse>(`${BACKEND_URL_V1}/targets`, request);
        if (Math.floor(response.status / 100) === 2) {
            if (response.parsedBody?.targetView) {
                return response.parsedBody?.targetView;
            }
        }

        const errorMessage = response.serverError?.error.message ?? "Target could not be created";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const updateTarget = async (target: ITargetView, request: ICreateTargetRequest): Promise<FoundationError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/targets/${target.id}`, request);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Target could not be updated";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }
}

export const deleteTarget = async (targetId: string): Promise<null | FoundationError> => {

    try {
        const response = await deletee(`${BACKEND_URL_V1}/targets/${targetId}`);

        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Failed to delete Target";
        return new FoundationError(errorMessage);
    } catch (err) {
        console.log(err);
        return new FoundationError("Something went wrong");
    }

}
