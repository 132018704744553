import { jsonConfig } from "./configs/ConfigProvider";
import { Environment } from "./Environment";

export class Config {
  private static instance: Config;
  private config: ReturnType<typeof jsonConfig>;

  private constructor() {
    let environment: Environment;
    const reactEnv = process.env.REACT_APP_ENV as "dev" | "stage" | "prod" | undefined;
    switch (reactEnv) {
      case "dev":
        environment = Environment.DEV;
        break;
      case "stage":
        environment = Environment.STAGE;
        break;
      case "prod":
        environment = Environment.PROD;
        break;
      default:
        environment = Environment.DEV;
        break;
    }

    this.config = jsonConfig(environment);
  }

  public static getInstance() {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  public getHost() {
    return this.config.server.host;
  }

  public getFirebaseConfig() {
    return this.config.firebase;
  }

  public themeConfig() {
    return {
      primaryColor: "#DAE3F1",
      primaryColorLight: "#EFF3F9",
      primaryTextColor: "#155EA2",
      light: "#ffffff",
      dark: "#2A384B",
      selectedColor: "#EFF3F9",
      selectedBorderColor: "#32E7A4",
      logoDark: "/assets/logo.svg",

      dashboardIcon: "/assets/dashboard.png",
      dataCollectionIcon: "/assets/data_collection.png",
      offsettingIcon: "/assets/offsetting.png",
      reductionIcon: "/assets/reduction.png",
      reportingIcon: "/assets/reporting.png",

      offsettingRenewableEnergy: "/assets/renewable_energy.png",
      offsettingDAC: "/assets/dac.png",
      offsettingForestry: "/assets/forestry.png",
      offsettingEnergyEfficiency: "/assets/energy_efficiency.png",
      offsettingCleanWater: "/assets/clean_water.png",
      offsettingSoil: "/assets/soil.png",
      offsettingOcean: "/assets/ocean.png",
      offsettingMineralization: "/assets/mineralization.png",
      formatESGPreview: "/assets/format_esg_preview.png",
      formatESGIcon: "/assets/format_esg_icon.png",
      brsrReportIcon: "/assets/brsr_report.png",
      tcfdReportIcon: "/assets/tcfd_report.png",
      formatAnnualReportIcon: "/assets/annual_report_logo.png",
      customReportIcon: "/assets/custom_report.png",
      deleteIconRed: "/assets/deleteIconRed.png",
      apiIntegration: "/assets/api_integration.png",
      cleanWater: "/assets/cleanWater.png",
      fuelEfficientStoves: "/assets/fuelEfficientStoves.png",
      riceHuskEnergy: "/assets/riceHuskEnergy.png",
      solarPark: "/assets/solarPark.png"
    }
  }

  public getSupportedCountries() {
    // Order is important, do not change
    return [
      {
        countryCode: "IN",
        prefix: "+91",
      }
    ];
  }
}
