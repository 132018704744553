import { IconButton, Input, MenuItem, Select } from "@mui/material";
import { ReactNode, useContext, useState } from "react";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { ActionPlanStatus, IActionPlanView } from "../../models/ActionPlan";
import GenericTableWithTitle from "../genericTable/GenericTableWithTitle";
import CircleIcon from '@mui/icons-material/Circle';
import { formatTimestampToMonthYear } from "../../utils/DateUtils";
import { ConfirmationDialog } from "../confirmationDialog/ConfirmationDialog";
import { FoundationError } from "../../models/FoundationError";
import { dismissActionPlan, updateActionPlanStatus } from "../../api/ActionPlan";
import { ToastContext } from "../../contexts/ToastContext";
import { Loading } from "../loading/Loading";

interface IProps {
    actionPlansData: IActionPlanView[];
    refreshTable: () => void;
    onActionPlanClicked: (actionPlan: IActionPlanView) => void;
}

interface IStatusDowndownProps {
    currentActionPlanId: string;
    status: ActionPlanStatus;
    onChange: (status: ActionPlanStatus) => void;
    isUpdatingForActionPlanId: string | undefined;
}

const StatusDropdown = (props: IStatusDowndownProps) => {
    const { status, onChange, currentActionPlanId, isUpdatingForActionPlanId } = props;

    return (
        <>
            {currentActionPlanId === isUpdatingForActionPlanId && (
                <Loading isLoading />
            )}
            <Select
                id="actionPlanStatus"
                value={status}
                onChange={(event) => {
                    const value = event.target.value as ActionPlanStatus;
                    onChange(value);
                }}
                input={<Input fullWidth />}
                startAdornment={
                    <CircleIcon
                        fontSize={"small"}
                        color={(
                            status === ActionPlanStatus.INACTIVE) ? "disabled" :
                            status === ActionPlanStatus.ACTIVE ? "warning" : "disabled"
                        }
                        sx={{ marginRight: "5px", marginLeft: "5px" }}
                    />
                }
            >
                <MenuItem value={ActionPlanStatus.ACTIVE}>Active</MenuItem>
                <MenuItem value={ActionPlanStatus.INACTIVE}>Inactive</MenuItem>
            </Select>
        </>
    );
};

export const ActionTable: React.FC<IProps> = (props) => {
    const { selectedBorderColor } = useContext(FoundationThemeContext);

    const { actionPlansData, refreshTable, onActionPlanClicked } = props;
    const { deleteIconRed } = useContext(FoundationThemeContext);
    const { showToast } = useContext(ToastContext);

    const [isDismissing, setIsDismissing] = useState(false);
    const [isUpdatingStatusForActionPlanId, setIsUpdatingStatusForActionPlanId] = useState<string>();
    const [selectedActionPlanForDismiss, setSelectedActionPlanForDismiss] = useState<string>();

    const headers = [
        "Category",
        "Facility",
        "Start",
        "End",
        "Action Item",
        "Emissions Reduction Potential Total tCO2e",
        "Active/In-active",
        "Dismiss",
        "Status"
    ];

    const onStatusChange = async (status: ActionPlanStatus, actionPlan: IActionPlanView) => {
        setIsUpdatingStatusForActionPlanId(actionPlan.id);
        const response = await updateActionPlanStatus(actionPlan.id, status);
        setIsUpdatingStatusForActionPlanId(undefined);

        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("Action plan status updated", "success");
        refreshTable();
    }

    const onConfirmDismissActionPlan = async (actionItemId: string) => {
        setIsDismissing(true);
        const response = dismissActionPlan(actionItemId);
        setIsDismissing(false);

        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setSelectedActionPlanForDismiss(undefined);
        // sometimes the update operation takes time to propogate, just refresh the table after 500 ms to be safe
        setTimeout(() => refreshTable(), 500);
    }

    return (
        <>
            {selectedActionPlanForDismiss && (
                <ConfirmationDialog
                    buttonText={"Dismiss"}
                    bodyText={"Are you sure you want to dismiss the action plan?"}
                    open={true}
                    onClose={() => setSelectedActionPlanForDismiss(undefined)}
                    onApprove={() => onConfirmDismissActionPlan(selectedActionPlanForDismiss)}
                    isApproving={isDismissing}
                />
            )}
            <GenericTableWithTitle
                title={""}
                pagination
                headers={headers.map((str) => {
                    return { header: str };
                })}
                data={actionPlansData}
                showHeadingRow
                onRowClick={onActionPlanClicked}
                dataRenderer={(val: IActionPlanView, column: string): ReactNode => {
                    switch (column) {
                        case headers[0]:
                            return val.actionPlanCategory;
                        case headers[1]:
                            return val.facilityView.name;
                        case headers[2]:
                            return formatTimestampToMonthYear(val.startDate);
                        case headers[3]:
                            return formatTimestampToMonthYear(val.endDate);
                        case headers[4]:
                            return val.description;
                        case headers[5]:
                            return val.getEmissionReduction;
                        case headers[6]:
                            return (
                                <StatusDropdown
                                    status={val.status ?? ActionPlanStatus.INACTIVE}
                                    onChange={(status: ActionPlanStatus) => {
                                        onStatusChange(status, val);
                                    }}
                                    currentActionPlanId={val.id}
                                    isUpdatingForActionPlanId={isUpdatingStatusForActionPlanId}
                                />
                            );
                        case headers[7]:
                            return (
                                <IconButton
                                    aria-label="fingerprint" color="success"
                                    onClick={() => setSelectedActionPlanForDismiss(val.id)}
                                    sx={{
                                        marginLeft: "20px",
                                        color: "#fff",
                                        outline: `1px solid ${selectedBorderColor}`,
                                        width: "45px",
                                        borderRadius: "5px",
                                        height: "40px",
                                    }}
                                    disabled={val.status === ActionPlanStatus.COMPLETED}
                                >
                                    <img src={deleteIconRed} alt="delete icon" />
                                </IconButton>
                            );
                        case headers[8]:
                            if (val.status === ActionPlanStatus.COMPLETED) {
                                return "Completed";
                            } else {
                                return "Unknown"
                            }
                        default:
                            return "";
                    }
                }}
            />
        </>
    );
}
