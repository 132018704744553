import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import styles from "./SideBar.module.css";
import { Link, matchPath, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import SidebarNav from "../sidebarNav/SidebarNav";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddchartIcon from '@mui/icons-material/Addchart';
import { Auth } from "../../utils/Auth";
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WorkIcon from '@mui/icons-material/Work';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import GridViewIcon from '@mui/icons-material/GridView';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import BusinessIcon from '@mui/icons-material/Business';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import KitchenIcon from '@mui/icons-material/Kitchen';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import SpaIcon from '@mui/icons-material/Spa';
import EvStationIcon from '@mui/icons-material/EvStation';
import TireRepairIcon from '@mui/icons-material/TireRepair';

interface ISideBarData {
  heading: string;
  views: ISideBarView[];
}

export interface ISideBarView {
  title: string;
  icon?: string | ReactNode;
  href?: string;
  items?: ISideBarItem[];
  hide?: boolean;
}

interface ISideBarItem {
  title: string;
  icon: string | ReactNode;
  href: string;
  type?: string
}

const SideBar: React.FC = () => {

  const { primaryColor, primaryTextColor, logoDark } = useContext(FoundationThemeContext);

  const sideBarViews: ISideBarData[] = useMemo(() => [
    {
      heading: "Company emissions",
      views: []
    },
    {
      heading: "Facility Emissions",
      views: [
        {
          title: "Overview",
          icon: <GridViewIcon fontSize="large" sx={{ color: primaryTextColor }} />,
          href: "/E/facility-emissions"
        },
        {
          title: "Scope 1",
          items: [
            {
              title: "Diesel",
              icon: <EvStationIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/diesel"
            },
            {
              title: "Petrol",
              icon: <LocalGasStationIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/petrol"
            },
            {
              title: "LPG",
              icon: <PropaneTankIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/lpg"
            },
            {
              title: "Refrigerants",
              icon: <KitchenIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/refrigerants"
            },
            {
              title: "Oil Products",
              icon: <OilBarrelIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/oil"
            },
            {
              title: "Coal Products",
              icon: <IndeterminateCheckBoxIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/coal"
            },
            {
              title: "Natural Gas",
              icon: <GasMeterIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/natural-gas"
            },
            {
              title: "Biomass",
              icon: <SpaIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/biomass"
            },
            {
              title: "Other Fuels",
              icon: <TireRepairIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/other-fuels"
            }
          ]
        },
        {
          title: "Scope 2",
          items: [
            {
              title: "Electricity",
              icon: <ElectricBoltIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/electricity"
            },
          ]
        },
        {
          title: "Scope 3",
          items: [
            {
              title: "Business Travel",
              icon: <WorkIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/business-travel"
            },
            {
              title: "Employee Commute",
              icon: <DirectionsBikeIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/employee-commute"
            },
            {
              title: "Waste",
              icon: <DeleteIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/waste"
            },
            {
              title: "Suppliers",
              icon: <LocalShippingIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/suppliers"
            },
            {
              title: "Purchased Goods and Services",
              icon: <BusinessIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/purchased-goods"
            },
            {
              title: "Capital Goods",
              icon: <WifiTetheringIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/capital-goods"
            },
            {
              title: "Fuel and Energy",
              icon: <EnergySavingsLeafIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/fuel-and-energy"
            },
            {
              title: "Upstream Transportation and Distribution",
              icon: <AirportShuttleIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/upstream-transportation-distribution"
            },
            {
              title: "Upstream Leased Assets",
              icon: <FormatLineSpacingIcon sx={{ color: primaryTextColor }} />,
              type: "button",
              href: "/E/upstream-leased-assets"
            }
          ]
        }
      ]
    },
    {
      heading: "Data Collection",
      views: []
    },
    {
      heading: "Reduction",
      views: [
        {
          title: "Analytics",
          icon: <GridViewIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/reduction"
        },
        {
          title: "Action Plans",
          icon: <FormatListBulletedIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/reduction/plans"
        },
        {
          title: "Target Setting",
          icon: <TrackChangesIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/reduction/targets"
        },
      ]
    },
    {
      heading: "Offsetting",
      views: []
    },
    {
      heading: "Reports",
      views: [
        {
          title: "Reports List",
          icon: <GridViewIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/reports"
        },
        {
          title: "Create new Report",
          icon: <FormatListBulletedIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/reports/create"
        },
        {
          title: "Report Details",
          icon: <GridViewIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/reports/:reportId",
          hide: true
        }
      ]
    },
    {
      heading: "Facility Settings",
      views: [
        {
          title: "Facility Details",
          icon: <ContentCopyIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/facility-details"
        },
        {
          title: "Create New Facility",
          icon: <AddchartIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/create-facility"
        }
      ]
    },
    {
      heading: "Supplier Settings",
      views: [
        {
          title: "Supplier Details",
          icon: <ContentCopyIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/supplier-details"
        },
        {
          title: "Create New Supplier",
          icon: <AddchartIcon sx={{ color: primaryTextColor }} fontSize="large" />,
          href: "/create-supplier"
        }
      ]
    },
    {
      heading: "User Details",
      views: []
    },
    {
      heading: "Profile",
      views: []
    }
  ], [primaryTextColor]);

  const [sidebarData, setSidebarData] = useState<ISideBarData>();

  let location = useLocation();
  let currentPath = location.pathname;

  useEffect(() => {
    let sideBarView: ISideBarData | undefined;

    let path = (currentPath === "/" || currentPath === "/E") ? "/E/company-emissions" : currentPath

    sideBarViews.forEach(views => {
      let page = views.views.find(view => true && matchPath(view.href ?? "", path));
      if (page) {
        sideBarView = views;
      } else {
        let pages = views.views
          .map(view => {
            return view.items?.find(subView => true && matchPath(subView.href ?? "", path))
          })
          .filter(view => view && true);

        if (pages.length > 0) {
          sideBarView = views;
        }
      }
    });

    setSidebarData(sideBarView)
  }, [currentPath, sideBarViews])

  const pages = sidebarData?.views ?? [];

  const companyLogoPath = Auth.getInstance().getUserSettings()?.companyView.logoUrl

  return (
    <div
      className={styles.sideBar_root}
      style={{ backgroundColor: primaryColor }}
    >
      <div className={`${styles.sideBar_header} title`}>
        <Button component={Link} to={"/E/company-emissions"}>
          <img src={companyLogoPath ?? logoDark} alt={"logo"} className={companyLogoPath ? styles.sideBar_img_custom : styles.sideBar_img} />
        </Button>
      </div>
      <p className={`${styles.sideBar_heading} title`} style={{ color: primaryTextColor }}>{sidebarData?.heading}</p>
      <SidebarNav pages={pages} />
    </div>
  );
};

export default SideBar;
