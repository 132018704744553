import { FoundationError } from "../models/FoundationError";
import { BACKEND_URL_V1, post } from "../utils/Api";
import { IGetSettings } from "./Users";

interface ILoginResponse {
  customToken: string;
  getSettingsResponse: IGetSettings
}

interface ILoginRequest {
  email: string;
  password: string;
  returnSecureToken: boolean
}

interface IPasswordResetRequest {
  resetToken: string;
  password: string;
}

export const login = async (
  email: string,
  password: string
): Promise<ILoginResponse | FoundationError> => {

  try {
    // returnSecureToken should always be true
    const loginRequest: ILoginRequest = {
      email: email,
      password: password,
      returnSecureToken: true
    }
    const response = await post<ILoginResponse>(
      `${BACKEND_URL_V1}/auth/login`, 
      loginRequest
    );

    if (response.status / 100 === 2 && response.parsedBody !== undefined) {
      return response.parsedBody;
    }

    const errorMessage = response.serverError?.error.message ?? "Failed to login";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }

}

export const resetPassword = async (
  password: string,
  resetToken: string
): Promise<ILoginResponse | FoundationError> => {

  try {
    const passwordResetRequest: IPasswordResetRequest = {
      resetToken: resetToken,
      password: password,
    }
    const response = await post<ILoginResponse>(
      `${BACKEND_URL_V1}/auth/resetPassword`, 
      passwordResetRequest
    );

    if (response.status / 100 === 2 && response.parsedBody !== undefined) {
      return response.parsedBody;
    } else if (response.status / 100 === 4) {
      return new FoundationError("Session expired! try again");
    }

    const errorMessage = response.serverError?.error.message ?? "Unable to reset password";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
}


export const sendPasswordResetEmail = async (
  email: string,
): Promise<undefined | FoundationError> => {

  try {
    const response = await post(
      `${BACKEND_URL_V1}/auth/resetPasswordLink`, 
      {email}
    );

    if (response.status / 100 === 2) {
      return undefined;
    }

    const errorMessage = response.serverError?.error.message ?? "Unable to reset password";
    return new FoundationError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FoundationError("Something went wrong");
  }
}
