import { IGenericSummaryComponentItemData } from "../components/companyEmissionsOverview/summary/GenericSummaryComponent";
import { IKeyValuePair } from "../components/genericPieChart/GenericPieChart";
import { IFacilityEmissionsV2 } from "../models/CompanyEmissionsV2";
import { KpiSection, KpiSectionGroup } from "../models/Kpi";

export function getFacilitySectionSummaryData(facilityEmissions: IFacilityEmissionsV2 | undefined, section: KpiSection): IGenericSummaryComponentItemData[][] {
    if (facilityEmissions === undefined) {
        return [];
    }

    const totalFacilityEmissions = facilityEmissions.totalEmissions;
    const sectionEmissions = facilityEmissions.sectionEmissions.find((emission) => emission.section === section);
    if (sectionEmissions === undefined) {
        return [
            [
                { key: "Emissions", unit: "N/A" },
                { key: "Since last month", unit: "N/A" },
                { progress: 0 },
                { key: "All of my carbon emissions", value: totalFacilityEmissions, unit: " tCO2e" },
            ]
        ];
    }

    const totalEmissions = sectionEmissions.totalEmissions;
    const percentOfTotal = totalFacilityEmissions === 0 ? 0 : ((totalEmissions / totalFacilityEmissions) * 100);

    const lastMonthEmissions = facilityEmissions.monthlySectionWiseEmissions
        .at(facilityEmissions.monthlyScopeWiseEmissions.length - 1)
        ?.sectionEmissions
        .find((emission) => emission.section === section)
        ?.totalEmissions;

    const secondLastMonthEmissions = facilityEmissions.monthlySectionWiseEmissions
        .at(facilityEmissions.monthlyScopeWiseEmissions.length - 2)
        ?.sectionEmissions
        .find((emission) => emission.section === section)
        ?.totalEmissions;

    let emissionsSinceLastMonth = 0;
    if (lastMonthEmissions && secondLastMonthEmissions) {
        emissionsSinceLastMonth = lastMonthEmissions - secondLastMonthEmissions;
    }
    const baseSummaryData = [
        [
            { key: `Emissions from ${sectionEmissions.sectionTitle}`, value: totalEmissions, unit: " tCO2e" },
            { key: "Since last month", value: emissionsSinceLastMonth ?? 0, unit: " tCO2e" },
            { progress: percentOfTotal },
            { key: "All of my carbon emissions", value: totalFacilityEmissions, unit: " tCO2e" },
        ]
    ];

    const summaryKpis = facilityEmissions.scopeWiseSectionEmissions
        .flatMap((emission) => emission.sectionEmissionsWithGrouping)
        .find((emission) => emission.section === section)
        ?.groupedKpis.SUMMARY;

    if (summaryKpis === undefined) {
        return baseSummaryData;
    }

    const additionalSummaryData = summaryKpis.map((kpi) => {
        return { key: kpi.outputTitle, value: kpi.emissions ?? kpi.input, unit: " tCO2e" }
    });

    baseSummaryData.push(additionalSummaryData);
    return baseSummaryData;
}

export function getPieChartDataForSectionGroup(
    facilityEmissions: IFacilityEmissionsV2 | undefined,
    section: KpiSection,
    sectionGroup: KpiSectionGroup,
    dateType: "emissions" | "input" = "emissions"
) {
    const chartDataTotal: IKeyValuePair[] = [];
    const chartDataPerEmployee: IKeyValuePair[] = [];
    if (facilityEmissions === undefined) {
        return { chartDataTotal, chartDataPerEmployee, chartTitle: "" };
    }

    const kpisForSectionGroup = facilityEmissions.scopeWiseSectionEmissions
        .flatMap((scopeWiseEmissions) => scopeWiseEmissions.sectionEmissionsWithGrouping)
        .filter((sectionEmissions) => sectionEmissions.section === section)
        .flatMap((sectionEmissionsWithGrouping) => sectionEmissionsWithGrouping.groupedKpis)
        .filter((sectionGroupEmissions) => sectionGroupEmissions[sectionGroup] !== undefined)
        .flatMap((sectionGroupEmissions) => sectionGroupEmissions[sectionGroup]);

    const employeeCount = facilityEmissions.facility.numberOfEmployees;
    kpisForSectionGroup.forEach((kpi) => {
        let kpiValue = dateType === "emissions" ? kpi.emissions : kpi.input;
        if (kpiValue === 0) {
            return;
        }
        chartDataTotal.push({ key: kpi.outputTitle, value: kpiValue });
        chartDataPerEmployee.push({ key: kpi.outputTitle, value: kpiValue / employeeCount });
    });

    const chartTitle = getPieChartTitleForSectionGroup(section, sectionGroup);
    return { chartDataTotal, chartDataPerEmployee, chartTitle };
}

function getPieChartTitleForSectionGroup(section: KpiSection, sectionGroup: KpiSectionGroup) {
    if (sectionGroup === KpiSectionGroup.NONE) {
        switch (section) {
            case KpiSection.DIESEL_CONSUMPTION: return "Emissions per diesel consumption type";
            case KpiSection.REFRIGERANTS: return "Emissions per refrigerants";
            case KpiSection.OIL_PRODUCTS: return "Emissions per oil products";
            case KpiSection.COAL_PRODUCTS: return "Emissions per coal products";
            case KpiSection.BIOMASS: return "Emissions per biomass type";
            case KpiSection.OTHER_FUELS: return "Emissions per other fuels";
        }

        return "";
    }

    switch (sectionGroup) {
        case KpiSectionGroup.ELECTRICITY_SOURCES: return "Emissions from Renewable and Non-Renewable Sources";
        case KpiSectionGroup.BUSINESS_TRAVEL: return "Emissions per business travel type";
        case KpiSectionGroup.EMPLOYEE_COMMUTE: return "Emissions per employee commute type";
    }

    return "";
}
