import { Grid, IconButton } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { deleteFacility, getAllFacilities } from "../../api/Facility";
import GenericTableWithTitle from "../../components/genericTable/GenericTableWithTitle";
import { Loading } from "../../components/loading/Loading";
import { IFacility } from "../../models/Facility";
import { FoundationError } from "../../models/FoundationError";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from "../../components/confirmationDialog/ConfirmationDialog";
import { ToastContext } from "../../contexts/ToastContext";

const FacilityDetails: React.FC = () => {
    const [facilities, setFacilities] = useState<IFacility[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFacility, setSelectedFacility] = useState<IFacility>();
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const headers = ["Name", "Supervisors", "Space", "Number Of Employees", ""]
    const navigate = useNavigate();

    const { showToast } = useContext(ToastContext);

    const getSupervisors = (facility: IFacility): string => {
        const names: string[] = [];
        for (let i = 0; i < facility.supervisors.length; i++) {
            names.push(facility.supervisors[i].name)
        }
        return names.join()
    };

    const fetchFacilities = useCallback(async () => {
        setIsLoading(true)
        const facilities = await getAllFacilities()
        setIsLoading(false);
        if (facilities instanceof FoundationError) {
            const message = facilities?.message ?? "Something went wrong!";
            showToast(message, "error");
        } else {
            setFacilities([...facilities]);
        }
    }, [showToast]);

    useEffect(() => {
        fetchFacilities();
    }, [fetchFacilities])

    facilities?.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    });

    const onDeleteFacility = async (facilityId: string) => {
        setIsDeleting(true);
        const error = await deleteFacility(facilityId)
        setIsDeleting(false);
        if (error instanceof FoundationError) {
            showToast(error.getErrorMessage(), "error");
            return;
        }

        showToast("Facility has been deleted successfully", "success");
        setShowDeleteConfirmationDialog(false);
        fetchFacilities();
    }

    return (
        <div>
            <Grid container spacing={2} padding={4}>
                <ConfirmationDialog
                    buttonText={"Delete"}
                    bodyText={`Are you sure that you want to delete the facility: ${selectedFacility?.name} and the data associated with it permanently?`}
                    open={showDeleteConfirmationDialog}
                    onClose={() => {
                        setShowDeleteConfirmationDialog(false);
                    }}
                    onApprove={() => onDeleteFacility(selectedFacility?.id ?? "")}
                    isApproving={isDeleting}
                />
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {facilities.length === 0 && (
                        <div>
                            <Loading text={"No facilities found"} isLoading={isLoading} />
                        </div>
                    )}
                    {facilities.length > 0 && (
                        <GenericTableWithTitle
                            showHeadingRow={true}
                            pagination={true}
                            title={"Facilities"}
                            headers={headers.map((str) => {
                                return { header: str };
                            })}
                            data={facilities}
                            maxHeight="400px"
                            getWidth={(column) => {
                                if (column === "Supervisors" || column === "Name") {
                                    return 160;
                                } else {
                                    return 80;
                                }
                            }}
                            dataRenderer={(data, column) => {
                                const supervisors = getSupervisors(data)
                                switch (column) {
                                    case 'Name':
                                        return data.name;
                                    case 'Supervisors':
                                        return (supervisors);
                                    case 'Space':
                                        return data.spaceInMeterSquare + " ㎡";
                                    case 'Number Of Employees':
                                        return data.numberOfEmployees;
                                    case "":
                                        return (
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "20px"
                                            }}>
                                                <IconButton
                                                    name="edit"
                                                    color="default"
                                                    onClick={() => {
                                                        navigate(`/create-facility`, {
                                                            state: data
                                                        });
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    name="delete"
                                                    color="default"
                                                    onClick={() => {
                                                        setSelectedFacility(data as IFacility);
                                                        setShowDeleteConfirmationDialog(true);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        );
                                }
                            }}
                            hover={false}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default FacilityDetails;
