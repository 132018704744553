import { Button, Card, CardContent, Grid, Tooltip } from "@mui/material"
import styles from "./TableWithPieOrBarChart.module.css"
import InfoIcon from '@mui/icons-material/Info';
import { useContext, useEffect, useState } from "react";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import GenericTable, { IGenericTableProps } from "../genericTable/GenericTable";
import GenericPieChart, { IGenericPieChartProps, IKeyValuePair } from "../genericPieChart/GenericPieChart";
import GenericBarChart, { IGenericBarChartProps } from "../genericBarChart/GenericBarChart";
import { ErrorComponent } from "../errorComponent/ErrorComponent";
import { Loading } from "../loading/Loading";
import { useNavigate, useLocation } from "react-router-dom";

interface IProps {
    title: string;
    tabs?: string[];
    defaultTab?: string;
    orient?: "row" | "column";
    showActionButton?: boolean;
    showInfoIcon?: boolean;
    infoIconText?: string;
    colors?: string[];
    showTable?: boolean
    chartType?: "pie" | "bar";
    pieChartProps?: IGenericPieChartProps,
    barChartProps?: IGenericBarChartProps,
    minHeight?: number;
    isLoading?: boolean;
    onTabChange?: (category: string) => any[];
    allowZero?: boolean;
}

export function TableWithPieOrBarChart<T>(props: IProps & IGenericTableProps<T>) {

    const {
        title,
        tabs,
        defaultTab = "",
        orient,
        showActionButton,
        showInfoIcon,
        colors,
        chartType = "pie",
        barChartProps,
        pieChartProps,
        showTable = true,
        minHeight = 400,
        isLoading,
        onTabChange,
        infoIconText,
        allowZero = false
    } = props;
    const [data, setData] = useState<IKeyValuePair[]>();
    const [hoverIndex, setHoverIndex] = useState<number>();
    const isEmpty = data === undefined || data?.length === 0 ||
        (data.length > 0 && !allowZero && data.map(e => e.value === 0).filter((isZero) => isZero === true).length === data.length);

    const navigate = useNavigate();
    const queryStr = useLocation().search;

    const pieChartColors = colors ?? [
        '#155EA2',
        '#8D6BEB',
        '#64B5FC',
        '#32E7A4',
        '#04B1B4',
        '#FCEA64',
        '#C2B484',
        '#CECECE',
        '#878787'
    ];

    useEffect(() => {
        setData(pieChartProps?.data ?? barChartProps?.data ?? []);
    }, [pieChartProps, barChartProps])

    useEffect(() => {
        setSelectedTab(tabs ? defaultTab.length > 0 ? defaultTab : tabs[0] : "");
    }, [barChartProps, pieChartProps, tabs, defaultTab]);

    const { primaryColorLight, selectedBorderColor } = useContext(FoundationThemeContext);
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const categoryOnChange = (category: string) => {
        if (onTabChange) {
            setData(onTabChange(category))
        }
        setSelectedTab(category);
    }

    return (
        <Card className={styles.card}>
            <div className={styles.tableWithPieChartOrBarChartHeader}>
                <h4 className={`${styles.genericTable_tableTitle} title`}>{title}</h4>
                {showInfoIcon && (
                    <Tooltip title={infoIconText}>
                        <InfoIcon style={{ color: "#CECECE" }} />
                    </Tooltip>
                )}
            </div>

            <CardContent className={styles.cardContent} style={{ minHeight: minHeight }}>
                {isLoading && (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: minHeight }}>
                        <Loading isLoading />
                    </div>
                )}

                {!isLoading && (
                    <>
                        {isEmpty ?
                            (<ErrorComponent />) :
                            (
                                <>
                                    <Grid container spacing={2} padding={4} style={{ flexDirection: orient === "row" ? "column-reverse" : "row" }}>
                                        <Grid item lg={(orient === "row" || !showTable) ? 12 : 6} md={12} sm={12} xs={12} className={styles.pieChartContainer}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                {chartType === "pie" && pieChartProps && (
                                                    <GenericPieChart
                                                        colors={pieChartColors}
                                                        {...pieChartProps}
                                                        hoverIndex={hoverIndex}
                                                        setHoverIndex={setHoverIndex}
                                                        data={data}
                                                    />
                                                )}

                                                {chartType === "bar" && barChartProps && (
                                                    <GenericBarChart
                                                        {...barChartProps}
                                                        colors={pieChartColors}
                                                    />
                                                )}
                                            </div>

                                        </Grid>
                                        <Grid item lg={orient === "row" ? 12 : 6} md={12} sm={12} xs={12}>
                                            <div className={styles.tabsRoot}>
                                                <div className={styles.tabs}>
                                                    {tabs && tabs.map(tab => {
                                                        return (
                                                            <span
                                                                onClick={() => {
                                                                    categoryOnChange(tab)
                                                                }}
                                                                className={selectedTab === tab ? `${styles.selected} title` : "title"}
                                                                key={tab}
                                                            >
                                                                {tab}
                                                            </span>)
                                                    })}
                                                </div>
                                            </div>
                                            {showTable && (
                                                <GenericTable
                                                    {...props}
                                                    maxHeight={"200px"}
                                                    hoverIndex={hoverIndex}
                                                    setHoverIndex={setHoverIndex}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                    {showActionButton && (
                                        <Button
                                            className={styles.submitButton}
                                            style={{
                                                marginLeft: "100px",
                                                backgroundColor: primaryColorLight,
                                                color: "black",
                                                border: `1px solid ${selectedBorderColor}`,
                                                float: "right",
                                                marginTop: "10px",
                                                textTransform: "none",
                                                fontFamily: "Poppins"
                                            }}
                                            variant="outlined"
                                            onClick={() => {
                                                navigate({
                                                    pathname: "/reduction",
                                                    search: queryStr,
                                                });
                                            }}
                                        >
                                            Take action
                                        </Button>
                                    )}
                                </>
                            )
                        }
                    </>
                )}
            </CardContent>
        </Card>
    )
}
