import { Navigate } from "react-router-dom";
import HomeLayout from "../components/homeLayout/HomeLayout";
import { Auth } from "./Auth";
import { displayNameForUser } from "./DisplayUtils";

interface IProps {
    path: string;
    element: JSX.Element;
    showDates?: boolean;
    showFacilityFilter?: boolean;
    showESGFilters?: "E" | "S" | "G";
}

function PrivateRouteWithLayout(props: IProps) {
    const userSettings = Auth.getInstance().getUserSettings();
    if (!userSettings) {
        return <Navigate to="/login" />;
    }

    const {
        element,
        showDates,
        showFacilityFilter,
        showESGFilters
    } = props;

    return (
        <HomeLayout
            username={displayNameForUser(userSettings)}
            showESGFilters={showESGFilters}
            showFacilityFilter={showFacilityFilter}
            showDates={showDates}
        >
            {element}
        </HomeLayout>
    );
};

export default PrivateRouteWithLayout;
