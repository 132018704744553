import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { GenericSummaryComponent } from "../../../components/companyEmissionsOverview/summary/GenericSummaryComponent";
import { CompanyDataContextV2 } from "../../../contexts/CompanyDataContextV2";
import { KpiSection, KpiSectionGroup } from "../../../models/Kpi";
import { getFacilitySectionSummaryData, getPieChartDataForSectionGroup } from "../../../utils/FacilityEmissionUtils";
import { TableWithPieOrBarChart } from "../../../components/tableWithPieOrBarChart/TableWithPieOrBarChart";
import FacilityEmissionsOverTime from "../../../components/facilityEmissionsOverTime/FacilityEmissionsOverTime";
import { IFacilityEmissionsV2 } from "../../../models/CompanyEmissionsV2";
import GenericBarChartWithTitle from "../../../components/genericBarChart/GenericBarChartWithTitle";
import { toFixed } from "../../../utils/Utils";

interface IProps {

}

const pieChartColors = ["#32E7A4", "#64B5FC", "#FCEA64", "#FF5733", "#33FF57", "#5733FF", "#FF33A6", "#A633FF", "#FFA633", "#33FFE0", "#E033FF", "#FFE033", "#33FF6E", "#6E33FF", "#FF6E33", "#33FFD4", "#D433FF", "#FFD433", "#33FF4B", "#4B33FF", "#FF4B33", "#33FF91", "#9133FF", "#FF9133", "#33FFBB", "#BB33FF", "#FFBB33", "#33FF23", "#2333FF", "#FF2333", "#33FFC9", "#C933FF", "#FFC933", "#33FF78", "#7833FF", "#FF7833", "#33FFFD", "#FD33FF", "#FFFD33", "#33FF0F", "#0F33FF", "#FF0F33", "#33FF47", "#4733FF", "#FF4749", "#33FFFD", "#FD33FF", "#FFFD33", "#33FF0F", "#0F33FF", "#FF0F33", "#33FF47", "#4733FF"];
const barChartColors = ["#04B1B4"];

interface IBarChartData {
    key: string;
    value: number;
}

function getBarChartData(facilityEmissions: IFacilityEmissionsV2 | undefined) {
    let perEmployee: IBarChartData[] = [];
    let perSqMt: IBarChartData[] = [];
    if (facilityEmissions === undefined) {
        return { perEmployee, perSqMt };
    }

    const sectionEmissionsForCurrentPeriod = facilityEmissions.sectionEmissions.find((emission) => emission.section === KpiSection.ELECTRICITY);
    const sectionEmissionsForPreviousPeriod = facilityEmissions.sectionEmissionsForPreviousPeriod.find((emission) => emission.section === KpiSection.ELECTRICITY);
    if ((sectionEmissionsForCurrentPeriod === undefined) && (sectionEmissionsForPreviousPeriod === undefined)) {
        return { perEmployee, perSqMt };
    }

    const numberOfEmployees = facilityEmissions.facility.numberOfEmployees;
    const currentPeriodEmissionsPerEmployee = (sectionEmissionsForCurrentPeriod?.totalEmissions ?? 0) / numberOfEmployees;
    perEmployee.push({ key: "CY", value: currentPeriodEmissionsPerEmployee });
    const previousPeriodEmissionsPerEmployee = (sectionEmissionsForPreviousPeriod?.totalEmissions ?? 0) / numberOfEmployees;
    perEmployee.push({ key: "PY", value: previousPeriodEmissionsPerEmployee });

    const spaceInMeterSquare = facilityEmissions.facility.spaceInMeterSquare;
    const currentPeriodEmissionsPerSqMt = (sectionEmissionsForCurrentPeriod?.totalEmissions ?? 0) / spaceInMeterSquare;
    perSqMt.push({ key: "CY", value: currentPeriodEmissionsPerSqMt });
    const previousPeriodEmissionsPerSqMt = (sectionEmissionsForPreviousPeriod?.totalEmissions ?? 0) / spaceInMeterSquare;
    perSqMt.push({ key: "PY", value: previousPeriodEmissionsPerSqMt });

    return { perEmployee, perSqMt };
}

const Electricity: React.FC<IProps> = () => {
    const {
        isLoadingCompanyEmissions,
        selectedFacilityEmissions
    } = useContext(CompanyDataContextV2);

    const [pieTab, setPieTab] = useState<"total" | "perEmployee">("total");

    const summaryData = getFacilitySectionSummaryData(selectedFacilityEmissions, KpiSection.ELECTRICITY);
    const pieChartData = getPieChartDataForSectionGroup(selectedFacilityEmissions, KpiSection.ELECTRICITY, KpiSectionGroup.ELECTRICITY_SOURCES);
    const barChartData = getBarChartData(selectedFacilityEmissions);

    return (
        <Grid container spacing={2} paddingX={4} paddingBottom={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <GenericSummaryComponent
                    showDates={false}
                    isLoading={isLoadingCompanyEmissions}
                    data={summaryData}
                />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <GenericBarChartWithTitle
                    title={"Emissions per Employee"}
                    showInfoIcon
                    data={barChartData.perEmployee}
                    barLabelKey={"key"}
                    dataKeys={["value"]}
                    colors={barChartColors}
                    aspect={1}
                    maxHeight={400}
                    labelFormatter={(value, key) => `${toFixed(value[key])} tCO2e`}
                    yAxisLabel="tCO2e per employee per annum"
                />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <GenericBarChartWithTitle
                    title={"Emissions per sq meter"}
                    showInfoIcon
                    data={barChartData.perSqMt}
                    barLabelKey={"key"}
                    dataKeys={["value"]}
                    colors={barChartColors}
                    aspect={1}
                    maxHeight={400}
                    labelFormatter={(value, key) => `${toFixed(value[key])} tCO2e`}
                    yAxisLabel="tCO2e per sq meters per annum"
                />
            </Grid>
            {pieChartData.chartDataTotal.length > 0 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TableWithPieOrBarChart
                        title={pieChartData.chartTitle}
                        orient={"row"}
                        tabs={["Total", "Per Employee"]}
                        defaultTab={pieTab === "total" ? "Total" : "Per Employee"}
                        isStriped
                        showLegendColors
                        showActionButton
                        showInfoIcon
                        isLoading={isLoadingCompanyEmissions}
                        showHeadingRow
                        headers={["", "tCO2e"].map((header) => { return { header } })}
                        data={pieTab === "total" ? pieChartData.chartDataTotal : pieChartData.chartDataPerEmployee}
                        dataRenderer={(data, column) => {
                            switch (column) {
                                case "":
                                    return data.key;
                                case "tCO2e":
                                    return data.value;
                            }
                        }}
                        maxHeight={"300px"}
                        chartType={"pie"}
                        colors={pieChartColors}
                        pieChartProps={{
                            data: pieTab === "total" ? pieChartData.chartDataTotal : pieChartData.chartDataPerEmployee,
                            unit: "tCO2e",
                            dataKey: "value",
                            toolTipLabelFormatter: (value, _, percent) => `${value} tCO2e (${percent}%)`,
                            minWidth: 330,
                            minHeight: 330,
                            legendLabelKey: "key",
                            toolTipLabelKey: "key",
                        }}
                        onTabChange={(category) => {
                            switch (category) {
                                case 'Total':
                                    setPieTab("total");
                                    return pieChartData.chartDataTotal;
                                case 'Per Employee':
                                    setPieTab("perEmployee");
                                    return pieChartData.chartDataPerEmployee;
                                default:
                                    return [];
                            }
                        }}
                    />
                </Grid>
            )}

            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FacilityEmissionsOverTime
                    isLoadingCompanyEmissions={isLoadingCompanyEmissions}
                    selectedFacilityEmissions={selectedFacilityEmissions}
                    section={KpiSection.ELECTRICITY}
                />
            </Grid>
        </Grid>
    );
}

export default Electricity;
