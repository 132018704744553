import { addMonths } from 'date-fns'

export function createArrayWithNumbers(length: number): number[] {
    return Array.from({ length }, (_, k) => k);
}

export function toFixed(value: number | undefined, numOfDecimals: number | undefined = 2) {
    return value?.toFixed(numOfDecimals).replace(/[.,]00$/, "") ?? "0";
}

export const getRolling12Month = () => {
    const end = new Date();
    const start = addMonths(end, -11); // 11 because current end is inclusive
    return { start, end, label: "Last 12 months" };
}

export const getLastNFinancialYears = (numberOfYears: number = 3) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const year = today.getFullYear();
    const financialYears: { start: Date, end: Date; label: string }[] = [];
    let financialYear;
    if (currentMonth >= 3) {
        financialYear = year + 1;
    } else {
        financialYear = year;
    }

    for (let i = 0; i < numberOfYears; i++) {
        const previousYear = financialYear - 1;
        const shortCurrentYear = financialYear % 1000;
        const label = `FY ${previousYear}-${shortCurrentYear}`
        const start = new Date();
        start.setDate(1);
        start.setMonth(3);
        start.setFullYear(previousYear);

        const end = new Date();
        end.setDate(31);
        end.setMonth(2);
        end.setFullYear(financialYear);

        financialYears.push({ start, end, label });
        financialYear--;
    }

    return financialYears;
}

export const getLastNCalendarYears = (numberOfYears = 3) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const calendarYears: { start: Date, end: Date; label: string }[] = [];
    
    for (let i = 0; i < numberOfYears; i++) {
        let start = new Date();
        let currentFullYear = currentYear - i
        start.setFullYear(currentFullYear);
        start.setMonth(0);
        start.setDate(1);

        const end = new Date();
        end.setFullYear(currentFullYear);
        end.setMonth(11);
        end.setDate(31);

        let label = `CY ${start.getFullYear()}`;
        calendarYears.push({ start, end, label });
    }

    return calendarYears;
}
