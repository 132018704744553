import React, { ReactNode, useContext } from "react";
import styles from "./HomeLayout.module.css";
import SideBar from "../sideBar/SideBar";
import TopNavBar from "../topNavBar/TopNavBar";
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { Filters } from "../filters/Filters";

interface IProps {
    children: ReactNode;
    username: string;
    showDates?: boolean;
    showFacilityFilter?: boolean;
    showESGFilters?: "E" | "S" | "G";
}

const HomeLayout: React.FC<IProps> = (props) => {

    const { username, showESGFilters, showFacilityFilter, showDates } = props;

    const { primaryColorLight } = useContext(FoundationThemeContext);

    return (
        <div className={styles.homeLayout_root}>
            <TopNavBar username={username} />
            <SideBar />
            <div className={styles.homeLayout_content} style={{ backgroundColor: primaryColorLight }}>
                <Filters showESGFilters={showESGFilters} showFacilityFilter={showFacilityFilter} showDates={showDates}>
                    {props.children}
                </Filters>
            </div>
        </div>
    );

}

export default HomeLayout;
