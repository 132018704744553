import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line } from 'recharts';
import style from "./GenericLineChart.module.css";

export interface IGenericLineChartProps {
    data: any[];
    xAxisDataKey: string;
    yAxisDataKeys: string[];
    yAxisLabelFormatter?: (value: number, index: number) => string;
    colors?: string[];
    highlightLineIndex?: number;
    xAxisHeight?: number;
    yAxisWidth?: number;
    angle?: number;
    xAxisLabel?: string;
    yAxisLabel?: string;
    xAxisLabelMargin?: number;
    yAxisLabelMargin?: number;
}

function GenericLineChart(props: IGenericLineChartProps) {
    const {
        data,
        xAxisDataKey,
        yAxisDataKeys,
        yAxisLabelFormatter = (value, _) => value.toString(),
        colors,
        highlightLineIndex,
        xAxisHeight = 70,
        yAxisWidth,
        xAxisLabel,
        yAxisLabel,
        xAxisLabelMargin = 10,
        yAxisLabelMargin = -10,
        angle = 0,
    } = props;

    const lineColors = colors ?? [
        '#0069E8',
        '#5B3A96',
        '#CE1D26',
        '#FFA600',
        '#7FC91B',
    ];

    const CustomTooltip = (data: any) => {
        if (data.active && data.payload) {
            return (
                <div className={`${style.genericLineChart_tooltipContainer} recharts-custom-tooltip`}>
                    <p>
                        {data.label}
                    </p>
                    <div>
                        {data.payload.map((i: any, index: number) => {
                            return (
                                <div className={style.genericLineChart_customToolTipContainer} key={index}>
                                    <div
                                        className={style.genericLineChart_bulletContainer}
                                        style={{ backgroundColor: lineColors[index] }}
                                    />
                                    <span className={style.genericLineChart_value}>
                                        {yAxisLabelFormatter(i.value, index)}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className={style.genericLineChart_lineChartContainer}>
            <ResponsiveContainer width="100%" aspect={3} maxHeight={230}>
                <LineChart
                    width={210}
                    data={data}
                    margin={{ top: 5, left: 0, bottom: 5, right: 4 }}
                >
                    <CartesianGrid stroke="#EEEEEE" />
                    <XAxis
                        dataKey={xAxisDataKey}
                        interval={0}
                        fontFamily="inherit"
                        angle={angle}
                        textAnchor={"end"}
                        height={xAxisHeight}
                        fontSize={"12px"}
                        label={xAxisLabel && { value: xAxisLabel, angle: 0, position: 'middle', dy: xAxisLabelMargin, fill: "#878787" }}
                    />
                    <YAxis
                        fontFamily="inherit"
                        tickFormatter={yAxisLabelFormatter}
                        width={yAxisWidth}
                        fontSize={"12px"}
                        label={yAxisLabel && { value: yAxisLabel, angle: -90, position: 'middle', dx: yAxisLabelMargin, fill: "#878787" }}
                    />
                    <Tooltip content={CustomTooltip} wrapperStyle={{ padding: "0px" }} />
                    {yAxisDataKeys.map((dataKey, index) => {
                        let strokeColor = "#DEDFE2";
                        if (highlightLineIndex === undefined || highlightLineIndex === index) {
                            strokeColor = lineColors[index % lineColors.length];
                        }

                        return <Line type="monotone" dataKey={dataKey} stroke={strokeColor} activeDot={{ r: 5 }} dot={{ r: 0 }} strokeWidth={1.5} key={index} />;
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default GenericLineChart
