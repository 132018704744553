import { Card, CardContent, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { Role } from "../../models/Role";
import styles from "./ProfilePage.module.css";
import { IUserProfile } from "../../models/User";
import { Loading } from "../../components/loading/Loading";
import { useDropzone } from "react-dropzone";
import { ToastContext } from "../../contexts/ToastContext";
import { FoundationError } from "../../models/FoundationError";
import { uploadCompanyLogo } from "../../api/Company";
import { Auth } from "../../utils/Auth";

function getCompanyAddress(user: IUserProfile | undefined) {
    return user?.companyView?.address?.street + ", "
        + user?.companyView?.address?.state + ", "
        + user?.companyView?.address?.city + ", "
        + user?.companyView?.address?.country
}

const ProfilePage: React.FC = () => {
    const { showToast } = useContext(ToastContext);
    const user = Auth.getInstance().getUserSettings();

    const facilityListName = user?.role === Role.SUPERVISOR || user?.role === Role.DATA_MANAGER ? user?.facilityViews?.reduce((accumulator, item) => accumulator += item.name + " ", '') : "ALL"

    const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);

    const { open } = useDropzone({
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png']
        },
        onDropAccepted: (acceptedFiles) => handleUploadLogo(acceptedFiles[0]),
        onDropRejected: () => showToast("File size cannot be more than 5 MB", "error"),
        multiple: false,
        maxSize: 5 * 1024 * 1024
    });

    const handleUploadLogo = async (file: File) => {
        setIsUploadingDocument(true);
        const response = await uploadCompanyLogo(user!.parentCompanyId, file);
        setIsUploadingDocument(false);
        if (response instanceof FoundationError) {
            showToast(response.message, "error");
            return;
        }
    }

    return (
        <div className={styles.profileInfo__mainContainer}>
            <Card className={styles.profileInfo__container}>
                <div className={styles.profileInfo__headingConatiner}>
                    <div className={styles.profileInfo__title}>User Profile</div>
                </div>
                <CardContent>
                    <Grid container spacing={2} className={styles.profileInfo__row}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className={styles.profileInfo__label}>Name</div>
                            <div className={styles.profileInfo__value}>{user?.name}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={8}>
                            <div className={styles.profileInfo__label}>Email</div>
                            <div className={styles.profileInfo__value}>{user?.email}</div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={styles.profileInfo__row}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className={styles.profileInfo__label}>Phone Number</div>
                            <div className={styles.profileInfo__value}>{user?.phoneNumber ?? "NA"}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={8}>
                            <div className={styles.profileInfo__label}>Designation</div>
                            <div className={styles.profileInfo__value}>{user?.designationInCompany}</div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={styles.profileInfo__row}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className={styles.profileInfo__label}>Facilities</div>
                            <div className={styles.profileInfo__value}>{facilityListName}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={8}>
                            <div className={styles.profileInfo__label}>Role</div>
                            <div className={styles.profileInfo__value}>{user?.role}</div>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

            <Card className={styles.companyInfo__container}>
                <div className={styles.profileInfo__headingConatiner}>
                    <div className={styles.profileInfo__title}>Company Details</div>
                </div>
                <CardContent>


                    <Grid container spacing={2} className={styles.profileInfo__row}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className={styles.profileInfo__label}>Name</div>
                            <div className={styles.profileInfo__value}>{user?.companyView.name}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={7}>
                            <div className={styles.profileInfo__label}>Address</div>
                            <div className={styles.profileInfo__value}>{getCompanyAddress(user)}</div>
                        </Grid>
                    </Grid><Grid container spacing={2} className={styles.profileInfo__row}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className={styles.profileInfo__label}>Currency</div>
                            <div className={styles.profileInfo__value}>{user?.companyView.currency}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={7}>
                            <div className={styles.profileInfo__label}>Language</div>
                            <div className={styles.profileInfo__value}>{user?.companyView.language}</div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={styles.profileInfo__row}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className={styles.profileInfo__label}>Point of Contact</div>
                            <div className={styles.profileInfo__value}>{user?.companyView.pointOfContact?.name}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={7}>
                            <div className={styles.profileInfo__label}>Logo</div>
                            {user?.role === Role.SUPER_ADMIN && (
                                <div className={styles.profileInfo__value}>
                                    <button
                                        onClick={open}
                                        disabled={isUploadingDocument}
                                    >
                                        <Loading isLoading={isUploadingDocument} text="Browse" />
                                    </button>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div >
    );
}

export default ProfilePage;
