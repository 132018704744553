export enum KpiScope {
    SCOPE_1 = "SCOPE_1",
    SCOPE_2 = "SCOPE_2",
    SCOPE_3 = "SCOPE_3"
}

export function displayNameForScope(scope: KpiScope) {
    switch (scope) {
        case KpiScope.SCOPE_1: return "Scope 1";
        case KpiScope.SCOPE_2: return "Scope 2";
        case KpiScope.SCOPE_3: return "Scope 3";
    }
}

export enum KpiSection {
    FACILITY_DETAILS = "FACILITY_DETAILS",
    DIESEL_CONSUMPTION = "DIESEL_CONSUMPTION",
    PETROL_CONSUMPTION = "PETROL_CONSUMPTION",
    LPG_CONSUMPTION = "LPG_CONSUMPTION",
    REFRIGERANTS = "REFRIGERANTS",
    ELECTRICITY = "ELECTRICITY",
    OIL_PRODUCTS = "OIL_PRODUCTS",
    COAL_PRODUCTS = "COAL_PRODUCTS",
    NATURAL_GAS = "NATURAL_GAS",
    BIOMASS = "BIOMASS",
    BUSINESS_TRAVEL = "BUSINESS_TRAVEL",
    EMPLOYEE_COMMUTE = "EMPLOYEE_COMMUTE",
    OTHER_FUELS = "OTHER_FUELS"
}

export enum KpiSectionGroup {
    NONE = "NONE",
    SUMMARY = "SUMMARY",
    ELECTRICITY_SOURCES = "ELECTRICITY_SOURCES",
    BUSINESS_TRAVEL = "BUSINESS_TRAVEL",
    EMPLOYEE_COMMUTE = "EMPLOYEE_COMMUTE"
}

export enum NetZeroKpiType {
    FACILITY_SIZE = "FACILITY_SIZE",
    NUMBER_OF_EMPLOYEES = "NUMBER_OF_EMPLOYEES",
    ELECTRICITY_USAGE = "ELECTRICITY_USAGE",
    PERCENT_ELECTRICITY_FROM_RENEWABLE_SOURCES = "PERCENT_ELECTRICITY_FROM_RENEWABLE_SOURCES",
    DIESEL_CONSUMPTION_DG_SETS = "DIESEL_CONSUMPTION_DG_SETS",
    DIESEL_CONSUMPTION_VEHICLE_FLEET = "DIESEL_CONSUMPTION_VEHICLE_FLEET",
    PETROL_CONSUMPTION_VEHICLE_FLEET = "PETROL_CONSUMPTION_VEHICLE_FLEET",
    LPG_CONSUMPTION = "LPG_CONSUMPTION",
    R_23_REFRIGERANT_CONSUMPTION = "R_23_REFRIGERANT_CONSUMPTION",
    R_32_REFRIGERANT_CONSUMPTION = "R_32_REFRIGERANT_CONSUMPTION",
    R_125_REFRIGERANT_CONSUMPTION = "R_125_REFRIGERANT_CONSUMPTION",
    R_134A_REFRIGERANT_CONSUMPTION = "R_134A_REFRIGERANT_CONSUMPTION",
    R_143A_REFRIGERANT_CONSUMPTION = "R_143A_REFRIGERANT_CONSUMPTION",
    R_152A_REFRIGERANT_CONSUMPTION = "R_152A_REFRIGERANT_CONSUMPTION",
    R_236FA_REFRIGERANT_CONSUMPTION = "R_236FA_REFRIGERANT_CONSUMPTION",
    R_401A_REFRIGERANT_CONSUMPTION = "R_401A_REFRIGERANT_CONSUMPTION",
    R_401B_REFRIGERANT_CONSUMPTION = "R_401B_REFRIGERANT_CONSUMPTION",
    R_401C_REFRIGERANT_CONSUMPTION = "R_401C_REFRIGERANT_CONSUMPTION",
    R_402A_REFRIGERANT_CONSUMPTION = "R_402A_REFRIGERANT_CONSUMPTION",
    R_402B_REFRIGERANT_CONSUMPTION = "R_402B_REFRIGERANT_CONSUMPTION",
    R_403A_REFRIGERANT_CONSUMPTION = "R_403A_REFRIGERANT_CONSUMPTION",
    R_403B_REFRIGERANT_CONSUMPTION = "R_403B_REFRIGERANT_CONSUMPTION",
    R_404A_REFRIGERANT_CONSUMPTION = "R_404A_REFRIGERANT_CONSUMPTION",
    R_406A_REFRIGERANT_CONSUMPTION = "R_406A_REFRIGERANT_CONSUMPTION",
    R_407A_REFRIGERANT_CONSUMPTION = "R_407A_REFRIGERANT_CONSUMPTION",
    R_407B_REFRIGERANT_CONSUMPTION = "R_407B_REFRIGERANT_CONSUMPTION",
    R_407C_REFRIGERANT_CONSUMPTION = "R_407C_REFRIGERANT_CONSUMPTION",
    R_407D_REFRIGERANT_CONSUMPTION = "R_407D_REFRIGERANT_CONSUMPTION",
    R_407E_REFRIGERANT_CONSUMPTION = "R_407E_REFRIGERANT_CONSUMPTION",
    R_408A_REFRIGERANT_CONSUMPTION = "R_408A_REFRIGERANT_CONSUMPTION",
    R_409A_REFRIGERANT_CONSUMPTION = "R_409A_REFRIGERANT_CONSUMPTION",
    R_409B_REFRIGERANT_CONSUMPTION = "R_409B_REFRIGERANT_CONSUMPTION",
    R_410A_REFRIGERANT_CONSUMPTION = "R_410A_REFRIGERANT_CONSUMPTION",
    R_410B_REFRIGERANT_CONSUMPTION = "R_410B_REFRIGERANT_CONSUMPTION",
    R_411A_REFRIGERANT_CONSUMPTION = "R_411A_REFRIGERANT_CONSUMPTION",
    R_411B_REFRIGERANT_CONSUMPTION = "R_411B_REFRIGERANT_CONSUMPTION",
    R_412A_REFRIGERANT_CONSUMPTION = "R_412A_REFRIGERANT_CONSUMPTION",
    R_413A_REFRIGERANT_CONSUMPTION = "R_413A_REFRIGERANT_CONSUMPTION",
    R_414A_REFRIGERANT_CONSUMPTION = "R_414A_REFRIGERANT_CONSUMPTION",
    R_414B_REFRIGERANT_CONSUMPTION = "R_414B_REFRIGERANT_CONSUMPTION",
    R_415A_REFRIGERANT_CONSUMPTION = "R_415A_REFRIGERANT_CONSUMPTION",
    R_415B_REFRIGERANT_CONSUMPTION = "R_415B_REFRIGERANT_CONSUMPTION",
    R_416A_REFRIGERANT_CONSUMPTION = "R_416A_REFRIGERANT_CONSUMPTION",
    R_417A_REFRIGERANT_CONSUMPTION = "R_417A_REFRIGERANT_CONSUMPTION",
    R_418A_REFRIGERANT_CONSUMPTION = "R_418A_REFRIGERANT_CONSUMPTION",
    R_419A_REFRIGERANT_CONSUMPTION = "R_419A_REFRIGERANT_CONSUMPTION",
    R_420A_REFRIGERANT_CONSUMPTION = "R_420A_REFRIGERANT_CONSUMPTION",
    R_500_REFRIGERANT_CONSUMPTION = "R_500_REFRIGERANT_CONSUMPTION",
    R_501_REFRIGERANT_CONSUMPTION = "R_501_REFRIGERANT_CONSUMPTION",
    R_502_REFRIGERANT_CONSUMPTION = "R_502_REFRIGERANT_CONSUMPTION",
    R_503_REFRIGERANT_CONSUMPTION = "R_503_REFRIGERANT_CONSUMPTION",
    R_504_REFRIGERANT_CONSUMPTION = "R_504_REFRIGERANT_CONSUMPTION",
    R_505_REFRIGERANT_CONSUMPTION = "R_505_REFRIGERANT_CONSUMPTION",
    R_506_REFRIGERANT_CONSUMPTION = "R_506_REFRIGERANT_CONSUMPTION",
    R_507_REFRIGERANT_CONSUMPTION = "R_507_REFRIGERANT_CONSUMPTION",
    R_508A_REFRIGERANT_CONSUMPTION = "R_508A_REFRIGERANT_CONSUMPTION",
    R_508B_REFRIGERANT_CONSUMPTION = "R_508B_REFRIGERANT_CONSUMPTION",
    R_509_REFRIGERANT_CONSUMPTION = "R_509_REFRIGERANT_CONSUMPTION",
    PFC_218_REFRIGERANT_CONSUMPTION = "PFC_218_REFRIGERANT_CONSUMPTION",
    PFC_116_REFRIGERANT_CONSUMPTION = "PFC_116_REFRIGERANT_CONSUMPTION",
    PFC_14_REFRIGERANT_CONSUMPTIO = "PFC_14_REFRIGERANT_CONSUMPTION"
}
