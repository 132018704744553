import { Button, ButtonProps, Icon, List, ListItem } from "@mui/material";
import { Link, LinkProps, useLocation, useSearchParams } from "react-router-dom";
import styles from "./TopNav.module.css"
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { useContext } from "react";
import { Role } from "../../models/Role";
import { Auth } from "../../utils/Auth";

interface IButtonProps {
  buttonPath: string;
  backgroundColor: string;
}

export const LinkButton: React.FC<ButtonProps & LinkProps & IButtonProps> = (
  props
) => {
  const { buttonPath, backgroundColor, ...rest } = props;

  return (
    <Button
      className={styles.topBarNav_button}
      style={{ backgroundColor }}
      component={Link}
      {...(rest as any)}
    />
  );
};

export const TopNav: React.FC = () => {
  const { primaryTextColor, dashboardIcon, dataCollectionIcon, selectedColor, reductionIcon, offsettingIcon, reportingIcon } = useContext(
    FoundationThemeContext
  );
  const currentPath = useLocation().pathname;
  const user = Auth.getInstance().getUserSettings();

  const iconStyle = {
    height: "auto",
    width: "auto"
  }

  let topNavBarPages = [
    {
      title: "Dashboard",
      allowedRoles: [],
      href: "/E/company-emissions",
      icon: (<Icon sx={iconStyle}>
        <img src={dashboardIcon} alt="" />
      </Icon>)
    },
    {
      title: "Data Collection",
      allowedRoles: [],
      href: "/data-collection",
      icon: (<Icon sx={iconStyle}>
        <img src={dataCollectionIcon} alt="" />
      </Icon>)
    },
    {
      title: "Reduction",
      allowedRoles: [Role.ADMIN, Role.SUPER_ADMIN],
      href: "/reduction",
      icon: (<Icon sx={iconStyle}>
        <img src={reductionIcon} alt="" />
      </Icon>)
    },
    {
      title: "Offsetting",
      allowedRoles: [],
      href: "/offsetting",
      icon: (<Icon sx={iconStyle}>
        <img src={offsettingIcon} alt="" />
      </Icon>)
    },
    {
      title: "Reports",
      href: "/reports",
      allowedRoles: [Role.ADMIN, Role.SUPER_ADMIN],
      icon: (<Icon sx={iconStyle}>
        <img src={reportingIcon} alt="" />
      </Icon>)
    }
  ]
  let [searchParams] = useSearchParams();

  if (user) {
    topNavBarPages = topNavBarPages.filter(obj => obj.allowedRoles.includes(user?.role) || obj.allowedRoles.length === 0)
  }

  return (
    <div className={`${styles.topBarNav_root} ${styles.topBarNav_nav}`}>
      <List classes={{ root: styles.topBarNav_list }}>
        {topNavBarPages
          .map((page) => {
            let selectedButtonStyle = false;
            const buttonPath = page.href;
            if (currentPath === "/" || buttonPath === "/") {
              selectedButtonStyle = currentPath === buttonPath;
            } else if (currentPath.includes(buttonPath)) {
              selectedButtonStyle = true;
            }
            return (
              <ListItem disableGutters key={page.title} className={styles.topBarNav_item}>
                <LinkButton
                  buttonPath={buttonPath}
                  backgroundColor={selectedButtonStyle ? selectedColor : ""}
                  to={page.href + "?" + searchParams.toString()}
                >
                  {page.icon}
                  <div
                    className={styles.topBarNav_title}
                    style={{ color: selectedButtonStyle ? "#000000" : primaryTextColor }}
                  >
                    {page.title}
                  </div>
                </LinkButton>
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}
