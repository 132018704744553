import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./CreateCustomReport.module.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { createArrayWithNumbers } from "../../../utils/Utils";
import { ICustomReportTemplate, IReportTableData, QuestionType } from "../../../models/Report";
import { useForm, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { Loading } from "../../../components/loading/Loading";
import { FoundationError } from "../../../models/FoundationError";
import { getCustomReportTemplateData, saveCustomReportTemplate, updateCustomReportTemplate } from "../../../api/CustomReport";
import { FoundationThemeContext } from "../../../contexts/FoundationThemeContext";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContext } from "../../../contexts/ToastContext";

interface IQuestionProps {
    sectionIndex: number;
    questionIndex: number;
    getValues: UseFormGetValues<ICustomReportTemplate>;
    setValue: UseFormSetValue<ICustomReportTemplate>;
}

const QuestionContent: React.FC<IQuestionProps> = (props) => {

    const MIN_TABLE_ROW_SIZE = 1;
    const MAX_TABLE_ROW_SIZE = 100;
    const MIN_TABLE_COL_SIZE = 1;
    const MAX_TABLE_COL_SIZE = 10;

    const DEFAULT_REPORT_TABLE_DATA = {
        headers: [[]],
        rowTitles: []
    }

    const { sectionIndex, questionIndex, getValues, setValue } = props;
    const [choices, setChoices] = useState<string[]>(getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.choices`) ?? []);
    const [choiceInput, setChoiceInput] = useState<string>();
    const [questionType, setQuestionType] = useState<QuestionType>(getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.type`) ?? QuestionType.ONE_LINE);

    const defaultRows = getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.tableData`)?.rowTitles.length
    const [numberOfTableRows, setNumberOfTableRows] = useState(defaultRows ?? 0);

    const defaultColumns = getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.tableData`)?.headers[0].length
    const [numberOfTableColumns, setNumberOfTableColumns] = useState(defaultColumns ?? 0);

    const [reportTableData, setReportTableData] = useState<IReportTableData>(DEFAULT_REPORT_TABLE_DATA);

    useEffect(() => {
        if (questionType === QuestionType.TABULAR) {
            setValue(`reportData.sections.${sectionIndex}.questions.${questionIndex}.tableData`, reportTableData);
        } else {
            const questions = getValues(`reportData.sections.${sectionIndex}.questions`);
            if (questions && questions.length > questionIndex) {
                setValue(`reportData.sections.${sectionIndex}.questions.${questionIndex}.tableData`, undefined);
            }
        }
    }, [reportTableData, setValue, questionIndex, sectionIndex, questionType, getValues]);

    useEffect(() => {
        if (numberOfTableRows > reportTableData.rowTitles.length) {
            reportTableData.rowTitles.push("");
        } else {
            reportTableData.rowTitles.splice(-1);
        }
    }, [numberOfTableRows, reportTableData.rowTitles]);

    useEffect(() => {
        if (numberOfTableColumns > reportTableData.headers[0].length) {
            reportTableData.headers[0].push({ title: "" });
        } else {
            reportTableData.headers[0].splice(-1);
        }
    }, [numberOfTableColumns, reportTableData.headers]);


    const removeChoice = (choice: string) => {
        setChoices(choices.filter(ch => ch !== choice))
        const existingValues = getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.choices`) ?? [];
        setValue(
            `reportData.sections.${sectionIndex}.questions.${questionIndex}.choices`,
            [...existingValues.filter((val: string) => val !== choice)]
        )
    };

    const addChoice = () => {
        if (!choiceInput || choices.includes(choiceInput) || choiceInput.length === 0) {
            setChoiceInput("");
            return;
        }
        setChoices([...choices, choiceInput])
        const existingValues = getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.choices`) ?? [];
        setValue(
            `reportData.sections.${sectionIndex}.questions.${questionIndex}.choices`,
            [...existingValues, choiceInput]
        )
        setChoiceInput("");
    }

    const addQuestion = (val: string, sectionId: string, questionId?: string, questionType?: QuestionType) => {

        let sections = getValues(`reportData.sections`);
        let currSectionInd = sections.findIndex(section => section.sectionId === sectionId);
        if (currSectionInd === -1) {
            return;
        }
        let currSection = sections[currSectionInd];
        let questions = currSection?.questions;

        let currQuestionInd = questions.findIndex(question => question.questionId === questionId);

        if (questionId && currQuestionInd !== -1) {
            // question already exist in the ui
            setValue(`reportData.sections.${currSectionInd}.questions.${currQuestionInd}.question`, val);
        } else {
            const newQuestionId = (getValues(`reportData.sections.${currSectionInd}.questions`).length).toString();
            setValue(`reportData.sections.${currSectionInd}.questions`, [...questions, { questionId: newQuestionId ?? "", question: val, type: questionType ?? QuestionType.ONE_LINE, required: true }]);
        }
    }

    const clearQuestion = (sectionId: string, questionId: string) => {
        let sections = getValues(`reportData.sections`);
        let currSectionInd = sections.findIndex(section => section.sectionId === sectionId);
        if (currSectionInd === -1) {
            return;
        }
        let currSection = sections[currSectionInd];

        let currQuestionInd = currSection?.questions.findIndex(question => question.questionId === questionId);
        if (currQuestionInd !== -1) {
            currSection.questions[currQuestionInd].question = "";
            setValue(`reportData.sections.${currSectionInd}`, currSection);
        }

    }

    const onQuestionChange = (val: string, sectionIndex: number, questionIndex: number) => {

        const currSection = getValues(`reportData.sections.${sectionIndex}`)

        if (!currSection.questions) {
            setValue(`reportData.sections.${sectionIndex}.questions`, []);
        }

        const currQuestion = currSection.questions[questionIndex];
        if (!val || val.length === 0) {
            clearQuestion(currSection.sectionId, currQuestion.questionId)
        } else {
            addQuestion(val, currSection.sectionId, currQuestion?.questionId, currQuestion?.type);
        }

    }

    const setRequired = (isChecked: boolean) => {
        setValue(`reportData.sections.${props.sectionIndex}.questions.${props.questionIndex}.required`, isChecked);
    }

    const onQuestionTypeChange = (val: QuestionType, sectionIndex: number, questionIndex: number) => {
        setQuestionType(val);

        if (val !== QuestionType.MCQ_SINGLE && val !== QuestionType.MCQ_MULTI) {
            setValue(`reportData.sections.${sectionIndex}.questions.${questionIndex}.choices`, undefined);
            setChoices([]);
        }

        setValue(`reportData.sections.${sectionIndex}.questions.${questionIndex}.type`, val);
    }

    const onChangeColumnHandler = (e: any) => {
        const val = parseInt(e.target.value);

        if (val > 10) {
            setNumberOfTableColumns(10);
            e.target.value = 10;
        } else {
            setNumberOfTableColumns(val);
        }
    }

    const onChangeRowHandler = (e: any) => {
        const val = parseInt(e.target.value);

        if (val > 100) {
            setNumberOfTableRows(100);
            e.target.value = 100;
        } else {
            setNumberOfTableRows(val);
        }
    }

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <div className={styles.createCustomReport_questionNumber}>Question {questionIndex + 1}</div>
                    <div className={styles.createCustomReport_question}>
                        <TextField
                            variant="outlined"
                            placeholder="Add Question"
                            defaultValue={getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.question`)}
                            onChange={e => {
                                const val = e.target.value;
                                onQuestionChange(val, sectionIndex, questionIndex);
                            }}
                            fullWidth
                        />
                    </div>
                </Grid>
                <Grid item lg={12} md={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={e => setRequired(e.target.checked)}
                                    defaultChecked={getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.required`) ?? true}
                                />
                            }
                            label="Required?"
                        />
                    </FormGroup>
                </Grid>
                <Grid item md={12}>
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Question Type</InputLabel>
                        <Select
                            labelId="select-label"
                            label="Question Type"
                            value={questionType}
                            onChange={e => {
                                const qType = e.target.value as QuestionType;
                                onQuestionTypeChange(qType, sectionIndex, questionIndex);
                            }}
                        >
                            <MenuItem value={QuestionType.ONE_LINE}>One line</MenuItem>
                            <MenuItem value={QuestionType.MULTI_LINE}>Multi line</MenuItem>
                            <MenuItem value={QuestionType.MCQ_SINGLE}>MCQ single</MenuItem>
                            <MenuItem value={QuestionType.MCQ_MULTI}>MCQ multi</MenuItem>
                            <MenuItem value={QuestionType.TABULAR}>Table</MenuItem>
                        </Select>
                    </FormControl>
                    {(questionType === QuestionType.MCQ_SINGLE || questionType === QuestionType.MCQ_MULTI) && (
                        <div className={styles.choiceContainer}>
                            <Stack direction="row" sx={{ width: "100%" }} flexWrap={"wrap"} width={100}>
                                {choices.map((choice: string, _ind) => {
                                    return <Chip key={`chip_${_ind}`} label={choice} onDelete={() => removeChoice(choice)} sx={{ marginRight: "10px", marginTop: "10px" }} />
                                })}
                            </Stack>
                            <div className={styles.choiceActionContainer}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Add Option"
                                    value={choiceInput}
                                    onChange={e => {
                                        setChoiceInput(e.target.value as string);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            addChoice();
                                        }
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    onClick={() => { addChoice() }}
                                    sx={{ marginLeft: "10px" }}
                                >
                                    Add choice
                                </Button>
                            </div>
                        </div>
                    )}
                    {(questionType === QuestionType.TABULAR) && (
                        <div className={styles.tableContainer}>

                            <Grid container justifyContent={"center"}>
                                <Grid padding={2}>
                                    <TextField
                                        sx={{ width: "250px" }}
                                        variant="outlined"
                                        type="number"
                                        defaultValue={defaultColumns}
                                        InputProps={{ inputProps: { min: MIN_TABLE_COL_SIZE, max: MAX_TABLE_COL_SIZE, step: 1 } }}
                                        placeholder="Enter Number of Columns"
                                        onChange={onChangeColumnHandler}
                                    />
                                </Grid>
                                <Grid padding={2}>
                                    <TextField
                                        sx={{ width: "250px" }}
                                        variant="outlined"
                                        type="number"
                                        defaultValue={defaultRows}
                                        InputProps={{ inputProps: { min: MIN_TABLE_ROW_SIZE, max: MAX_TABLE_ROW_SIZE, step: 1 } }}
                                        placeholder="Enter Number of Rows"
                                        onChange={onChangeRowHandler}
                                    />
                                </Grid>
                            </Grid>
                            <div className={styles.tableHeadersContainer}>
                                <div className={styles.columnOrRowInputs}>
                                    <h3>Columns: </h3>
                                    <Grid container>
                                        {createArrayWithNumbers(numberOfTableColumns).map((col, colIndex) => {
                                            return (
                                                <Grid key={`col_${colIndex}`} padding={2}>
                                                    <TextField
                                                        sx={{ width: "180px" }}
                                                        variant="outlined"
                                                        placeholder={`Enter col heading ${col + 1}`}
                                                        defaultValue={getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.tableData.headers.0.${colIndex}.title`)}
                                                        onChange={e => {
                                                            const val = e.target.value as string;
                                                            const newData = { ...reportTableData };
                                                            newData.headers[0][colIndex] = { title: val }
                                                            setReportTableData(newData);
                                                        }}
                                                        required
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                                <div className={styles.columnOrRowInputs}>
                                    <h3>Rows: </h3>
                                    <Grid container>
                                        {createArrayWithNumbers(numberOfTableRows).map((row, rowIndex) => {
                                            return (
                                                <Grid key={`row_${rowIndex}`} padding={2}>
                                                    <TextField
                                                        sx={{ width: "180px" }}
                                                        variant="outlined"
                                                        defaultValue={getValues(`reportData.sections.${sectionIndex}.questions.${questionIndex}.tableData.rowTitles.${rowIndex}`)}
                                                        placeholder={`Enter row heading ${row + 1}`}
                                                        onChange={e => {
                                                            const val = e.target.value as string;
                                                            const newData = { ...reportTableData };
                                                            newData.rowTitles[rowIndex] = val;
                                                            setReportTableData(newData);
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </CardContent>
    )
}

const CreateCustomReport: React.FC = () => {
    const { reportId } = useParams<{ reportId?: string }>();

    const [customReportId, setCustomReportId] = useState<string>();
    const [sectionAndQuestionCount, setSectionAndQuestionCount] = useState<number[]>([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const { setValue, getValues, handleSubmit, register, formState: { errors }, reset } = useForm<ICustomReportTemplate>();
    const [isLoading, setIsLoading] = useState(false);

    const onSectionTitleChange = (val: string, sectionIndex: number) => {
        setValue(`reportData.sections.${sectionIndex}.title`, val);
        setValue(`reportData.sections.${sectionIndex}.sectionId`, sectionIndex.toString());
    }

    const { showToast } = useContext(ToastContext);

    const navigate = useNavigate();
    const queryStr = useLocation().search;

    const fetchReportData = useCallback(async (reportId: string) => {
        setIsLoading(true);
        const response = await getCustomReportTemplateData(reportId);
        setIsLoading(false);

        if (response instanceof FoundationError) {
            const errorMessage = response.message ?? "Something went wrong!";
            showToast(errorMessage, "error");
            return;
        }

        const sectionsWithQuestionsCount: number[] = [];
        response.reportData.sections.forEach((section) => {
            sectionsWithQuestionsCount.push(section.questions.length);
        });

        setSectionAndQuestionCount(sectionsWithQuestionsCount);

        reset({
            templateName: response.templateName,
            reportData: response.reportData
        });
        setCustomReportId(response.id);
    }, [reset, showToast]);

    useEffect(() => {
        if (reportId === undefined) {
            return;
        }

        fetchReportData(reportId);
    }, [reportId, fetchReportData]);

    const onSubmit = async (reportTemplate: ICustomReportTemplate, isSubmitted: boolean) => {
        reportTemplate.templateName = reportTemplate.templateName.trim();
        if (reportTemplate.templateName?.length === 0) {
            showToast("Template name is required", "error");
            return;
        }

        if (!reportTemplate.reportData) {
            showToast("At least one question is required", "error");
            return;
        }

        let sections = [...getValues(`reportData.sections`)];

        const isAtLeastOneSectionWithQuestionFound = sections.find((section) => section.questions !== undefined);
        if (isAtLeastOneSectionWithQuestionFound === undefined) {
            showToast("At least one question is required", "error");
            return;
        }

        sections.forEach((section) => {
            if (section.questions === undefined) {
                section.questions = [];
                return;
            }

            const filteredQuestions = section.questions.filter((question) => question.question && question.question.trim().length > 0);
            section.questions = [...filteredQuestions];
        });

        reportTemplate.reportData.sections = sections.filter(section => section.questions && section.questions.length > 0)

        setValue(`reportData`, reportTemplate.reportData);

        if (isSubmitted) {
            setLoadingSubmit(true);
        } else {
            setLoadingSave(true);
        }
        const postData = getValues();
        let response;
        postData.isSubmitted = isSubmitted;
        if (customReportId === undefined) {
            response = await saveCustomReportTemplate(postData)
        } else {
            response = await updateCustomReportTemplate(customReportId, postData)
        }
        if (isSubmitted) {
            setLoadingSubmit(false);
        } else {
            setLoadingSave(false);
        }
        if (response instanceof FoundationError) {
            const errorMessage = response.message ?? "Something went wrong!";
            showToast(errorMessage, "error");
            return;
        }

        if (isSubmitted) {
            showToast("Custom report template submitted successfully!", "success");
            setTimeout(() => {
                navigate({
                    pathname: `/reports/create`,
                    search: queryStr
                });
            }, 2000)
            return;
        }

        setCustomReportId(response.id);
        showToast("Custom report saved successfully!", "success");
    }

    const { primaryColorLight } = useContext(FoundationThemeContext);

    const helperTextRootStyle = {
        width: "90%",
        margin: "20px 0",
        backgroundColor: "white",
        "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: primaryColorLight,
            margin: 0,
            paddingLeft: "15px",
            paddingTop: "2px"
        },
    }

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div className={styles.createCustomReport_reportCreationContainer}>
            <div className={styles.createCustomReport_reportCreationDescription}>
                <div className={styles.createCustomReport_reportCreationHeading}>
                    Create a custom report
                </div>
                <div className={styles.createCustomReport_reportCreationSubtitle}>
                    You can add your mutiple sections and questions to create you own report format.
                </div>
                <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <TextField
                        variant="outlined"
                        placeholder="Add template title"
                        {...register("templateName", { required: true })}
                        error={errors.templateName !== undefined}
                        helperText={
                            errors.templateName ? "Template name required" : ""
                        }
                        onChange={e => {
                            const val = e.target.value;
                            setValue(`templateName`, val);
                        }}
                        sx={helperTextRootStyle}
                        fullWidth
                    />
                    <Box>
                        <Button
                            sx={{ margin: 2 }}
                            variant="outlined"
                            onClick={handleSubmit((data) => onSubmit(data, false))}
                            disabled={loadingSave || loadingSubmit}
                        >
                            <Loading text={"Save"} isLoading={loadingSave} />
                        </Button>
                        <Button
                            sx={{ margin: 2 }}
                            variant="contained"
                            onClick={handleSubmit((data) => onSubmit(data, true))}
                            disabled={loadingSave || loadingSubmit}
                        >
                            <Loading text={"Submit"} isLoading={loadingSubmit} />
                        </Button>
                    </Box>
                </Grid>

            </div>
            <div className={styles.createCustomReport_reportCreationSectionsAndQuestions}>
                {sectionAndQuestionCount.map((questionsCount, sectionIndex) => {
                    return (
                        <Accordion key={`accordion_${sectionIndex}`}>
                            <AccordionSummary
                                key={`section_${sectionIndex}`}
                                classes={{ content: styles.reportDetails__sectionHeaderContainer }} expandIcon={<ExpandMoreIcon />}>
                                <div>
                                    <div className={styles.createCustomReport_questionNumber}>Section {sectionIndex + 1}</div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Add Section Title"
                                        defaultValue={getValues(`reportData.sections.${sectionIndex}.title`)}
                                        onChange={e => {
                                            const val = e.target.value;
                                            onSectionTitleChange(val, sectionIndex);
                                        }}
                                        fullWidth
                                    />
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item md={12}>
                                        {createArrayWithNumbers(questionsCount).map((questionIndex) => {
                                            return (
                                                <Card
                                                    key={`section_${sectionIndex}_question_${questionIndex}`}
                                                    className={styles.createCustomReport_questionContainer}>
                                                    <QuestionContent
                                                        sectionIndex={sectionIndex}
                                                        questionIndex={questionIndex}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                    />
                                                </Card>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                <Button
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={() => {
                                        const newQuestionsCount = questionsCount + 1;
                                        const newSectionAndQuestionCount = [...sectionAndQuestionCount];
                                        newSectionAndQuestionCount[sectionIndex] = newQuestionsCount;
                                        setSectionAndQuestionCount(newSectionAndQuestionCount);
                                    }}
                                >
                                    Add question
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
                <Button
                    className={styles.createCustomReport_addMoreSectionButton}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                        const newSections = [...sectionAndQuestionCount, 1];
                        setSectionAndQuestionCount(newSections);
                    }}
                >
                    Add new section
                </Button>
            </div>
        </div >
    );
}

export default CreateCustomReport;
