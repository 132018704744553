import { IDataCollectionSectionV2 } from "../../models/DataCollection";
import styles from "./DataCollectionSidebar.module.css";
import { Link } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, List, ListItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FoundationThemeContext } from "../../contexts/FoundationThemeContext";
import { Loading } from "../loading/Loading";
import { KpiScope, displayNameForScope } from "../../models/Kpi";

interface IProps {
    data: IDataCollectionSectionV2[],
    onSelect: (sectionIndex: number, questionNumber: number) => void;
    selectedSectionIndex: number;
    selectedQuestionIndex: number;
    isLoading: boolean;
    getValues: any;
}

function renderSection(
    section: IDataCollectionSectionV2,
    selectedSectionIndex: number,
    selectedQuestionIndex: number,
    allSections: IDataCollectionSectionV2[],
    primaryColor: string,
    selectedColor: string,
    primaryTextColor: string,
    getValues: any,
    onSelect: (sectionIndex: number, questionNumber: number) => void
) {
    const sectionIndex = allSections.findIndex((s) => s === section);
    return (
        <div key={sectionIndex} className={`${styles.accordionRoot} ${sectionIndex === (allSections.length - 1) ? styles.accordionRoot_lastChild : undefined}`}>
            <Accordion
                sx={{
                    backgroundColor: primaryColor,
                    boxShadow: "none",
                    width: 1
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        width: 1,
                        textAlign: "left"
                    }}
                >
                    {section.title}
                </AccordionSummary>
                <AccordionDetails
                    sx={{ padding: "0px 0px" }}
                >
                    {section.questionWithAnswers.map((question, questionIndex) => {
                        const questionKpiType = question.kpiType;
                        const isSelected = sectionIndex === selectedSectionIndex && questionIndex === selectedQuestionIndex;
                        const currAnswer = getValues(`${sectionIndex}.questionWithAnswers.${questionIndex}.input`);

                        const isAnswered = (currAnswer !== undefined || question.input !== undefined);

                        return (
                            <ListItem disableGutters
                                key={questionKpiType}
                                className={
                                    isSelected ?
                                        styles.sideBarNav_listItemSubSelected :
                                        styles.sideBarNav_listItemSub
                                }
                            >
                                <Button
                                    fullWidth
                                    className={styles.sideBarNav_subButton}
                                    style={{ backgroundColor: isSelected ? selectedColor : "", justifyContent: "flex-start" }}
                                    onClick={() => {
                                        onSelect(sectionIndex, questionIndex);
                                    }}
                                >
                                    <div
                                        className={styles.sideBarNav_title}
                                        style={{ color: primaryTextColor }}
                                    >
                                        {question.title}
                                        <Checkbox size="small" checked={isAnswered} disableTouchRipple />
                                    </div>
                                </Button>
                            </ListItem>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export const DataCollectionSidebarV2: React.FC<IProps> = (props) => {

    const { data, selectedSectionIndex, selectedQuestionIndex, onSelect, getValues } = props;
    const [isLoading, setIsLoading] = useState(props.isLoading);

    const [sections, setSections] = useState(data);

    const { primaryColor, primaryTextColor, logoDark, selectedColor } = useContext(FoundationThemeContext);

    useEffect(() => {
        setSections(data);
    }, [data]);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    const sectionsWithoutScope = sections.filter((section) => section.scope === undefined);

    return (
        <div
            className={styles.sideBar_root}
            style={{ backgroundColor: primaryColor }}
        >
            <div className={styles.sideBar_header}>
                <Button component={Link} to={"/E/company-emissions"}>
                    <img src={logoDark} alt={"logo"} className={styles.sideBar_img} />
                </Button>
            </div>
            <p className={styles.heading} style={{ color: primaryTextColor }}>Data Collection</p>
            <div className={`${styles.sideBarNav_root} ${styles.sideBarNav_nav}`}>
                <hr className={styles.sideBar_divider} />

                <List classes={{ root: styles.sideBarNav_list }}>
                    {sectionsWithoutScope.length > 0 && (
                        <>
                            {sectionsWithoutScope.map(
                                (section) => renderSection(
                                    section,
                                    selectedSectionIndex,
                                    selectedQuestionIndex,
                                    sections,
                                    primaryColor,
                                    selectedColor,
                                    primaryTextColor,
                                    getValues,
                                    onSelect
                                )
                            )}
                        </>
                    )}
                    {Object.values(KpiScope).map((scope) => {
                        const sectionsForScope = sections.filter((section) => section.scope === scope);
                        return (
                            <Accordion
                                key={scope}
                                sx={{
                                    backgroundColor: primaryColor,
                                    boxShadow: "none",
                                    width: 1
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        width: 1,
                                        textAlign: "left",
                                        fontSize: "18px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {displayNameForScope(scope)}
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "0px 0px" }}>
                                    <List classes={{ root: styles.sideBarNav_list }}>
                                        {isLoading && <Loading text="" isLoading={true} />}
                                        {!isLoading && (
                                            <>
                                                {sectionsForScope.map(
                                                    (section) => renderSection(
                                                        section,
                                                        selectedSectionIndex,
                                                        selectedQuestionIndex,
                                                        sections,
                                                        primaryColor,
                                                        selectedColor,
                                                        primaryTextColor,
                                                        getValues,
                                                        onSelect
                                                    )
                                                )}
                                            </>
                                        )}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </List>
            </div>
        </div>
    );
}
