import { IFacility } from "./Facility";

export enum ActionPlanStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    COMPLETED = "COMPLETED"
}

export enum ActionPlanItem {
    TRANSITION_TO_RENEWABLE_ENERGY = "TRANSITION_TO_RENEWABLE_ENERGY",
    SMART_ENERGY_MANAGEMENT_SYSTEM = "SMART_ENERGY_MANAGEMENT_SYSTEM",
    ECONOMY_TRAVEL = "ECONOMY_TRAVEL",
    TRAIN_TRAVEL = "TRAIN_TRAVEL",
    AVOID_TRAVEL = "AVOID_TRAVEL",
    PUBLIC_TRANSPORT = "PUBLIC_TRANSPORT",
    EV_CAR_FLEET = "EV_CAR_FLEET",
    ENERGY_TEMPERATURE_OPTIMIZATION = "ENERGY_TEMPERATURE_OPTIMIZATION",
    REDUCE_GENERATED_WASTE = "REDUCE_GENERATED_WASTE",
    CIRCULAR_ECONOMY = "CIRCULAR_ECONOMY"
}

export enum ActionPlanCategory {
    ENERGY = "ENERGY",
    COMMUTE = "COMMUTE",
    WASTE = "WASTE",
    MATERIAL = "MATERIAL"
}

export interface IActionPlanView {
    id: string;
    companyId: string;
    facilityView: IFacility;
    actionPlanCategory: ActionPlanCategory;
    actionPlanItem: ActionPlanItem;
    getEmissionReduction: number;
    description: string;
    status: ActionPlanStatus;
    startDate: number;
    endDate: number;
    creationDate: number;
}

export interface IActionPlanItemView {
    item: ActionPlanItem;
    category: ActionPlanCategory;
    description: string;
    reductionPotential: number;
}

export interface ITargetView {
    id: string;
    companyId: string;
    startDate: number;
    endDate: number;
    targetEmission: number;
}

export interface IMonthlyEmissionAnalyticsData {
    businessAsUsualEmissions: number;
    emissionsWithReductions: number;
    applicableActionPlans: IActionPlanView[];
    applicableTargets: ITargetView[];
    yearMonth: number;
    isPredictive: boolean;
}
