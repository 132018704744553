import { Accordion, AccordionSummary, AccordionDetails, Grid, Button, CircularProgress } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReportingInput from "../../../components/reportingInput/ReportingInput";
import styles from "./ReportDetails.module.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IReportData, IReportView, ISectionIdToQuestionIdToAnswer, QuestionType, ReportStatus } from "../../../models/Report";
import { SubmitHandler, useForm } from "react-hook-form";
import { Loading } from "../../../components/loading/Loading";
import { Auth } from "../../../utils/Auth";
import { completeReport, downloadReport, getReport, updateReport } from "../../../api/Company";
import { FoundationError } from "../../../models/FoundationError";
import { createArrayWithNumbers } from "../../../utils/Utils";
import { ConfirmationDialog } from "../../../components/confirmationDialog/ConfirmationDialog";
import { formatTimestampToDateMonthYear } from "../../../utils/DateUtils";
import { ToastContext } from "../../../contexts/ToastContext";

const ReportDetails: React.FC = () => {
    const { reportId } = useParams<{ reportId: string }>();
    const [expandedSectionId, setExpandedSectionId] = useState<string | false>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [report, setReport] = useState<IReportView>();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);

    const { handleSubmit, setValue, getValues, reset, formState: { dirtyFields } } = useForm<IReportData>();

    const handleChange = (sectionId: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedSectionId(isExpanded ? sectionId : false);
    };

    const onSubmit: SubmitHandler<IReportData> = async (data) => {
        // post only fields that have changed (dirtyFields)
        const dirtySections = dirtyFields.sections;
        if (!dirtySections || dirtySections.length === 0) {
            // nothing changed, so no-op
            return;
        }

        const sectionIdQuestionIdToAnswerMap: ISectionIdToQuestionIdToAnswer = {};
        dirtySections.forEach((section, sectionIndex) => {
            section?.questionAndAnswers?.forEach((questionAnswer, questionIndex) => {
                const sectionData = data.sections[sectionIndex];
                const questionAnswerData = sectionData.questionAndAnswers[questionIndex];
                const answers = questionAnswerData.answer;
                if (answers === undefined) {
                    return;
                }

                const questionType = sectionData.questionAndAnswers[questionIndex].type;
                let answer: string | string[] | string[][] | undefined = undefined;
                if (Array.isArray(questionAnswer.answer) && questionType === QuestionType.TABULAR) {
                    const existingAnswers = report?.reportData.sections[sectionIndex].questionAndAnswers[questionIndex].answer as string;
                    const existingAnswersArray = existingAnswers ? JSON.parse(existingAnswers) : [] as string[][];
                    const tableData = report?.reportData.sections[sectionIndex].questionAndAnswers[questionIndex].tableData;
                    const numOfRows = tableData?.rowTitles.length ?? 0;
                    let numOfCols = 0;
                    tableData?.headers[0].forEach((header) => numOfCols += header.colSpan ?? 1);
                    const answersToPost = createArrayWithNumbers(numOfRows).map((_, rowIndex) => {
                        return createArrayWithNumbers(numOfCols - 1).map((_, colIndex) => {
                            let answered = false;
                            const answeredQuestions = questionAnswer.answer as any[][];
                            if (answeredQuestions[rowIndex] !== undefined) {
                                if (answeredQuestions[rowIndex][colIndex] !== undefined) {
                                    answered = answeredQuestions[rowIndex][colIndex];
                                }
                            }

                            if (answered) {
                                return answers[rowIndex][colIndex];
                            } else {
                                if (existingAnswersArray[rowIndex] !== undefined && existingAnswersArray[rowIndex] !== null) {
                                    if (existingAnswersArray[rowIndex][colIndex] !== undefined && existingAnswersArray[rowIndex][colIndex] !== null) {
                                        return existingAnswersArray[rowIndex][colIndex];
                                    }
                                }
                            }

                            return "";
                        });
                    });

                    questionAnswer.answer.map((isAnswered, index) => {
                        if (isAnswered) {
                            return answers[index] as string;
                        } else {
                            return "";
                        }
                    });

                    answer = JSON.stringify(answersToPost);
                } else if (questionAnswer.answer === true) {
                    answer = answers;
                }

                if (answer !== undefined && answer !== null) {
                    let questionIdToAnswerMap = sectionIdQuestionIdToAnswerMap[sectionData.sectionId];
                    if (questionIdToAnswerMap === undefined) {
                        questionIdToAnswerMap = {}
                    }

                    questionIdToAnswerMap[questionAnswerData.questionId] = answer;
                    sectionIdQuestionIdToAnswerMap[sectionData.sectionId] = questionIdToAnswerMap;
                }
            });
        });

        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings && report) {
            setIsSaving(true);
            const response = await updateReport(userSettings.parentCompanyId, report.id, sectionIdQuestionIdToAnswerMap);
            setIsSaving(false);

            if (response instanceof FoundationError) {
                showToast(response.getErrorMessage(), "error");
                return;
            }

            setReport(response);
            reset(response.reportData);
            showToast("Saved!", "success");
            checkIfAllRequiredQuestionsAreAnswered(response);
        }
    };

    const checkIfAllRequiredQuestionsAreAnswered = (report: IReportView) => {
        if (report) {
            let requiredQuestionsCount = 0;
            let answeredQuestionsCount = 0;
            report.reportData.sections.forEach((section) => {
                section.questionAndAnswers.forEach(
                    obj => {
                        if (obj.required) {
                            requiredQuestionsCount++
                            if (obj.answer !== undefined) {
                                answeredQuestionsCount++
                            }
                        }
                    })
            })

            if (requiredQuestionsCount === 0) {
                setIsDisabled(false);
                return;
            }

            if (answeredQuestionsCount > 0 && requiredQuestionsCount === answeredQuestionsCount) {
                setIsDisabled(false)
            }
        }
    }

    const fetchReport = useCallback(async (companyId: string, reportIdParam: string) => {
        setIsLoading(true);
        const response = await getReport(companyId, reportIdParam);
        setIsLoading(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setReport(response);
        reset(response.reportData);
        checkIfAllRequiredQuestionsAreAnswered(response);
    }, [reset, showToast]);

    const submitReport = async () => {
        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings && report) {
            setIsSubmiting(true)
            const response = await completeReport(userSettings.parentCompanyId, report.id)
            setIsSubmiting(false)
            if (response instanceof FoundationError) {
                showToast(response.getErrorMessage(), "error");
                return;
            } else {
                setShowConfirmationDialog(false);
                showToast("Saved!", "success");

                const userSettings = Auth.getInstance().getUserSettings();
                if (userSettings && reportId) {
                    fetchReport(userSettings.parentCompanyId, reportId);
                }
            }
        }
    }

    const downloadReportPdf = async (reportView: IReportView) => {
        setIsDownloading(true);
        const response = await downloadReport(reportView.createdByUser.parentCompanyId, reportView.id);
        setIsDownloading(false);
        if (response instanceof FoundationError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
    }

    useEffect(() => {
        const userSettings = Auth.getInstance().getUserSettings();
        if (userSettings && reportId) {
            fetchReport(userSettings.parentCompanyId, reportId);
        }
    }, [reportId, fetchReport]);

    return (
        <div className={styles.reportDetails__createContainer}>
            {showConfirmationDialog && (
                <ConfirmationDialog
                    buttonText={"Submit"}
                    bodyText={`Do you want to submit the report? You will not be able to edit the report after submission`}
                    open={showConfirmationDialog}
                    onClose={() => {
                        setShowConfirmationDialog(false);
                    }}
                    onApprove={submitReport}
                    isApproving={isSubmiting}
                />
            )}
            {isLoading && (
                <div className={styles.reportDetails__loadingDiv}>
                    <CircularProgress
                        size={30}
                        color={"inherit"}
                    />
                </div>
            )}
            {!isLoading && report && (
                <>
                    <div className={styles.reportDetails__metadataContainer}>
                        <div className={styles.reportDetails__title}>{report.name}</div>
                        <div className={styles.reportDetails_button}>
                            {(report.status === ReportStatus.COMPLETED || report.status === ReportStatus.ARCHIVED) ? (
                                <>
                                    <div className={styles.reportDetails__meta}>
                                        Submitted by {report.completedByUser.name} on {formatTimestampToDateMonthYear(report.completionTimestamp)}
                                    </div>
                                    <Button
                                        className={styles.reportDetails__submitButton}
                                        variant="contained"
                                        onClick={() => downloadReportPdf(report)}
                                    >
                                        <Loading isLoading={isDownloading} text={"Download"} />
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        className={styles.reportDetails__submitButton}
                                        variant="contained"
                                        disabled={isDisabled}
                                        onClick={() => {
                                            setShowConfirmationDialog(true);
                                        }}
                                    >
                                        <Loading isLoading={isSubmiting} text={"Submit"} />
                                    </Button>
                                    <Button
                                        className={styles.reportDetails__submitButton}
                                        variant="contained"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        <Loading isLoading={isSaving} text={"Save"} />
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    {report.reportData.sections.map((section, sectionIndex) => {
                        const questionsCount = section.questionAndAnswers.length;
                        const answeredQuestionsCount = section.questionAndAnswers
                            .filter((questionAndAnswer) => questionAndAnswer.answer !== undefined)
                            .length;
                        return (
                            <Accordion
                                key={section.sectionId}
                                expanded={expandedSectionId === section.sectionId}
                                onChange={handleChange(section.sectionId)}
                                TransitionProps={{
                                    timeout: 400,
                                    mountOnEnter: true,
                                    unmountOnExit: true
                                }}
                            >
                                <AccordionSummary classes={{ content: styles.reportDetails__sectionHeaderContainer }} expandIcon={<ExpandMoreIcon />}>
                                    <div className={styles.reportDetails__sectionTitle}>
                                        {section.title}
                                    </div>
                                    <div className={styles.reportDetails__sectionStatus}>
                                        {answeredQuestionsCount}/{questionsCount}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        {section.questionAndAnswers.map((question, questionIndex) => {
                                            return (
                                                <Grid
                                                    key={`section_${section.sectionId}_question_${questionIndex}`}
                                                    item
                                                    md={12}
                                                >
                                                    <ReportingInput
                                                        questionObject={question}
                                                        questionIndex={questionIndex}
                                                        sectionIndex={sectionIndex}
                                                        sectionId={section.sectionId}
                                                        setValue={setValue}
                                                        getValues={getValues}
                                                        allowEdit={!(report.status === ReportStatus.COMPLETED || report.status === ReportStatus.ARCHIVED)}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </>
            )}
        </div>
    );
}

export default ReportDetails;
